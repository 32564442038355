/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../helpers'

type Props = {
  className: string
  description: string
  icon: boolean
  stats: number
  color:string
  labelColor: string
  textColor: string
  viewcount?:number | any
}


const CardsWidget7 = ({className, description, icon, stats,color, labelColor, textColor,viewcount}: Props) => (
  <div className={`card card-flush ${className}`}
  style={{
    backgroundColor: color,
    minHeight:'15em'
    
  }}>
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{viewcount>0?viewcount:''}</span>
          <span className='text-white pt-1 fw-semibold fs-4'>{viewcount>0?description:'No Views Yet'}</span>
        </div>
      </div>
    </div>

  </div>
)
export {CardsWidget7}
