/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import {Pie} from 'react-chartjs-2'
import * as XLSX from 'xlsx'
import WidgetsPage from '../../../../../app/modules/widgets/WidgetsPage'

type Props = {
  cardID?: string
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  datavalue?: object | any
  totalSocialCount?: number | any
}

const CardsWidget17: FC<Props> = ({cardID, className, datavalue, totalSocialCount}) => {
  const [socialMediaCounts, setSocialMediaCounts] = useState<{[key: string]: number} | undefined>()

  useEffect(() => {
    if (datavalue) {
      setSocialMediaCounts(datavalue as {[key: string]: number})
      // console.log("Social Media Count debug:", typeof datavalue)
    }
  }, [datavalue])

  //Download Fumctionality

  const downloadSocialAnalytics = () => {
    const ws = XLSX.utils.json_to_sheet([datavalue])
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, `${cardID}_socialmedianalytics.xlsx`)
  }

  // Prepare data for pie chart
  const filteredSocialMediaCounts = socialMediaCounts
    ? Object.entries(socialMediaCounts).reduce((acc, [socialMedia, count]) => {
        if (count > 0) {
          acc[socialMedia] = count
        }
        return acc
      }, {} as {[key: string]: number})
    : {}

  const pieChartData = {
    labels: Object.keys(filteredSocialMediaCounts).map((socialMedia) => {
      switch (true) {
        case socialMedia.startsWith('insta'):
          return 'Instagram'
        case socialMedia.startsWith('facebook'):
          return 'Facebook'
        case socialMedia.startsWith('linkedin'):
          return 'LinkedIn'
        case socialMedia.startsWith('youtube'):
          return 'Youtube'
        case socialMedia.startsWith('whatsapp'):
          return 'Whatsapp'
        default:
          return 'Other'
      }
    }),
    datasets: [
      {
        data: Object.values(filteredSocialMediaCounts),
        backgroundColor: Object.keys(filteredSocialMediaCounts).map((socialMedia) => {
          switch (true) {
            case socialMedia.startsWith('insta'):
              return '#cd486b' // Instagram
            case socialMedia.startsWith('facebook'):
              return '#1877f2' // Facebook
            case socialMedia.startsWith('linkedin'):
              return '#0077B5' // LinkedIn
            case socialMedia.startsWith('youtube'):
              return '#ff0000' // Youtube
            case socialMedia.startsWith('whatsapp'):
              return '#128c7e' // Whatsapp
            default:
              return '#000000' // Default color
          }
        }),
      },
    ],
  }

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'></span>

            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{totalSocialCount}</span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>
            Social Link Analytics
            <button
              onClick={downloadSocialAnalytics}
              style={{background: 'transparent', border: 'none', outline: 'none'}}
            >
              <i
                className='fa-solid fa-download btn btn-success rounded-circle mx-2'
                style={{padding:'0.5em'}}
              ></i>
            </button>
          </span>
        </div>
        <div>
          {/* <button className='btn btn-primary rounded' onClick={downloadSocialAnalytics}>Download Data</button> */}
        </div>
      </div>

      <div className='card-body d-flex flex-wrap align-items-center'>
        {socialMediaCounts && (
          <div className='row'>
            <div className='col-sm-12 col-md-4 col-lg-4'>
              <Pie
                data={pieChartData}
                width='50%'
                height='50%'
                options={{
                  plugins: {
                    legend: {
                      position: 'bottom',
                      align: 'start',
                      labels: {
                        boxWidth: 10,
                        boxHeight: 10,
                        padding: 20,
                        usePointStyle: true,
                      },
                    },
                  },
                  layout: {
                    padding: {
                      bottom: 20,
                    },
                  },
                }}
              />
            </div>

            <div
              className='col-sm-12 col-md-8 col-lg-8'
              style={{display: 'flex', flexWrap: 'wrap'}}
            >
              {' '}
              {/* Adjust width as needed */}
              {Object.entries(socialMediaCounts).map(([socialMedia, count], index) => (
                <div
                  key={index}
                  style={{
                    marginRight: '20px',
                    marginBottom: '10px',
                    width: '12em',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className='bullet w-8px h-3px rounded-2 me-3'
                    style={{
                      backgroundColor: socialMedia.startsWith('insta')
                        ? '#cd486b' // Instagram
                        : socialMedia.startsWith('facebook')
                        ? '#1877f2' // Facebook
                        : socialMedia.startsWith('twitter')
                        ? '#1da1f2' // Twitter
                        : socialMedia.startsWith('whatsapp')
                        ? '#128c7e' // WhatsApp
                        : socialMedia.startsWith('linkedin')
                        ? '#0077B5' // LinkedIn
                        : socialMedia.startsWith('snapchat')
                        ? '#fffc00' // Snapchat
                        : socialMedia.startsWith('pinterest')
                        ? '#bd081c' // Pinterest
                        : socialMedia.startsWith('tiktok')
                        ? '#69c9d0' // TikTok
                        : socialMedia.startsWith('youtube')
                        ? '#ff0000' // YouTube
                        : socialMedia.startsWith('reddit')
                        ? '#ff4500' // Reddit
                        : socialMedia.startsWith('messenger')
                        ? '#0084ff' // Messenger
                        : socialMedia.startsWith('behance')
                        ? '#1769ff' // Behance
                        : socialMedia.startsWith('telegram')
                        ? '#0088cc' // Telegram
                        : socialMedia.startsWith('quora')
                        ? '#b92b27' // Quora
                        : '#000000', // Default color
                    }}
                  ></div>
                  <div className='text-gray-500 flex-grow-1 me-4'>
                    {socialMedia.startsWith('insta')
                      ? 'Instagram'
                      : socialMedia.startsWith('facebook')
                      ? 'Facebook'
                      : socialMedia.startsWith('twitter')
                      ? 'Twitter'
                      : socialMedia.startsWith('whatsapp')
                      ? 'WhatsApp'
                      : socialMedia.startsWith('linkedin')
                      ? 'LinkedIn'
                      : socialMedia.startsWith('snapchat')
                      ? 'Snapchat'
                      : socialMedia.startsWith('pinterest')
                      ? 'Pinterest'
                      : socialMedia.startsWith('tiktok')
                      ? 'TikTok'
                      : socialMedia.startsWith('youtube')
                      ? 'YouTube'
                      : socialMedia.startsWith('reddit')
                      ? 'Reddit'
                      : socialMedia.startsWith('messenger')
                      ? 'Messenger'
                      : socialMedia.startsWith('behance')
                      ? 'Behance'
                      : socialMedia.startsWith('telegram')
                      ? 'Telegram'
                      : socialMedia.startsWith('quora')
                      ? 'Quora'
                      : socialMedia}
                  </div>
                  <div className='fw-bolder text-gray-700 text-xxl-end'>{count}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {CardsWidget17}
