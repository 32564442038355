import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { verifyOtp } from '../core/_requests'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../core/Auth'
import MetaTagWrapper from '../../helpers/MetaTagWrapper'
import { UserModel } from '../core/_models'
import axios from 'axios'
import { APPURL } from '../../helpers/imageUrl'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const BASE_URL = APPURL

const initialValues = {
  otp: '',
}

const otpSchema = Yup.object().shape({
  otp: Yup.number().min(6, '6 digits required').required('OTP is required'),
})

const VerifyOtp = () => {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  // const [errors, setErrors] = useState<any>('')
  // const [successOTP, setSuccessOTP] = useState<any>('')
  const navigate = useNavigate()
  const email = sessionStorage.getItem('emailss');
  const token = sessionStorage.getItem('token');
  // const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const formik = useFormik({
    initialValues,
    validationSchema: otpSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      // setHasErrors(undefined)
      try {
        const { data: auth } = await verifyOtp(parseInt(values.otp))
        saveAuth(auth)
        // console.log(auth);
        // setErrors('')
        const token = JSON.stringify(sessionStorage.getItem('token')).replace(/["]+/g, '')

        if (auth.data.success === 'Your Email is Verified.') {
          localStorage.setItem('token', token)

          localStorage.setItem('user_type', auth.data?.['user']['user_type']!)
          localStorage.setItem('user_name', auth.data?.['user']['name']!)
          const user: UserModel = {
            id: auth.data?.['user']['id'],
            username: auth.data?.['user']['name'],
            email: auth.data?.['user']['email'],
            phone: auth.data?.['user']['phone_number'],
          }
          if (auth.data?.['user']['user_type'] == 0) {
            // navigate('/crafted/widgets/lists') //TODO: This Line will show 404 error page!
            navigate('/') //Fix it by using this
          } else {
            navigate('/crafted/widgets/allcards')
          }

          setCurrentUser(user)
          // navigate('/')

          sessionStorage.clear()

          toast.success(`${auth.data.success}`, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        } else {
          // setErrors('Please enter correct otp')
          toast.error(`${auth.data.message}`, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      } catch (error: any) {
        console.error(error)
        // setErrors(error.response.data?.data?.['errors'][0])
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
        
        toast.error(`${error.response.data?.data?.['errors'][0]}`, {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    },
  })

  const handleResendOTP = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
        },
      }

      const bodyParams = {
        email,
        _method: 'PUT'
      }

      const response = await axios.put(`${BASE_URL}/resend_otp`, bodyParams, config)
      // console.log(response)
      // console.log(response?.data)
      // console.log(response.data?.data?.['message'])

      if(response.status === 200) {
        // setSuccessOTP(response.data?.data?.['message'])
        toast.success(`${response.data?.data?.['message']}`, {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
      else {
        // setSuccessOTP('')
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  // console.log(token);

  return (
    <>
      <MetaTagWrapper title='Verify OTP || Tap Savvy' />
      <div className='alert alert-danger alert-dismissible fade show' role='alert'>
        Do not refresh the page or close the window until your account is verified
        <button type='button' className='btn-close' data-bs-dismiss='alert' aria-label='Close'></button>
      </div>
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Heading */}

        {/* {errors ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{errors}</div>
          </div>
        ) : null}

        {
          successOTP ? (
            <div className='mb-lg-15 alert alert-success'>
              <div className='alert-text font-weight-bold'>{successOTP}</div>
            </div>
          ) : null
        } */}

        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Verify OTP</h1>
          {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Enter your otp to confirm your email.
          </div>
        </div>

        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>OTP</label>
          <input
            placeholder='OTP'
            {...formik.getFieldProps('otp')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.otp && formik.errors.otp },
              {
                'is-valid': formik.touched.otp && !formik.errors.otp,
              }
            )}
            type='text'
            name='otp'
            autoComplete='off'
          />
          {formik.touched.otp && formik.errors.otp && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.otp}</span>
            </div>
          )}

          <div className='resend__otp text-center'>
            <span onClick={handleResendOTP}>Resend OTP</span>
          </div>
        </div>

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      <ToastContainer />
    </>
  )
}

export default VerifyOtp
