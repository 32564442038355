import React, {FC, useEffect, useState} from 'react'
import {OrderDetail, Gallery} from '../interfaces/cardDetails'
import {GALLERY_IMAGE_URL} from '../../../modules/helpers/imageUrl'

interface props {
  orderDetails?: OrderDetail
  gallery?: Gallery[]
}
const Imagegallery: FC<props> = ({orderDetails, gallery}) => {
  const style = orderDetails?.template
  const imageurl = GALLERY_IMAGE_URL
  const [mainImage, setMainImage] = useState<Gallery>()
  const [images, setImages] = useState<Gallery[]>([])

  useEffect(() => {
    setImages(gallery!)
    setMainImage(gallery?.at(0))
  }, [gallery])

  const handleThumbnailClick = (thumbnailUrl: Gallery) => {
    setMainImage(thumbnailUrl)
  }

  return (
    <div className='card' style={{backgroundColor: style?.background_color}}>
      <div className='row card-body'>
        <h3 className='text-center p-2' style={{color: style?.font_color, fontFamily:style?.font_style}}>
          Gallery
        </h3>
        <div className='col' style={{ display: 'flex', justifyContent: 'center'}}>
          <img src={imageurl + mainImage?.image_file} alt='Main Image' className='img-fluid rounded-3' />
        </div>
      </div>
      <div className='row row-cols-4 p-4'>
        <div className='scrollable-container' style={{overflowX: 'auto', width: '100%'}}>
          {/* <style>
            {`.scrollable-container::-webkit-scrollbar { 
        display: none; 
      }`}
          </style> */}
          <div className='d-flex flex-nowrap'>
            {images.length > 1 &&
              images.map((image) => (
                <div
                  className='col-1'
                  style={{
                    width: '200px',
                    maxWidth: '100%',
                    height: '200px',
                  }}
                  key={image.id}
                >
                  <img
                    src={imageurl + image.image_file}
                    alt={`Thumbnail ${image.id}`}
                    className='img-thumbnail'
                    style={{
                      backgroundColor: style?.background_color,
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                      border:'none'
                    }}
                    onClick={() => handleThumbnailClick(image)}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Imagegallery
