//TEMPLATE 03 preview

import React from 'react'
import '../customization/savedTemplates/template03.css'
import styleConfig from './interfaces/styleconfig'
import {bottom, hide} from '@popperjs/core'
import '@fortawesome/fontawesome-free/css/all.min.css'

interface CardDetails5Props {
  styleConfig: styleConfig
  firstName?: String
  lastName?: String
  OCompanyName?: String
  ODesignation?: String
  OAbout?: String
  OProfilePic?: String
  OCoverPic?: String
}

const CardDetails5: React.FC<CardDetails5Props> = (_props: CardDetails5Props) => {
  const styleconfig = _props.styleConfig
  const default_cover_img =
    'https://images.unsplash.com/photo-1542330952-bffc55e812b2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8b2ZmaWNlJTIwbGFkeXxlbnwwfHwwfHx8MA%3D%3D'
  const default_profile_image =
    'https://plus.unsplash.com/premium_photo-1693258698597-1b2b1bf943cc?w=1000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8cHJvZmlsZSUyMGJveXxlbnwwfHwwfHx8MA%3D%3D'

  const firstName = _props.firstName
  const lastName = _props.lastName
  const OCompanyName = _props.OCompanyName
  const ODesignation = _props.ODesignation
  const OAbout = _props.OAbout
  const OProfilePic = _props.OProfilePic
  const OCoverPic = _props.OCoverPic

  return (
    <div
      className='App'
      style={{borderRadius: '12px', maxWidth: '350px', maxHeight: '655px', overflow: 'hidden'}}
    >
      {/* THE ENTIRE CARD CONTAINER */}
      <div className='template03_card_container'>
        {/* THE CARD HEADER */}

        {/* THE CARD BODY */}
        <div
          className='template03_card_body'
          style={{
            backgroundColor: `${
              styleconfig.backgroundColor ? styleconfig.backgroundColor : '#fff'
            }`,
            color: `${styleconfig.fontColor ? styleconfig.fontColor : '#000'}`,
            fontFamily: `${styleconfig.fontFamily}`,
          }}
        >
          <div
            className='template03_card_body_bg'
            style={{
              backgroundImage: styleconfig.coverImage
                ? `url(${styleconfig.coverImage})`
                : styleconfig.coverHeight === 0
                ? 'none'
                : OCoverPic
                ? `url(https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/template/cover_images/${OCoverPic})`
                : `${default_cover_img}`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            {/* Card Header  */}
            <div
              className='d-flex justify-content-between align-items-center p-2'
              style={{
                height: '40px',
                backgroundColor: `${
                  styleconfig.backgroundColor ? styleconfig.backgroundColor : '#fff'
                }`,
                opacity: '50%',
              }}
            >
              <div className='mx-3'>
                <i
                  className='fa-solid fa-share fs-2'
                  style={{
                    color: `${styleconfig.fontColor ? styleconfig.fontColor : '#000'}`,
                  }}
                ></i>
              </div>

              <div className='d-flex me-5'>
                <div className='mx-1'>
                  <i
                    className='fa-solid fa-phone fs-2'
                    style={{
                      color: `${styleconfig.fontColor ? styleconfig.fontColor : '#000'}`,
                    }}
                  ></i>
                </div>

                <div className='mx-2'>
                  <i
                    className='fa-solid fa-location-dot fs-2'
                    style={{
                      color: `${styleconfig.fontColor ? styleconfig.fontColor : '#000'}`,
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div className='template03_card_profile_info_container'>
            <div
              className='template03_card_profile_info'
              style={{
                backgroundColor: `${
                  styleconfig.backgroundColor ? styleconfig.backgroundColor : '#fff'
                }`,
              }}
            >
              <div
                className='template03_card_profile_pic'
                style={{
                  backgroundImage: styleconfig.profileImage
                    ? `url(${styleconfig.profileImage})`
                    : OProfilePic
                    ? `url(https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/template/profile_image/${OProfilePic})`
                    : `${default_profile_image}`,
                }}
              ></div>
              <div className='template03_card_profile_information'>
                <div className='template03_card_profile_fullname'>
                  {firstName ? <>{firstName}</> : <>First Name</>}
                  <br />
                  {lastName ? <>{lastName}</> : <>Last Name</>}
                </div>
                <div className='template03_card_profile_companyinfo'>
                  <div
                    className='template03_card_profile_designation'
                    style={{
                      color: `${styleconfig.fontColor ? styleconfig.fontColor : '#000000'}`,
                      filter: 'saturate(80%)',
                    }}
                  >
                    {ODesignation ? <>{ODesignation}</> : <>Designation</>}
                  </div>
                  <div className='template03_card_profile_companyname'>
                    {OCompanyName ? <>{OCompanyName}</> : <>Company Name</>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className='template03_card_probile_about'
            style={{
              color: `${styleconfig.fontColor ? styleconfig.fontColor : '#000000'}`,
              filter: 'saturate(80%)',
            }}
          >
            {OAbout ? (
              <>{OAbout}</>
            ) : (
              <>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt magnam cumque quod
                quia dolorum rem.
              </>
            )}
          </div>

          <div className='template03_card_profile_location_container'>
            <div className='template03_card_profile_location'>
              <div>
                <div>
                <i
                    className='fa-solid fa-location-dot fs-2'
                    style={{
                      color: `${styleconfig.fontColor ? styleconfig.fontColor : '#000'}`,
                    }}
                  ></i>
                </div>
                <div
                  style={{
                    color: `${styleconfig.fontColor}`,
                  }}
                >
                  33, Gurukripa Building, 2nd Floor, Kika Street
                </div>
              </div>
              <div
                style={{
                  color: `${styleconfig.fontColor}`,
                }}
              >
                Gulalwadi, Mumbai
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardDetails5
