/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import {ListAllCardInterface} from '../../../../app/modules/widgets/components/admin/inerfaces/ListviewInterface'
import {Link, useLocation} from 'react-router-dom'
import {PROFILE_IMAGE_URL, COVER_IMAGE_URL} from '../../../../app/modules/helpers/imageUrl'
import MetaTagWrapper from '../../../../app/modules/helpers/MetaTagWrapper'
import DateFormateHelper from '../../../helpers/DateFormateHelper'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Console} from 'console'
import {object} from 'yup'
import { debounce } from 'lodash';

// import { Dropdown } from 'react-bootstrap';
type Props = {
  className: string
  tabIndex: any
}

const profileImageUrl = PROFILE_IMAGE_URL

const ListsWidget8: React.FC<Props> = ({className, tabIndex}) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isDraft = searchParams.get('draft')
  const {onGet, onPost, onPut} = useApiHook()
  const [allCards, setAllCards] = useState<ListAllCardInterface[]>([])
  const [isloading, setisloading] = useState(false)
  const [filterAllCards, setFilterAllCards] = useState<ListAllCardInterface[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [tobeDeteled, settobeDeteled] = useState<number>()
  const [tobeActivated, settobeActivated] = useState<string>()
  const [tobeDeactivated, settobeDeactivated] = useState<string>()
  const [tobeLocked, settobeLocked] = useState<number>()
  const [showActivatemodal, setshowActivatemodal] = useState(false)
  const [showdeActivatemodal, setshowdeActivatemodal] = useState(false)
  const [showLockemodal, setshowdeLockemodal] = useState(false)
  const [password, setPassword] = useState('')
  const [isProtected, setIsProtected] = useState<any>(0)
  const [isPassword, setIsPassword] = useState<any>(0)
  const inputFeild = useRef<HTMLInputElement | null>(null)
  const [noResults, setNoresults] = useState(true)
  
  const getAllAPIData = async () => {
    try {
      setisloading(true)
      const response = await onGet('/all_cards')
      const responseList: any = response?.data?.['cards']
      // console.log(responseList.length);
      

      let cardData: any
      if (inputFeild.current) {
        const value = inputFeild.current.value.trim()
        // console.log('Value:', value)
        if (value !== '' || value !== undefined || value !== null) {
          // console.log('Searching for:', value);
          const cardDataFiltered = responseList.filter((obj: any) => {
            // Check Name & Company Name wise Search
            const firstNameMatch = obj.first_name.toLowerCase().includes(value.toLowerCase());
            const companyNameMatch = obj.company_name ? obj.company_name.toLowerCase().includes(value.toLowerCase()) : false;
            // console.log('First Name Match:', firstNameMatch);
            // console.log('Company Name Match:', companyNameMatch);
            return firstNameMatch || companyNameMatch;
          });
          // console.log('Filtered Data:', cardDataFiltered);
          // cardData = cardDataFiltered.length > 0 ? cardDataFiltered : response?.data?.['cards'];
          cardDataFiltered === undefined
            ? (cardData = response?.data?.['cards'])
            : (cardData = cardDataFiltered)
        }
        else{
          cardData = response?.data?.['cards']
        }
      } else {
        cardData = response?.data?.['cards']
      }
      console.log('Card Data Lengthhhhh: ', cardData.length);

      // To test if cardData value is true or not
      if (cardData.length === 0) {
        // console.log('Inside if block debug')
        setNoresults(true)
      } else if (cardData[0].order_details.length === 0) {
        const filteredCardData = cardData.filter((card: any) => card.order_details.length === 0)
        filteredCardData.length === cardData.length ? setNoresults(true) : setNoresults(false)
        // console.log('Inside else if block:')
      } else {
        // console.log('Inside else block debug:')
        setNoresults(false)
      }

      // console.log('No results: ', noResults)

      const AllCardInfoData: ListAllCardInterface[] = cardData
      setAllCards(AllCardInfoData)
      setisloading(false)
    } catch (error) {
      console.log(error)

      setisloading(false)
    }
  }
  const debounceSearch = debounce(getAllAPIData, 300);

  const handleDeleteCard = async () => {
    const response = await onGet(`/deactivate/${tobeDeactivated}`)
    // console.log(response)
    if (response.status === 200) {
      setshowdeActivatemodal(false)
      getAllAPIData()
    }
  }

  useEffect(() => {
    getAllAPIData()
  }, [])



  const handleAllFilter = (data: any) => {
    setAllCards(data)
  }

  const handleDeleteAPI = async () => {
    try {
      const response = await onGet(`/delete/card/${tobeDeteled}/`)
      console.log(response)
      if (response.status === 200) {
        setShowDeleteModal(false)
        getAllAPIData()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const passwordNull = async (id: any) => {
    try {
      let response = await onGet('/is_protected_deactivated/' + id)
      if (response.status == 200) {
        setIsPassword(true)
      } else {
        setIsPassword(false)
      }
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }
  const handleActive = async () => {
    const response = await onGet(`/activate/${tobeActivated}`)

    if (response.status === 200) {
      setshowActivatemodal(false)
      getAllAPIData()
    }
  }
  const handleLock = async () => {
    try {
      if (isPassword) {
        let response = await onPut('/activate/card_password/' + tobeLocked, {
          current_password: password,
        })
        if (response.status == 401) {
          toast.error('Password is wrong')
        } else if (response.status == 200) {
          toast.success('Card is locked')
          getAllAPIData()
          setshowdeLockemodal(false)
        }
      } else {
        let response = await onPost('/store/card_password', {card_id: tobeLocked, password})
        if (response.status == 201) {
          toast.success('Password has been created successfully')
          setshowdeLockemodal(false)
          getAllAPIData()
        } else {
          toast.error('Something went wrong')
        }
      }
    } catch (error) {
      toast.error('Something went wrong')
    }
  }
  const handleUnlock = async () => {
    try {
      let response = await onPut('/deactivate/card_password/' + tobeLocked, {
        card_id: tobeLocked,
        current_password: password,
      })
      if (response.status == 200) {
        getAllAPIData()
        toast.success('Card unlocked')
        setshowdeLockemodal(false)
      } else {
        toast.error('Invalid Password')
      }
    } catch (error) {
      toast.error('Something went wrong')
    }
  }


  return (
    <>
      <MetaTagWrapper title='All Cards || Tap Savvy' />
      <div className='card card-xl-stretch mb-xl-8'>
        {/* begin::Header */}
        <div className='card-header align-items-center border-0 mt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold text-muted'>
              {isDraft === 'true' ? 'Draft Cards' : `Total Cards : ${allCards.length}`}
            </span>
            
            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>


          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-link btn-lg text-primary'
              onClick={() => getAllAPIData()}
            >
              <img
                src={toAbsoluteUrl('/media/svg/refresh-svgrepo-com (1).svg')}
                height={20}
                width={20}
                alt='refresh'
              />
            </button>

          
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <Dropdown1
              filterAllCards={filterAllCards}
              setFilterAllCards={setFilterAllCards}
              allCards={allCards}
              handleAllFilter={handleAllFilter}
            />
            {/* end::Menu */}

          </div>

          {/* search functionality  */}
          <input
            ref={inputFeild}
            className='form-control mb-5'
            type='text'
            name=''
            id=''
            placeholder='Search by Name or Company'
            // onChange={getAllAPIData}
            onChange={debounceSearch}

          />
          {noResults === true && (
            <div className='text-center fs-2 text-light mt-4 w-100'>No results found</div>
          )}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        {isloading ? (
          <div className='d-flex justify-content-center align-items-center custom-spinner'>
            <div className='spinner-grow text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body pt-3'>
            {isDraft === 'true' ? (
              <>
                {allCards.map(
                  (item, index) =>
                    item.order_details?.length! <= 0 && (
                      <div className='d-flex align-items-sm-center mb-7' key={item.id}>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-60px symbol-2by3 me-4'>
                          {!item.order_details?.at(0)?.template?.profile_image ? (
                            <>
                              <span
                                className='symbol-label'
                                style={{
                                  backgroundColor: '#800080',
                                  fontSize: '25px',
                                  fontWeight: '700',
                                  color: 'white',
                                }}
                              >
                                {/* <KTIcon iconName='abstract-26' className='fs-2x text-success' /> */}
                                {/* <KTIcon iconName='abstract-26' className='fs-2x text-success' /> */}
                                {item.first_name?.substring(0, 1).toUpperCase()}
                              </span>
                            </>
                          ) : (
                            <>
                              <div
                                className='symbol-label'
                                style={{
                                  backgroundImage: item.order_details?.at(0)?.template
                                    ?.profile_image
                                    ? `url(${
                                        profileImageUrl +
                                        item.order_details?.at(0)?.template?.profile_image
                                      })`
                                    : `url(${
                                        COVER_IMAGE_URL +
                                        item.order_details?.at(0)?.template?.cover_image
                                      })`,
                                }}
                              ></div>
                            </>
                          )}
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Title */}
                        <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
                              {item.first_name}
                            </span>
                            <span className='text-muted fw-semibold d-block pt-1 fs-7'>
                              Card Code: {item.card_code}
                            </span>
                            <a
                              href={item.order_details?.at(0)?.card_url}
                              className='text-blue fw-semibold d-block pt-1 fs-7'
                            >
                              {item.order_details?.at(0)?.card_url}
                            </a>
                          </div>
                          <div className='mx-10 text-muted fw-semibold d-block pt-1 fs-8'>
                            Created At:
                            <span className='pt-1 fs-9'>
                              {DateFormateHelper(item.order_details?.at(0)?.created_at)}
                            </span>
                          </div>
                          <Link
                            to={`/pages/wizards/editcard/${item.id}?iscompleted=${
                              item.order_details?.length! > 0 ? true : false
                            }`}
                          >
                            <button
                              type='button'
                              className='badge badge-light-primary border border-0 fs-8 fw-bold my-2 mx-2'
                            >
                              Edit
                            </button>
                          </Link>
                          {item.status === 1 && (

                            <button
                              type='button'
                              className='badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2'
                              onClick={() => {
                                setshowdeActivatemodal(true)
                                settobeDeactivated(item.card_code)
                              }}
                            >
                              Deactivate
                            </button>
                          )}
                          <button
                            type='button'
                            className='badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2'
                            onClick={() => {
                              setShowDeleteModal(true)
                              settobeDeteled(item.id)
                            }}
                          >
                            Delete
                          </button>
                          {showDeleteModal && (
                            <div className='modal fade show' style={{display: 'block'}}>
                              <div className='modal-dialog modal-dialog-centered'>
                                <div className='modal-content'>
                                  <div className='modal-header'>
                                    <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                                      Are you sure you want to delete this card ?
                                    </h5>
                                  </div>
                                  <div className='modal-body'>
                                    <h3>You can create this card later if you want !</h3>
                                  </div>
                                  <div className='modal-footer'>
                                    <button
                                      className='btn btn-secondary'
                                      onClick={() => setShowDeleteModal(false)}
                                    >
                                      close
                                    </button>
                                    <button
                                      type='button'
                                      className='btn text-white'
                                      style={{backgroundColor: '#F24183'}}
                                      onClick={() => {
                                        handleDeleteAPI()
                                      }}
                                    >
                                      delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* Modal start */}

                          {/* Modal end */}
                        </div>
                        {/* end::Title */}
                      </div>
                    )
                )}
              </>
            ) : (
              <>
                {allCards?.map(
                  (item, index) =>
                    item.order_details?.length! > 0 && (
                      <div className='d-flex align-items-sm-center mb-7' key={item.id}>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-60px symbol-2by3 me-4'>
                          <div
                            className='symbol-label'
                            style={{
                              backgroundImage: item.order_details?.at(0)?.template?.profile_image
                                ? `url(${
                                    profileImageUrl +
                                    item.order_details?.at(0)?.template?.profile_image
                                  })`
                                : `url(${
                                    COVER_IMAGE_URL +
                                    item.order_details?.at(0)?.template?.cover_image
                                  })`,
                            }}
                          ></div>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Title */}
                        <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
                              {item.first_name}
                            </span>
                            <span className='text-muted fw-semibold d-block pt-1 fs-7'>
                              Card Code: {item.card_code}
                            </span>
                            <a
                              href={item.order_details?.at(0)?.card_url}
                              className='text-blue fw-semibold d-block pt-1 fs-7'
                            >
                              {item.order_details?.at(0)?.card_url}
                            </a>
                          </div>
                          <div className='mx-10 text-muted fw-semibold d-block pt-1 fs-8'>
                            Created At:{' '}
                            <span className='pt-1 fs-9'>
                              {DateFormateHelper(item.order_details?.at(0)?.created_at)}
                            </span>
                          </div>
                          <Link
                            to={`/customise/card/${item.id}`}
                            // onClick={() => setShowCreateAppModal(true)}
                            className='badge badge-light-warning border border-0 fs-8 fw-bold my-2 mx-2 btn-primary'
                          >
                            <i className='fa-solid fa-wand-magic-sparkles'></i>
                          </Link>
                          <button
                            onClick={() => {
                              setshowdeLockemodal(true)
                              settobeLocked(item.id)
                              setIsProtected(item.isProtected)
                              passwordNull(item.id)
                            }}
                            className={
                              'badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2 btn-primary'
                            }
                          >
                            {item.isProtected == 0 ? (
                              <i className='fa fa-unlock-alt text-success' aria-hidden='true'></i>
                            ) : (
                              <i className='fa fa-lock text-danger' aria-hidden='true'></i>
                            )}
                          </button>

                          <Link to={`/user/dashboard/${item.id}`}>
                            <button className=''
                            style={
                              {
                                background:'rgba(0,0,0,0.1)',
                                border:'none',
                                borderRadius:'20%',
                                padding:'0.4em',
                                outline:'none'
                              }
                            }>
                            <i className="fas fa-chart-line"
                            style={
                              {
                                color:'#56BBF1'
                               
                              }
                            }></i>
                            </button>
                          </Link>

                          <Link
                            to={`/pages/wizards/editcard/${item.id}?iscompleted=${
                              item.order_details?.length! > 0 ? true : false
                            }`}
                          >
                            <button
                              type='button'
                              className='badge badge-light-primary border border-0 fs-8 fw-bold my-2 mx-2'
                            >
                              Edit
                            </button>
                          </Link>
                          {item.status === 1 && (
                            <button
                              type='button'
                              className='badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2'
                              onClick={() => {
                                setshowdeActivatemodal(true)
                                settobeDeactivated(item.card_code)
                              }}
                            >
                              Deactivate
                            </button>
                          )}
                          {item.status === 0 && (
                            <button
                              type='button'
                              className='badge badge-light-success border border-0 fs-8 fw-bold my-2 me-2'
                              onClick={() => {
                                setshowActivatemodal(true)
                                settobeActivated(item.card_code)
                              }}
                            >
                              Approve
                            </button>
                          )}
                          <button
                            type='button'
                            className='badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2'
                            onClick={() => {
                              setShowDeleteModal(true)
                              settobeDeteled(item.id)
                            }}
                          >
                            Delete
                          </button>
                          {showDeleteModal && (
                            <div className='modal fade show' style={{display: 'block'}}>
                              <div className='modal-dialog modal-dialog-centered'>
                                <div className='modal-content'>
                                  <div className='modal-header'>
                                    <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                                      Are you sure you want to delete this card ?
                                    </h5>
                                  </div>
                                  <div className='modal-body'>
                                    <h3>You can create this card later if you want !</h3>
                                  </div>
                                  <div className='modal-footer'>
                                    <button
                                      className='btn btn-secondary'
                                      onClick={() => setShowDeleteModal(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type='button'
                                      className='btn text-white'
                                      style={{backgroundColor: '#F24183'}}
                                      onClick={() => {
                                        handleDeleteAPI()
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* Modal start */}

                          {/* Modal end */}
                        </div>
                        {/* end::Title */}
                      </div>
                    )
                )}
              </>
            )}
          </div>
        )}
        {/* end::Body */}
        {showActivatemodal && (
          <div className='modal fade show' style={{display: 'block'}}>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h3 className='modal-title text-gray-800 fw-bold fs-6'>Please Confirm</h3>
                </div>
                <div className='modal-body'>
                  <h2>Activate this card?</h2>
                </div>
                <div className='modal-footer'>
                  <button className='btn btn-primary' onClick={handleActive}>
                    Activate
                  </button>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => setshowActivatemodal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showdeActivatemodal && (
          <div className='modal fade show' style={{display: 'block'}}>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h1 className='modal-title text-gray-800 fw-bold fs-6' id='exampleModalLabel'>
                    Are you sure you want to deactivate this card?
                  </h1>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  ></button>
                </div>
                <div className='modal-body'>
                  <h2>You can activate this card later if you want !</h2>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => setshowdeActivatemodal(false)}
                  >
                    Close
                  </button>
                  <button
                    type='button'
                    className='btn text-white btn-primary'
                    onClick={handleDeleteCard}
                  >
                    Deactivate{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showLockemodal && (
          <div className='modal fade show' style={{display: 'block'}}>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h1 className='modal-title text-gray-800 fw-bold fs-6' id='exampleModalLabel'>
                    Are you sure you want to {isProtected == 0 ? ' Lock ' : 'unlock'} the card?
                  </h1>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setshowdeLockemodal(false)}
                  ></button>
                </div>
                <div className='modal-body'>
                  <h2>
                    You can {isProtected == 0 ? 'unlock' : 'lock'} this card later if you want !
                  </h2>
                  <br />
                  <label className='form-label'>
                    {isProtected == 0 ? (
                      <>
                        {isPassword ? 'Please enter password to lock this card' : 'Create Password'}
                      </>
                    ) : (
                      <>
                        {isPassword
                          ? 'Confirm Password to lock the card'
                          : 'Please enter password to unlock this card'}
                      </>
                    )}
                  </label>
                  <input
                    className='form-control'
                    type='number'
                    placeholder='eg. 123456'
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => setshowdeLockemodal(false)}
                  >
                    Close
                  </button>
                  {isProtected == 0 ? (
                    <button
                      type='button'
                      className={
                        'btn text-white btn-primary' + (password.length == 6 ? ' ' : ' disabled')
                      }
                      onClick={handleLock}
                    >
                      Lock{' '}
                    </button>
                  ) : (
                    <button
                      type='button'
                      className={
                        'btn text-white btn-primary' + (password.length == 6 ? ' ' : ' disabled')
                      }
                      onClick={handleUnlock}
                    >
                      Unlock{' '}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  )

  // function openModal(item: any) {
  //   return (

  //   )
  // }
}

export {ListsWidget8}
