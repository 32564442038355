/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
// import { KTIcon } from '../../../helpers'
// import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import MetaTagWrapper from '../../../../app/modules/helpers/MetaTagWrapper'
import axios from 'axios'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import 'react-toastify/dist/ReactToastify.css'
import { toast , ToastContainer} from 'react-toastify'
import { APPURL } from '../../../../app/modules/helpers/imageUrl'

const URL = APPURL

type Props = {
  className?: string
}

const ListsWidget5: FC<Props> = ({ className }) => {

  const { onPost } = useApiHook()
  const formData = new FormData()
  const [file, setFile] = useState<File | null>(null)

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    try {
      if (file) {
        formData.append('exel_file', file)
      }

      const token = localStorage.getItem('token')
      const axiosInstance = axios.create({
        baseURL: URL,
      });
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'accept': 'application/json',
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': 'http://localhost:3011',
          Authorization: `Bearer ${token}`,
        },
      }
      const res: any = await axiosInstance.post(`${URL}/import_excel`, formData, config)
      if (res.status === 200) {
        toast.success(`${res?.data?.data?.message}`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }

      if (res.status !== 200) {
        toast.error(`${res?.data?.['errors'][0]}`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    }
    catch (error) {
      console.log(error);
      toast.error('File Upload Failed, having same email and mobile number, Please Check, ', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }

  return (
    <>
      <MetaTagWrapper title='Bulk Card Creation || Tap Savvy' />
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header align-items-center justify-content-center border-0 mt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold text-muted fs-2 mb-2 text-dark'>Bulk Card Creation</span>
          </h3>
          {/* <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <Dropdown1 />
          </div> */}
        </div>
        {/* end::Header */}
        <div className='mx-auto mw-600px w-100 pt-15 pb-10'>
          <div className='card'>
            <div className='card-body pt-5'>
              <div className='w-100'>
                <form onSubmit={handleSubmit}>
                  <div className='pb-10 pb-lg-15'>
                    <label className='form-label fs-6'>
                      File Upload <span style={{ color: '#EF305E' }}>*</span>
                    </label>
                    <input
                      type='file'
                      autoComplete='off'
                      required
                      // accept="*/*"
                      accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      placeholder='Choose files'
                      className='form-control bg-transparent'
                      onChange={(e) => setFile(e.target.files?.[0] || null)}
                    />
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary mb-5'
                      disabled={!file}
                    >Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='card-body pt-5'>
          <div className='timeline-label'>
            <div className='timeline-item'>
              <div className='timeline-label fw-bold text-gray-800 fs-6'>08:42</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-warning fs-1'></i>
              </div>
              <div className='fw-mormal timeline-content text-muted ps-3'>
                Outlines keep you honest. And keep structure
              </div>
            </div>
            <div className='timeline-item'>
              <div className='timeline-label fw-bold text-gray-800 fs-6'>10:00</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-success fs-1'></i>
              </div>
              <div className='timeline-content d-flex'>
                <span className='fw-bold text-gray-800 ps-3'>AEOL meeting</span>
              </div>
            </div>
            <div className='timeline-item'>
              <div className='timeline-label fw-bold text-gray-800 fs-6'>14:37</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-danger fs-1'></i>
              </div>
              <div className='timeline-content fw-bold text-gray-800 ps-3'>
                Make deposit
                <a href='#' className='text-primary'>
                  USD 700
                </a>
                . to ESL
              </div>
            </div>
            <div className='timeline-item'>
              <div className='timeline-label fw-bold text-gray-800 fs-6'>16:50</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-primary fs-1'></i>
              </div>
              <div className='timeline-content fw-mormal text-muted ps-3'>
                Indulging in poorly driving and keep structure keep great
              </div>
            </div>
            <div className='timeline-item'>
              <div className='timeline-label fw-bold text-gray-800 fs-6'>21:03</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-danger fs-1'></i>
              </div>
              <div className='timeline-content fw-semibold text-gray-800 ps-3'>
                New order placed
                <a href='#' className='text-primary'>
                  #XF-2356
                </a>
                .
              </div>
            </div>
            <div className='timeline-item'>
              <div className='timeline-label fw-bold text-gray-800 fs-6'>16:50</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-primary fs-1'></i>
              </div>
              <div className='timeline-content fw-mormal text-muted ps-3'>
                Indulging in poorly driving and keep structure keep great
              </div>
            </div>
            <div className='timeline-item'>
              <div className='timeline-label fw-bold text-gray-800 fs-6'>21:03</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-danger fs-1'></i>
              </div>
              <div className='timeline-content fw-semibold text-gray-800 ps-3'>
                New order placed
                <a href='#' className='text-primary'>
                  #XF-2356
                </a>
                .
              </div>
            </div>
            <div className='timeline-item'>
              <div className='timeline-label fw-bold text-gray-800 fs-6'>10:30</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-success fs-1'></i>
              </div>
              <div className='timeline-content fw-mormal text-muted ps-3'>
                Finance KPI Mobile app launch preparion meeting
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <ToastContainer/>
    </>
  )
}

export { ListsWidget5 }
