import { lazy, FC, Suspense, useState, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Customization from '../pages/customization/customization'
import SocialMedias from '../modules/widgets/components/admin/SocialMedias'
import Roles from "../modules/widgets/components/admin/Roles"
import AllUserList from '../modules/widgets/components/admin/AllUserList'
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import BulkCardCreation from '../modules/widgets/components/admin/BulkCardCreation'
import Permisions from '../modules/widgets/components/admin/Permisions'

import AllTickets from '../modules/widgets/components/admin/AllTickets'
import CreateTicket from '../modules/widgets/components/admin/CreateTicket';
import ShowTicket from '../modules/widgets/components/admin/ShowTicket'
import TicketReply from '../modules/widgets/components/admin/TicketReply'
import { UserDashboard } from '../pages/dashboard/UserDashboard'


const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const [userType, setUserType] = useState<any>()
  useEffect(() => {
    setUserType(localStorage.getItem('user_type'))
  }, [])

  const widgetsBreadCrumbs: Array<PageLink> = [
    {
      title: '',
      path: '/crafted/widgets/charts',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {userType == 0 ? (
          <>
          <Route path='auth/*' element={<Navigate to='/crafted/widgets/lists' />} />
          <Route path='login' element={<Navigate to='/crafted/widgets/lists' />} />
          </>
        ) : (
          <>
          <Route path='auth/*' element={<Navigate to='/crafted/widgets/allcards' />} />
          <Route path='login' element={<Navigate to='/crafted/widgets/allcards' />} />
          </>
        )}
        {/* Pages */}
        {/* <Route path='login' element={<Navigate to='/crafted/widgets/lists' />} /> */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='/user/dashboard/:id' element={<UserDashboard />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='customise/card/:cid' element={<Customization />} />
        <Route path='socialmedia' element={<><PageTitle breadcrumbs={widgetsBreadCrumbs}>Social Media</PageTitle><SocialMedias /></>} />
        <Route path='createTicket' element={<><PageTitle breadcrumbs={widgetsBreadCrumbs}>Create Ticket</PageTitle><CreateTicket  /></>} />
        <Route path='showTicket' element={<><PageTitle breadcrumbs={widgetsBreadCrumbs}>Show Ticket</PageTitle><ShowTicket  /></>} />
        <Route path='roles' element={<><PageTitle breadcrumbs={widgetsBreadCrumbs}>Roles</PageTitle><Roles /></>} />
        <Route path='permisions' element={<><PageTitle breadcrumbs={widgetsBreadCrumbs}>Permisions</PageTitle><Permisions /></>} />
        <Route path='supportTicket/:id/reply' element={<><PageTitle breadcrumbs={widgetsBreadCrumbs}>Support Ticket</PageTitle><TicketReply /></>} />
        
        <Route path='all-user' element={<>
          <PageTitle breadcrumbs={widgetsBreadCrumbs}>
            Registered Users
          </PageTitle>
          <AllUserList /></>}
        />
        <Route path='bulk-card-creation' element={
          <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>
              Bulk Card Creation
            </PageTitle>
            <BulkCardCreation />
          </>
        }>
        </Route>

        <Route path='all-tickets-Manage' element={
          <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>
              Tickets Manages
            </PageTitle>
            <AllTickets />
          </>
        }>
          
        </Route>

        {/* Lazy Modules */}
        <Route
          path='pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
