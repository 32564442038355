/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {
  SocialType,
  SocialTypeInfo,
} from '../../../../app/modules/widgets/components/admin/inerfaces/SocialMediaInterface'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import {SOCIAL_MEDIA_URL} from '../../../../app/modules/helpers/imageUrl'
import axios from 'axios'
import {APPURL} from '../../../../app/modules/helpers/imageUrl'
import MetaTagWrapper from '../../../../app/modules/helpers/MetaTagWrapper'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import {string} from 'yup'
const socialMediaURL = SOCIAL_MEDIA_URL
const BASE_URL = APPURL

type Props = {
  className: string
  tabIndex: any
}
interface AssignPermission {
  id?: number
  name?: string
  permissions?: any
}
interface permissionsType {
id?:number;
name?:string;
}
interface RoleType {
  id?: number
  name?: string
  permissions? : Array<permissionsType> //need to change
}

const axiosInstance = axios.create({
  // Axios instance configuration
  baseURL: BASE_URL,
  // Other options...
})
interface NameInfo {
  name?: string
}

const RoleList: React.FC<Props> = ({className, tabIndex}) => {
  const {onPost, onGet} = useApiHook()
  const [roleList, setRoleList] = useState<RoleType[]>([])
  // const [permissionList, setPermissionList] = useState<PermissionType[]>([])
  const [showModal, setShowModal] = useState(false)
  const [showPermissionModel, setShowPermissionModal] = useState(false)
  const [showViewPermissionModel, setShowViewPermissionModal] = useState(false)
  const [showModalAddIcon, setShowModalAddIcon] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [inputValue, setInputValue] = useState<SocialTypeInfo>({})
  const [statusCode, setStatusCode] = useState<any>(1)
  const [selectedRole, setSelectedRole] = useState<any>([])
  const [tagUi, setTagUi] = useState<any>([])
  // const [tagList, setTagList] = useState<tagListType>({});
  const [name, setName] = useState('')
  const [toBedeleted, settoBedeleted] = useState<number>()
  const [toBeAddedPermission, settoAddedPermission] = useState<number>()
  const getRoleList = async () => {
    try {
      let response = await onGet('/admin/show_assigned_roles')
      console.log(response)
      const res: any = response.data
      const role_types: RoleType[] = res
      setRoleList(role_types)
    } catch (error) {
      console.log(error)
    }
  }
  type tagType = {
    label?: string
    value?: string
  }
  var tagArray: Array<tagType> = []
  const getPermissionList = async () => {
    try {
      let response = await onGet('/admin/show_permissions')
      console.log(response)
      const res: any = response.data
      for (let i = 0; i < res.length; i++) {
        tagArray.push({value: res[i].id, label: res[i].name})
      }
      setTagUi(tagArray)
    } catch (error) {}
  }
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'access-control-allow-origin': '*',
      'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
    },
  }
  useEffect(() => {
    getPermissionList()
    getRoleList()
  }, [])
  const handleDeleteMediaIcon = async () => {
    setShowModal(true)
  }
  const handleSubmit = async () => {
    try {
      let response = await onPost('/admin/create_role', {name: name})
      console.log(response.data, 'data')
      if (response.status == 201) {
        toast.success('Role created Successfully', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        getRoleList()
        setShowModalAddIcon(false)
      } else if (response.status == 422) {
        toast.error(`${name} has been already registered`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }
  const deleteRole = async () => {
    try {
      const response = await axiosInstance.delete(`/remove_role/${toBedeleted}`, config)
      if (response.status == 200) {
        toast.success('Role deleted successfully', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setShowModal(false)
        getRoleList()
      }
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }
  const addPermission = async () => {
    try {
      let permissionList = []
      for (let i = 0; i < selectedRole.length; i++) {
        permissionList[i] = selectedRole[i].label
      }
      let response = await onPost('/admin/assign_permissions_to_role', {
        permission: permissionList,
        role_id: toBeAddedPermission,
      })
      if(response.status==200){
        toast.success('Permissions added successfully', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
      setShowPermissionModal(false)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <MetaTagWrapper title='Social Media || Tap Savvy' />
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title fw-bold text-muted'>Admin Roles</h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary btn-sm btn-active-light-primary'
              onClick={() => setShowModalAddIcon(true)}
            >
              Add Roles
            </button>
            {showModalAddIcon && (
              <div className='modal fade show' style={{display: 'block'}}>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                        Add Role
                      </h5>
                    </div>
                    <form>
                      <div className='modal-body'>
                        <div className='pb-10 pb-lg-5'>
                          <label className='form-label fs-6'>Role Name</label>
                          <input
                            type='text'
                            name='name'
                            className='form-control'
                            placeholder='Enter the Role Name'
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button
                          className='btn btn-danger'
                          onClick={() => setShowModalAddIcon(false)}
                        >
                          close
                        </button>
                        <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-2'>
          {/* begin::Item */}
          <div className='d-flex flex-column mb-3'>
            {/* begin::Description */}
            {roleList &&
              roleList?.map((item, index) => (
                <div
                  className='d-flex align-items-center justify-content-between mb-8'
                  key={item.id}
                >
                  <div className='d-flex align-items-center ms-5'>
                    <span className='text-muted fw-semibold d-block mx-5'>{item.name}</span>
                  </div>

                  <div className='d-flex align-items-center me-5'>
                    <button
                      className='badge badge-light-info border-0 fs-8 fw-bold me-3'
                      onClick={() => {
                        setShowViewPermissionModal(true)
                      }}
                    >
                      View Permissions
                    </button>
                    <button
                      className='badge badge-light-primary border-0 fs-8 fw-bold me-3'
                      onClick={() => {
                        setShowPermissionModal(true)
                        settoAddedPermission(item.id)
                      }}
                    >
                      Add Permissions
                    </button>
                    <button
                      className='badge badge-light-danger border-0 fs-8 fw-bold me-3'
                      onClick={() => {
                        setShowModal(true)
                        settoBedeleted(item.id)
                      }}
                    >
                      Delete
                    </button>
                    {showModal && (
                      <div className='modal fade show' style={{display: 'block'}}>
                        <div className='modal-dialog modal-dialog-centered'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                                Are you sure you want to delete this role ?
                              </h5>
                            </div>
                            <div className='modal-body'>
                              <h2>You can add this role later if you want !</h2>
                            </div>
                            <div className='modal-footer'>
                              <button
                                className='btn btn-secondary'
                                onClick={() => setShowModal(false)}
                              >
                                close
                              </button>
                              <button
                                type='button'
                                className='btn text-white'
                                style={{backgroundColor: '#F24183'}}
                                onClick={() => {
                                  deleteRole()
                                }}
                              >
                                delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showPermissionModel && (
                      <div className='modal fade show' style={{display: 'block'}}>
                        <div className='modal-dialog modal-dialog-centered'>
                          <div className='modal-content' style={{height: '350px'}}>
                            <div className='modal-header'>
                              <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                                Add Permission to this Role
                              </h5>
                            </div>
                            <div className='modal-body'>
                              <label className='form-label text-muted fw-semibold'>
                                Select Permissionse from the dropdown
                              </label>
                              <Select
                                isMulti
                                options={tagUi}
                                onChange={(e) => setSelectedRole(e)}
                              />
                            </div>
                            <div className='modal-footer'>
                              <button
                                className='btn btn-secondary'
                                onClick={() => setShowPermissionModal(false)}
                              >
                                close
                              </button>
                              <button
                                type='button'
                                className='btn text-white btn-primary'
                                onClick={() => {
                                  addPermission()
                                }}
                              >
                                Add Permission
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showViewPermissionModel && (
                      <div className='modal fade show' style={{display: 'block'}}>
                        <div className='modal-dialog modal-dialog-centered'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                                Permission Added To This Role
                              </h5>
                            </div>
                            <div className='modal-body d-flex'>
                              
                                  <div className='badge mx-1 badge-light-success'>
                                    {/* <span className='me-2'>{roleList[0].id}</span>
                                    <span className='me-2'>{roleList[0].name}</span> */}
                                    <span className='me-2'>{roleList[0].permissions!==undefined ? roleList[0].permissions[0]?.name: "All"}</span>
                                    <span className='text-danger'></span>
                                  </div>
                               
                            </div>
                            <div className='modal-footer'>
                              <button
                                className='btn btn-danger'
                                onClick={() => setShowViewPermissionModal(false)}
                              >
                                close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            {/* end::Description */}
          </div>
        </div>
        {/* end::Body */}
      </div>
      <ToastContainer />
    </>
  )
}

export {RoleList}
