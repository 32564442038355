import React, { FC, useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import useApiHook from '../../../hook/useApiHook'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { platform } from 'os'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface ChildProps {
  isSubmitButton?: any
  stepper?: any
  prevStep?: any
  id?: any
}

interface upiID {
  name?: string
  logo?: string
}

interface AccountInfo {
  card_id?: number
  bank_name?: string
  account_holder_name?: string
  account_number?: number
  ifsc_code?: string
  gst_no?: string
}
interface savedupi {
  platform: upiID
  link: string
}
export const upiProviders: upiID[] = [
  { name: 'Google Pay', logo: '/media/svg/payment-methods/gpay.svg' },
  { name: 'PhonePe', logo: '/media/svg/payment-methods/phonepe.svg' },
  { name: 'Paytm', logo: '/media/svg/payment-methods/paytm.svg' },
  { name: 'Airtel Payments', logo: '/media/svg/payment-methods/airtel.svg' },
  { name: 'Amazon Pay', logo: '/media/svg/payment-methods/amazonpay.svg' },
  { name: 'Mobikwik', logo: '/media/svg/payment-methods/mobikwik.svg' },
  { name: 'freecharge', logo: '/media/svg/payment-methods/freecharge.svg' },
  { name: 'BHIM UPI', logo: '/media/svg/payment-methods/bhim-upi-icon.svg' },
  // Add more UPI providers as needed
]

const BASE_URL = process.env.REACT_APP_API_URL
const Step2: FC<ChildProps> = ({ isSubmitButton, stepper, prevStep, id }) => {
  const { onPost } = useApiHook()
  const store_id = localStorage.getItem('card_type')
  const [inputValue, setInputValue] = useState<AccountInfo>({})
  const [selectedPlatform, setselectedPlatform] = useState<upiID>({})
  const [upiId, setupiId] = useState('')
  const [qr, setqr] = useState('')
  const [savedUpis, setsavedUpis] = useState<savedupi[]>([])
  // const [upis, setupis] = useState([])
  const [errors, setErrors] = useState<any>('')

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setInputValue({
      ...inputValue,
      [name]: value,
    })
  }

  const handleFileChange = (event: any) => {
    event.preventDefault()
    const file = event.target.files[0]
    setqr(file)
    console.log(file)
  }
  useEffect(() => {
    setInputValue({
      ...inputValue,
      card_id: id,
    })
  }, [id])

  const handleUpiSubmit = async (event: any) => {
    event.preventDefault()

    try {
      const formdata = new FormData()
      formdata.append('card_id', id)
      formdata.append('platform_name', selectedPlatform.name!)
      formdata.append('upi_id', upiId)
      formdata.append('qr_code', qr)

      const token = localStorage.getItem('token')
      console.log(token)
      const axiosInstance = axios.create({
        baseURL: BASE_URL,
      })

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
        },
      }

      const res = await axiosInstance.post(`/store/payment_info`, formdata, config)

      // console.log(res)
      if (res.status === 200) {
        let saved: savedupi[] = [...savedUpis, { platform: selectedPlatform, link: upiId }]
        setsavedUpis(saved)
        setupiId('')

        toast.success('UPI ID Successfully Saved', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }

      return res
    } catch (error) {
      return error
    }
  }
  const handleBankSubmit = async () => {
    // alert(inputValue)

    // try {
    const response = await onPost('/store/bank_info', inputValue)
    // console.log(response)

    if (response.status === 200) {
      setErrors('')
      toast.success('Bank Details Successfully Saved', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
    if (response.status !== 200) {
      console.log(response)
      setErrors(response?.data?.['errors'][0])

      if (errors) {
        toast.error(`${errors}`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    }
    return response
    // } catch (error) {
    //   console.log(error)
    // }
  }

  const handleSubmit = async () => {
    // const upiResponse: any = await handleUpiSubmit()
    const bankResponse: any = await handleBankSubmit()

    // if (bankResponse.status === 200) {

    // }
  }

  const handleSkip = () => {
    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
    }
  }

  return (
    <>
      <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder text-dark'>Account Info</h2>

          <div className='text-gray-400 fw-bold fs-6'>
            You can only add UPI details, Then press Next
            <Link to={''} className='link-primary fw-bolder'>
              Help Page
            </Link>
            .
          </div>
        </div>

        <form>
          {errors ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{errors}</div>
            </div>
          ) : null}

          <div className='pb-10 pb-lg-15'>
            <label className='form-label fs-6'>
              Bank Name <span style={{ color: '#EF305E', fontSize: 10 }}>Optional</span>
            </label>
            <input
              type='text'
              placeholder='Bank name'
              name='bank_name'
              onChange={handleChange}
              autoComplete='off'
              className='form-control bg-transparent'
            />
          </div>

          <div className='pb-10 pb-lg-15'>
            <label className='form-label fs-6'>
              Account Holder Name <span style={{ color: '#EF305E', fontSize: 10 }}>Optional</span>
            </label>
            <input
              type='text'
              placeholder='Account holder name'
              name='account_holder_name'
              onChange={handleChange}
              autoComplete='off'
              className='form-control bg-transparent'
            />
          </div>

          <div className='pb-10 pb-lg-15'>
            <label className='form-label fs-6'>
              Account Number <span style={{ color: '#EF305E', fontSize: 10 }}>Optional</span>
            </label>
            <input
              type='text'
              placeholder='Account number'
              name='account_number'
              onChange={handleChange}
              autoComplete='off'
              className='form-control bg-transparent'
            />
          </div>

          <div className='pb-10 pb-lg-15'>
            <label className='form-label fs-6'>
              IFSC Code <span style={{ color: '#EF305E', fontSize: 10 }}>Optional</span>
            </label>
            <input
              type='text'
              placeholder='IFSC code'
              name='ifsc_code'
              onChange={handleChange}
              autoComplete='off'
              className='form-control bg-transparent'
            />
          </div>

          <div className='pb-10 pb-lg-15'>
            <label className='form-label fs-6'>
              GST No. <span style={{ color: '#EF305E', fontSize: 10 }}>Optional</span>
            </label>
            <input
              type='text'
              placeholder='GST no.'
              name='gst_no'
              onChange={handleChange}
              autoComplete='off'
              className='form-control bg-transparent'
            />
          </div>
          <div className='pb-10'>
            <button 
              type='button' 
              className='btn btn-lg btn-primary mb-4' 
              disabled={
                !inputValue.bank_name && 
                !inputValue.account_holder_name &&
                !inputValue.account_number &&
                !inputValue.gst_no
              }
              onClick={handleSubmit}>
              <span className='indicator-label'>
                {!isSubmitButton && 'Save Bank Details'}
                {isSubmitButton && 'Submit'}
              </span>
            </button>
          </div>
          {savedUpis &&
            savedUpis.map((item, index) => (
              <div key={index} className='row row-cols-5 mb-6 mt-6 align-items-center'>
                <img
                  className=''
                  src={toAbsoluteUrl(item.platform.logo!)}
                  alt='fb'
                  height={20}
                  width={20}
                />
                <p className='col mt-4'>{item.link}</p>
              </div>
            ))}
          <div className='row justify-start g-2'>
            <label className='form-label fs-6 '>
              UPI ID <span style={{ color: '#EF305E', fontSize: 10 }}>Optional</span>
            </label>
          </div>
          <div className='row'>
            <div className='col-3 social__link mrg__bottom'>
              <select
                className='form-select'
                name='select-field'
                onChange={(e) => {
                  const data = e.target.options[e.target.selectedIndex]
                  const Selected = JSON.parse(data.dataset.customValue!)
                  console.log(data)
                  setselectedPlatform(Selected)
                }}
              >
                {' '}
                <option value=''>Choose UPI Platform</option>
                {upiProviders.map((platform, index) => {
                  return (
                    <option
                      key={index}
                      value={platform.name}
                      data-custom-value={JSON.stringify(platform)}
                    >
                      {platform.name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='col-md-3 mrg__bottom'>
              <input
                type='text'
                className='form-control'
                name='text-field'
                value={upiId}
                placeholder='Enter Upi Id'
                onChange={(e) => setupiId(e.target.value)}
              />
            </div>
            <div className='col-md-3 mrg__bottom'>
              <input
                type='file'
                accept='.png, .jpg, .jpeg'
                placeholder='Enter QR Code'
                onChange={handleFileChange}
                className='form-control'
                name='image-field'
              />
            </div>
            <div className='pb-10 pb-lg-15 col-3 mrg__bottom save__upi__btn'>
              <button
                className='btn btn-primary'
                disabled={!upiId || !selectedPlatform.name || !qr}
                onClick={handleUpiSubmit}
              >
                Save UPI
              </button>
            </div>
          </div>

          <div className='d-flex flex-stack pt-15'>
            <div className='mr-2'>
              <button
                onClick={prevStep}
                type='button'
                className='btn btn-lg btn-light-primary me-3'
                data-kt-stepper-action='previous'
              >
                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                Back
              </button>
            </div>

            <div>
              <button type='button' className='btn btn-lg btn-light-primary me-3' onClick={handleSkip}>
                <span className='indicator-label'>
                  Next
                  <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                </span>
              </button>
              {/* <button
                type='button'
                className='btn btn-lg btn-primary me-3'
                disabled={
                  !inputValue.account_holder_name &&
                  !inputValue.account_number &&
                  !inputValue.bank_name &&
                  !inputValue.ifsc_code
                }
                onClick={handleSubmit}
              >
                <span className='indicator-label'>
                  {!isSubmitButton && 'Save & Continue'}
                  {isSubmitButton && 'Submit'}
                  <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                </span>
              </button> */}
            </div>
          </div>

          {/* <div className='d-flex flex-stack pt-15 button__show'>
            <div className='mr-2'>
              <button
                type='button'
                className='btn btn-lg btn-primary me-3 mb-5 align-items-end'
                disabled={
                  !inputValue.account_holder_name &&
                  !inputValue.account_number &&
                  !inputValue.bank_name &&
                  !inputValue.ifsc_code
                }
                onClick={handleSubmit}
              >
                <span className='indicator-label'>
                  {!isSubmitButton && 'Save & Continue'}
                  {isSubmitButton && 'Submit'}
                  <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                </span>
              </button>
            </div>

            <div className='d-flex justify-content-between'>
              <button
                onClick={prevStep}
                type='button'
                className='btn btn-lg btn-light-primary me-3'
                data-kt-stepper-action='previous'
              >
                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                Back
              </button>
              <button type='button' className='btn btn-lg btn-light-primary me-3' onClick={handleSkip}>
                <span className='indicator-label'>
                  Next
                  <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                </span>
              </button>
            </div>
          </div> */}
        </form>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}

export { Step2 }
