/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect,useState} from 'react'
import {useIntl} from 'react-intl'
import { useParams } from 'react-router-dom';

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
} from '../../../_metronic/partials/widgets'
import {UserChart1} from '../../../_metronic/partials/widgets/charts/UserChart1'
import {response} from 'express'



const UserDashboardPage: FC = () => {


  
const [apiSocialFetch, setApiSocialFetch] = useState<object | null>(null);
const [totalSocialCount, setTotalSocialCount] = useState<number | null>(null);
const [saveCount, setSaveCount] = useState<number | null>(null);
const [viewCount, setViewCount] = useState<number | null>(null);
const [productList, setProductList] = useState<Array<any> | null>(null);
const API_BASE_URL = process.env.REACT_APP_API_URL
const token = localStorage.getItem('token')
const headers = new Headers()
headers.append('Authorization', `Bearer ${token}`)

const { id } = useParams();
console.log("Get ID:",id)
const [userCardID, setUserCardID] = useState<string>(id!);


const getCardView = async () => {

  try {
    const formData = new FormData()
    formData.append('card_id', `${userCardID}`)

    const apihit = await fetch(`${API_BASE_URL}/count-ips/${userCardID}`, {
      method: 'GET',
      headers: headers,
    })
        
    const data = await apihit.json();
    // console.log("data view count debug: ",data.data.view_count)
    setViewCount(data.data.view_count)

    //Api hit for getting products

    // console.log('User  card id before api hit:',userCardID)
    const productslist = await fetch(`${API_BASE_URL}/get_all_products/${userCardID}`, {
      method: 'GET',
      headers: headers,
    })
    const productData = await productslist.json();
    // console.log("Product List Debug:",productData.products)
    setProductList(productData.products)
    

  } catch (error) {
    console.log(`Error: ${error}`)
  }

}


// Fetch API data





const getDashboardData = async () => {
  try {
    const formData = new FormData()
    formData.append('card_id',`${userCardID}`)

    const apihit = await fetch(`${API_BASE_URL}/click-counts`, {
      method: 'POST',
      body: formData,
      headers: headers,
    })
        
    const data = await apihit.json();
    setSaveCount(data.save_count.save_count)
    setTotalSocialCount(data.total_counts)
    setApiSocialFetch(data.social_counts)
   
    

  } catch (error) {
    console.log(`Error: ${error}`)
  }
}

  useEffect(() => {
    getDashboardData()
    getCardView()
  },[])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
        {/* New Column  0*/}

        <div className='col-sm-12 col-md-4 col-lg-4  p-2'>
          <div className='row'>
            <div className='col-12 mb-2'>
              <CardsWidget20
                className='h-100'
                description='Contact Save Counts'
                color='#F1416C'
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                savecount = {saveCount? saveCount : "Loading..."}
                viewcount = {viewCount? viewCount : "Loading..."}
              />
            </div>
            <div className='col-12 mb-2'>
              
              <CardsWidget7
                className='h-100'
                description='Card Views Count'
                icon={false}
                stats={200}
                color='#41a6f1'
                labelColor='dark'
                textColor='light'
                viewcount = {viewCount? viewCount : ""}
              />
            </div>
          </div>
        </div>

        <div className='col-sm-12 col-md-8 col-lg-8  p-2'>
          
          <CardsWidget17 className='h-100' cardID={userCardID} datavalue={apiSocialFetch?apiSocialFetch : '' } totalSocialCount={totalSocialCount?totalSocialCount:""} />
        </div>

      
      </div>
     
      <div className='row gy-5 gx-xl-8 py-2 mb-4'>
        <div className='w-100'>

        <UserChart1 className='' cardID={userCardID} />

        </div>
      </div>
      {/* end::Row */}

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
        
        </div>
        <div className='w-100'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' datavalues={productList}/>
        </div>
      </div>
    </>
  )
}

const UserDashboard: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <UserDashboardPage />
    </>
  )
}

export {UserDashboard}
