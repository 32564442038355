import {useState, FC} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import useApiHook from '../../../hook/useApiHook'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import axios from 'axios'
import {APPURL} from '../../../helpers/imageUrl';
import { useNavigate } from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
type Props = {
  className: string
  image: string
  title: string
}
export interface ImagePath {
  file?: File
  preview?: any
}

const initialValues = {
  subject: '',
  related_service: '',
  description: '',
}
const BASE_URL = APPURL
const registrationSubAdminSchema = Yup.object().shape({
  subject: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  related_service: Yup.string().required('Related Service is required'),
  description: Yup.string().required('Discription is required'),
})
const CreateTicket: FC = () => {
  const className = 'card-xl-stretch mb-xl-8'
  const image = 'abstract-4.svg'
  const [loading, setLoading] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [imageFile, setImageFile] = useState<ImagePath | null>()
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const {onPost} = useApiHook();
  const navigate = useNavigate()
  const handleToggleEye = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleToggleEyeConfirm = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSubAdminSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const formData = new FormData()
      // axios instance
      const axiosInstance = axios.create({
        // Axios instance configuration
        baseURL: BASE_URL,
        // Other options...
      })
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
        },
      }
      try {
        if (imageFile) {
          formData.append('subject', values.subject)
          formData.append('description', values.description)
          formData.append('related_service', values.related_service)
          formData.append('image_file', imageFile.file!)
        } else {
          formData.append('subject', values.subject)
          formData.append('description', values.description)
          formData.append('related_service', values.related_service)
        }
        let response = await axiosInstance.post('/create_ticket', formData, config)
        if (response.status === 201) {
          toast.success('Ticket Created Successfully', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          setTimeout(()=>{
            navigate("/")
          }, 1000)
        } else {
          toast.error('Something went wrong', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      } catch (error) {
        toast.error('Something went wrong', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    },
  })
  return (
    <div
      className={`card bgi-no-repeat ${className}`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + image)})`,
      }}
    >
      {/* begin::Body */}
      <div className='mx-auto mw-600px w-100 pt-15 pb-10'>
        <div className='card'>
          <div className='card-body'>
            <div className='w-100'>
              <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bold text-muted fs-2 text-center'>Create Support Ticket</h2>
              </div>

              <form noValidate onSubmit={formik.handleSubmit}>
                <div className='pb-10 pb-lg-15'>
                  <label className='form-label fs-6'>
                    Subject <span style={{color: '#EF305E'}}>*</span>
                  </label>
                  <input
                    type='text'
                    autoComplete='off'
                    required
                    placeholder='Enter Subject'
                    {...formik.getFieldProps('subject')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.subject && formik.errors.subject,
                      },
                      {
                        'is-valid': formik.touched.subject && !formik.errors.subject,
                      }
                    )}
                  />
                  {formik.touched.subject && formik.errors.subject && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.subject}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='pb-10 pb-lg-15'>
                  <label className='form-label fs-6'>
                    Related Service <span style={{color: '#EF305E'}}>*</span>
                  </label>
                  <select
                    className='form-control bg-transparent'
                    onChange={(e) => formik.setFieldValue('related_service', e.target.value)}
                  >
                    <option selected>Select Service</option>
                    <option value='card'>Card</option>
                    <option value='user'>User</option>
                    <option value='admin'>Admin</option>
                    <option value='ui'>Ui</option>
                  </select>
                  {/* <input
                    type='text'
                    autoComplete='off'
                    placeholder='Enter Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                  /> */}
                  {formik.touched.related_service && formik.errors.related_service && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.related_service}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='pb-10 pb-lg-15' style={{position: 'relative'}}>
                  <label className='form-label fs-6'>
                    Image File
                  </label>
                  <input
                    type='file'
                    accept='image/*'
                    name='image_file'
                    onChange={(e) =>
                      setImageFile({
                        preview: URL.createObjectURL(e.target.files?.[0]!),
                        file: e.target.files?.[0],
                      })
                    }
                    className='form-control bg-transparent'
                  />
                </div>

                <div className='pb-10 pb-lg-15' style={{position: 'relative'}}>
                  <label className='form-label fs-6'>
                    Discription <span style={{color: '#EF305E'}}>*</span>
                  </label>
                  <textarea
                    rows={4}
                    autoComplete='off'
                    placeholder='Enter discription'
                    {...formik.getFieldProps('description')}
                    className={clsx('form-control bg-transparent')}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='d-flex justify-content-end'>
                  <button type='submit' className='btn btn-lg btn-primary mb-5'>
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end::Body */}
      <ToastContainer />
    </div>
  )
}

export default CreateTicket
