import React, {useEffect, useState, useRef} from 'react'
import Payment from '../../cardDetails/Payment/Payment'
import './template03.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import {UpdateDashboardAPI} from './updateDashboardAPI'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import './template02.css'
import {url} from 'inspector'
import {
  CARD_BG_IMAGE_URL,
  COVER_IMAGE_URL,
  PROFILE_IMAGE_URL,
  SOCIAL_MEDIA_URL,
} from '../../../modules/helpers/imageUrl'
const socialMediaURL = SOCIAL_MEDIA_URL
interface Product {
  card_id?: number
  category_name?: string
  category_pic?: string
  price?: number
  created_at?: string
  enquiry_link?: string | null
  visit_link?: string | null
  id?: number
  select_format?: string
  updated_at?: string
}

interface Gallery {
  id?: number
  card_id?: number
  image_file?: string
  created_at?: string
  updated_at?: string
}
export interface SocialType {
  id?: number
  name?: string
  logo?: any
  status?: number
  created_at?: any
  updated_at?: any
}
export interface SocialLink {
  id?: number
  card_id?: number
  social_type_id?: number
  social_link?: string
  created_at?: string
  updated_at?: string
  social_type?: SocialType
}

interface Address {
  id?: number
  card_id?: number
  address_line1?: string
  address_line2?: string
  country?: string
  state?: string
  city?: string
  pin_code?: string
  type?: string
  created_at?: string
  updated_at?: string
  address_link?: string
}

interface PaymentDetails {
  id?: number
  card_id?: number
  platform_name?: string
  upi_id?: string
  qr_code?: string
  created_at?: string
  updated_at?: string
}
export interface SocialLink {
  id?: number
  card_id?: number
  social_type_id?: number
  social_link?: string
  created_at?: string
  updated_at?: string
  social_type?: SocialType
}

export interface SocialType {
  id?: number
  name?: string
  logo?: any
  status?: number
  created_at?: any
  updated_at?: any
}
interface Template03Props {
  id?: number
  first_name?: string
  last_name?: string
  about?: string
  company_name?: string
  designation?: string
  background_color?: string
  font_color?: string
  font_style?: string
  profile_image?: string
  background_image?: string
  country_code?: string
  mobile_no?: string
  whatsapp_no?: string
  email?: string
  website_link?: string
  qr_code_image?: string
  product?: Product[]
  galleries?: Gallery[]
  payment?: PaymentDetails[]
  address?: Address[]
  socialMedia?: SocialLink[]
}

const Template03: React.FC<Template03Props> = ({
  id,
  first_name,
  last_name,
  about,
  company_name,
  designation,
  background_color,
  font_color,
  font_style,
  profile_image,
  background_image,
  country_code,
  mobile_no,
  whatsapp_no,
  email,
  website_link,
  qr_code_image,
  product,
  galleries,
  address,
  payment,
  socialMedia,
}) => {
  const [whatsappNo, setWhatsappNo] = useState('')
  const {onPost} = useApiHook()
 

  function SocialIcons(socialMedia: SocialLink) {
    return (
      <>
        <button
          style={{
            background: 'transparent',
            border: 'none',
            outline: 'none',
          }}
          onClick={async () => UpdateDashboardAPI(socialMedia.social_type?.name!, id!)}
        >
          <a href={socialMedia.social_link} target='_blank' rel='noreferrer'>
            {' '}
            <img
              className='m-2'
              src={socialMediaURL + socialMedia.social_type?.logo}
              alt={socialMedia.social_type?.name}
              style={{width: '35px'}}
            />
          </a>
        </button>
      </>
    )
  }

  // PROFILE IMAGE
  const profileIMG_base_url =
    'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/template/profile_image/'

  const default_profile_image = `https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg`
  let profile_image_url = ''
  if (profile_image) {
    profile_image_url = profileIMG_base_url + profile_image
  }

  // COVER IMAGE
  const coverIMG_base_url =
    'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/template/cover_images/'
  const coverIMG__url = `${coverIMG_base_url}${background_image}`
  let default_cover_img =
    'https://www.solidbackgrounds.com/images/950x350/950x350-gray-solid-color-background.jpg'

  // To verify if address is present or not
  function verifyAddress() {
    if ((address ?? [])[0]?.address_link || (address ?? [])[0]?.address_line1) {
      return true
    } else {
      return false
    }
  }
  // Function to open the map link in a new page
  const locationDiv = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    function openmap() {
      if (address![0].address_link) {
        // If address_link is provided, open the map link
        window.open(address![0].address_link, '_blank')
      } else {
        // If address_link is not provided, generate a new address and perform a Google Maps search
        const newAddress = `${address![0].address_line1 || ''}, ${
          address![0].address_line2 || ''
        }, ${address![0].city || ''}, ${address![0].state || ''}, ${address![0].country || ''} ${
          address![0].pin_code || ''
        }`
        if (newAddress) {
          const googleMapsSearchLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            newAddress
          )}`
          window.open(googleMapsSearchLink, '_blank')
        }
      }
    }

    const locationDivRef = locationDiv.current

    if (locationDivRef && verifyAddress()) {
      locationDivRef.addEventListener('click', openmap)

      // Remove the event listener when the component unmounts
      return () => {
        locationDivRef.removeEventListener('click', openmap)
      }
    }
  }, [locationDiv])

  // function to open map
  function mapopen() {
    if (address![0].address_link) {
      // If address_link is provided, open the map link
      window.open(address![0].address_link, '_blank')
    } else {
      // If address_link is not provided, generate a new address and perform a Google Maps search
      const newAddress = `${address![0].address_line1 || ''}, ${address![0].address_line2 || ''}, ${
        address![0].city || ''
      }, ${address![0].state || ''}, ${address![0].country || ''} ${address![0].pin_code || ''}`
      if (newAddress) {
        const googleMapsSearchLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          newAddress
        )}`
        window.open(googleMapsSearchLink, '_blank')
      }
    }
  }

  //function to make call
  function makeCall() {
    if (mobile_no) {
      const telUri = `tel:${mobile_no}`
      window.location.href = telUri
    }
  }

  //function to make email
  function makeemail() {
    // UpdateDashboardAPI('email',id!)
    const mailtoLink = `mailto:${email}`
    window.location.href = mailtoLink
  }

  //function to download qr

  const [vcard, setvcard] = useState<string>('')
  const downloadContact = async () => {
    const contact = {
      firstName: first_name,
      lastName: last_name ? last_name : '',
      phone: mobile_no,
      whatsapp: whatsapp_no ? whatsapp_no : '',
      email: email,
      company: company_name ? company_name : '',
      designation: designation ? designation : '',
      address:
        address?.length! > 0
          ? address?.at(0)?.address_line1! +
            (address?.at(0)?.city ? ' ' + address?.at(0)?.city : '') +
            (address?.at(0)?.country ? ' ' + address?.at(0)?.country : '') +
            (address?.at(0)?.pin_code ? ' ' + address?.at(0)?.pin_code : '')
          : '',
    }

    const vcard = `BEGIN:VCARD
VERSION:3.0
PRODID:-//Apple Inc.//Mac OS X 10.14.1//EN
N:${contact.lastName};${contact.firstName};;;;
FN:${contact.firstName} ${contact.lastName}
ORG:${contact.company}
TEL;TYPE=CELL;TYPE=VOICE:${contact.phone}
TEL;TYPE=WHATSAPP:${contact.whatsapp}
EMAIL:${contact.email}
ADR;TYPE=WORK:;;${contact.address};;;;
TITLE:${contact.designation}
END:VCARD`

    const vcardBlob = new Blob([vcard], {type: 'text/vcard'})
    // Create a temporary URL for the vCard file
    const vcardURL = URL.createObjectURL(vcardBlob)
    setvcard(vcardURL)
    UpdateDashboardAPI('save', id!)
  }
  // useEffect(() => {
  //   console.log('download VContact')
  //   downloadContact()
  // }, [])

  const redirectToNewLink = (link: string | null | undefined) => {
    const sanitizedLink = link ?? ''
    let newLink
    if (!sanitizedLink.startsWith('https://')) {
      newLink = `https://${sanitizedLink}`
    } else {
      newLink = sanitizedLink
    }
    window.open(newLink, '_blank', 'noopener,noreferrer')
  }

  //ENQUIRY FUNCTION
  const enquiryBTN = async (name: string, format: string, image: string, id: number) => {
    const message = `Hi, I would love to know about your ${format} ${name}`
    const encodedMessage = encodeURIComponent(message)
    const imageURL = `https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/category_pic/${image}`
    const encodedImage = encodeURIComponent(imageURL)
    let whatsappLink
    country_code!?.toString().length > 0
      ? (whatsappLink = `https://wa.me/${country_code}${whatsapp_no}?text=${encodedMessage}&attachment=${encodedImage}`)
      : (whatsappLink = `https://wa.me/${whatsapp_no}?text=${encodedMessage}&attachment=${encodedImage}`)

    window.open(whatsappLink, '_blank')
    const response = await onPost(`/product-enquiries`,{product_id:`${id}`})

  }

  // Gallery Enlarge option

  const [modalContent, setModalContent] = useState<JSX.Element | null>(null)
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null)

  const handleGalleryItemClick = (index: number) => {
    const imagefile = galleries![index].image_file
    const url = 'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/galleries/'
    const imageURL = `${url}${imagefile}`
    setModalContent(
      <img
        src={imageURL}
        alt={`Gallery Image ${index}`}
        className='img-fluid'
        style={{maxHeight: '100%', maxWidth: '100%'}}
      />
    )
    setSelectedImageIndex(index)
  }

  const card_url = window.location.href

  //QR CODE FUNCTION

  const qr_image_BASE_URL =
    'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/card_qr_code/'
  const qr_image = qr_code_image
  const qr_image_URL = `${qr_image_BASE_URL}${qr_image}`

  const downloadQR = () => {
    const link = document.createElement('a')
    link.href = qr_image_URL
    link.download = 'qr_code_image.png'
    link.click()
    UpdateDashboardAPI('qrDownload', id!)
  }

  //make whatsapp messege
  async function whatsapp_delivery() {
    whatsapp_no &&
      await UpdateDashboardAPI('whatsapp', id!)
      window.open(`https://wa.me/${country_code}${whatsapp_no}?text=hi there`, '_blank')
      
    
  }

  return (
    <div className='App'>
      <div className='template03_bgcontainer'>
        {/* THE ENTIRE CARD CONTAINER */}
        <div className='template03_card_container'>
          {/* THE CARD HEADER */}
          <div
            className='template03_card_header'
            style={{
              backgroundColor: `${background_color}`,
              opacity: '50%',
            }}
          >
            <div>
              <button
                onClick={() => {
                  if (navigator.share) {
                    navigator
                      .share({
                        title: document.title,
                        url: window.location.href,
                      })
                      .then(() => UpdateDashboardAPI('share', id!))
                      .catch((error) => console.error('Error sharing the webpage:', error))
                  } else {
                    console.log('Web Share API not supported.')
                  }
                }}
              >
                <i
                  className='fa-solid fa-share fs-2'
                  style={{
                    color: `${font_color}`,
                  }}
                ></i>
              </button>
            </div>
            <div>
              <div>
                <button onClick={makeCall}>
                  <i
                    className='fa-solid fa-phone fs-2'
                    style={{
                      color: `${font_color}`,
                    }}
                  ></i>
                </button>
              </div>
              <div>
                <button onClick={mapopen}>
                  <i
                    className='fa-solid fa-location-dot fs-2'
                    style={{
                      color: `${font_color}`,
                    }}
                  ></i>
                </button>
              </div>
            </div>
          </div>

          {/* THE CARD BODY */}
          <div
            className='template03_card_body'
            style={{
              backgroundColor: `${background_color}`,
              color: `${font_color}`,
              fontFamily: `${font_style}`,
            }}
          >
            <div
              className='template03_card_body_bg'
              style={{
                backgroundImage: `url(${background_image ? coverIMG__url : default_cover_img})`,
              }}
            ></div>

            <div className='template03_card_profile_info_container'>
              <div
                className='template03_card_profile_info'
                style={{
                  backgroundColor: `${background_color}`,
                }}
              >
                <div
                  className='template03_card_profile_pic'
                  style={{
                    backgroundImage: `url(${
                      profile_image ? profile_image_url : default_profile_image
                    })`,
                  }}
                ></div>
                <div className='template03_card_profile_information'>
                  <div className='template03_card_profile_fullname'>
                    {first_name ? first_name : ' '} <br />
                    {last_name ? last_name : ' '}
                  </div>
                  <div className='template03_card_profile_companyinfo'>
                    <div
                      className='template03_card_profile_designation'
                      style={{
                        color: `${font_color}`,
                        filter: 'saturate(80%)',
                      }}
                    >
                      {company_name ? company_name : ' '}
                    </div>
                    <div className='template03_card_profile_companyname'>
                      {designation ? designation : ' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {about ? (
              <div
                className='template03_card_probile_about'
                style={{
                  color: `${font_color}`,
                  filter: 'saturate(80%)',
                }}
              >
                {about}
              </div>
            ) : (
              ''
            )}

            {verifyAddress() ? (
              <div
                className='template03_card_profile_location_container'
                style={{
                  marginBottom:
                    product!.length === 0 || galleries!.length === 0 || payment!.length === 0
                      ? '5em'
                      : '20px',
                  color: `${font_color}`,
                  filter: 'saturate(80%)',
                }}
              >
                <div className='template03_card_profile_location'>
                  <div
                    style={{
                      color: `${font_color}`,
                      filter: 'saturate(80%)',
                    }}
                  >
                    <div ref={locationDiv}>
                      <i
                        className='fa-solid fa-location-dot fs-2'
                        style={{
                          color: `${font_color}`,
                          filter: 'saturate(80%)',
                        }}
                      ></i>
                    </div>
                    {address![0].address_line1 ? (
                      <div
                        style={{
                          color: `${font_color}`,
                        }}
                      >
                        {address![0].address_line1}
                      </div>
                    ) : (
                      ''
                    )}{' '}
                  </div>
                  {address![0].address_line2 ? (
                    <div
                      style={{
                        color: `${font_color}`,
                        filter: 'saturate(80%)',
                      }}
                    >
                      {address![0].address_line2}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : null}

            {/* Whatsapp Share section  */}

            <div className='card mb-8 p-2 border' style={{background: `${background_color}`}}>
              <div className='card-body rounded-3 p-4'>
                <h1 className='card-title text-center' style={{color: '#938e87'}}>
                  Connect On Whatsapp
                </h1>
                <div
                  className='row'
                  style={{padding: '10px', boxSizing: 'border-box', overflow: 'hidden'}}
                >
                  <div className='col-8'>
                    <input
                      value={whatsappNo}
                      onChange={(e) => setWhatsappNo(e.target.value)}
                      className='form-control'
                      placeholder='Enter number with country code'
                      style={{marginTop: '10px', background: '#938e87', color: '#fff'}}
                    />
                  </div>
                  <div className='col-4'>
                    <button
                      // style={{background: orderDetails?.template?.button_color}}
                      style={{background: 'none'}}
                      className={'btn  w-100 ' + (whatsapp_no!.length < 10 && ' disabled')}
                    >
                      <a
                        target='blank'
                        href={`https://wa.me/${whatsappNo}?text=${card_url}`}
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        <div
                          style={{
                            width: '70px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#25d366',
                            border: 'none',
                            padding: '20px 50px',
                            borderRadius: '12px',
                            marginTop: '0',
                            color: '#ffffff',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }}
                        >
                          {/* Text content */}
                          <span
                            style={{
                              marginRight: '5px',
                              fontFamily: 'Arial, sans-serif',
                              fontWeight: 'lighter',
                            }}
                          >
                            Share
                          </span>

                          {/* Image */}
                          <img
                            src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/2044px-WhatsApp.svg.png'
                            alt='WhatsApp Icon'
                            style={{width: '20px', height: '20px'}}
                          />
                        </div>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Bottom Section  */}

            <div
              className='card_features_container'
              style={{
                background: `${background_color}`,
              }}
            >
              {product!.length > 0 || galleries!.length > 0 || payment!.length > 0 ? (
                <div
                  className='card_features'
                  style={{
                    background: 'transparent',
                  }}
                >
                  <ul
                    className='card-features-nvigation nav nav-pills mb-3'
                    id='pills-tab'
                    role='tablist'
                  >
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link product-button active'
                        id='pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-home'
                        type='button'
                        role='tab'
                        aria-controls='pills-home'
                        aria-selected='true'
                      >
                        Products
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link gallery-button'
                        id='pills-profile-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-profile'
                        type='button'
                        role='tab'
                        aria-controls='pills-profile'
                        aria-selected='false'
                      >
                        Gallery
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link payments-button'
                        id='pills-contact-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-contact'
                        type='button'
                        role='tab'
                        aria-controls='pills-contact'
                        aria-selected='false'
                      >
                        Payments
                      </button>
                    </li>
                  </ul>

                  <div className='tab-content' id='pills-tabContent'>
                    {/* products tab */}

                    {product!.length > 0 ? (
                      <div
                        className='tab-pane fade show active'
                        id='pills-home'
                        role='tabpanel'
                        aria-labelledby='pills-home-tab'
                        style={{
                          padding: '10px',
                        }}
                      >
                        <div className='row'>
                          {product &&
                            product.map((product_object, index) => (
                              <div key={index} className='col-6 mb-3'>
                                <div className='image-gallery-container'>
                                  <div
                                    style={{
                                      backgroundImage: `url('https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/category_pic/${product_object.category_pic}')`,
                                    }}
                                  ></div>
                                  <div>
                                    <h4 style={{color: 'black'}}>
                                      {product_object.category_name
                                        ? product_object.category_name
                                        : ''}
                                    </h4>
                                    <p>
                                      {product_object.select_format &&
                                      product_object.select_format !== 'undefined'
                                        ? product_object.select_format
                                        : ''}
                                    </p>
                                    <div>
                                      {product_object.price && product_object.price !== undefined
                                        ? `Price: ${product_object.price}`
                                        : ''}
                                    </div>
                                  </div>

                                  <div>
                                    {product_object.visit_link ? (
                                      <div
                                        onClick={() => redirectToNewLink(product_object.visit_link)}
                                      >
                                        View
                                      </div>
                                    ) : (
                                      <div>View</div>
                                    )}

                                    {/* <div className='product_enquiry' onClick={enquiryBTN(product_object.category_name!,product_object.select_format!,product_object.category_pic!)}>Enquiry</div> */}
                                    <div
                                      className='product_enquiry'
                                      onClick={() =>
                                        enquiryBTN(
                                          product_object.category_name!,
                                          product_object.select_format!,
                                          product_object.category_pic!,
                                          product_object.id!
                                        )
                                      }
                                    >
                                      Enquiry
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : //   <div
                    //     className='tab-pane fade show active'
                    //     id='pills-home'
                    //     role='tabpanel'
                    //     aria-labelledby='pills-home-tab'
                    //     style={{

                    //       display: 'flex',
                    //       border:'2px solid balck',
                    //       justifyContent:'center',
                    //       alignItems:'center',
                    //       height: '40vh',
                    //       color: 'black'
                    //     }}
                    //   >
                    // No products to show

                    //   </div>
                    null}

                    {/* gallery tab  */}
                    {galleries!.length > 0 ? (
                      <div
                        className='tab-pane fade'
                        id='pills-profile'
                        role='tabpanel'
                        aria-labelledby='pills-profile-tab'
                      >
                        <div className='row'>
                          {galleries!.map((gallery_object, index) => (
                            <div
                              key={index}
                              data-index={index.toString()}
                              className='col-4 border gallery_container'
                              style={
                                gallery_object.image_file
                                  ? {
                                      backgroundImage: `url('https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/galleries/${gallery_object.image_file}')`,
                                    }
                                  : {background: '#f1f1f1', cursor: 'not-allowed'}
                              }
                              data-bs-toggle='modal'
                              data-bs-target='#galleryModal'
                              onClick={() => handleGalleryItemClick(index)}
                            ></div>
                          ))}
                        </div>
                      </div>
                    ) : null}

                    {/* payment tab  */}

                    {payment!.length > 0 ? (
                      <div
                        className='tab-pane fade'
                        id='pills-contact'
                        role='tabpanel'
                        aria-labelledby='pills-contact-tab'
                      >
                        <Payment paymentDetails={payment} />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* THE CARD FOOTER */}
          <div className='template03_card_footer'>
            <div>
              <div>
                <button
                  id='qr-scan-template03'
                  data-bs-toggle='modal'
                  data-bs-target='#staticBackdrop'
                ></button>
              </div>
              <div>
                <button id='mail-template03' onClick={makeemail}></button>
              </div>

              <div>
                
                <div>

                  <a href={vcard} download='test.vcf' onClick={downloadContact}></a>
                </div>
              </div>
              <div>
                <button id='whatsapp-template03' onClick={whatsapp_delivery}>
                  {' '}
                </button>
              </div>
              <div>
                <button
                  id='social-template03'
                  type='button'
                  className='btn  w-100 h-100' // Bootstrap class for 100% width
                  data-bs-toggle='modal'
                  data-bs-target='#staticBackdropSocial'
                  style={{
                    background: 'transparent',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                ></button>
              </div>
            </div>
          </div>

          {/* QR MODAL  */}
          <div
            className='modal fade'
            id='staticBackdrop'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
            aria-labelledby='staticBackdropLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='staticBackdropLabel'>
                    QR IMAGE
                  </h5>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  ></button>
                </div>
                <div className='modal-body'>
                  <img src={qr_image_URL} alt='QR Image' style={{width: '100%'}} />
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-primary download_QR_btn'
                    onClick={downloadQR}
                  >
                    Download QR
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Gallery Modal  */}
          <div
            className='modal fade'
            id='galleryModal'
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  ></button>
                </div>
                <div className='modal-body d-flex justify-content-center'>{modalContent}</div>
              </div>
            </div>
          </div>

          {/* Social Media */}
          <div
            className='modal fade'
            id='staticBackdropSocial'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
            aria-labelledby='staticBackdropLabelSocial'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='staticBackdropLabelSocial'>
                    Social Media
                  </h5>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  ></button>
                </div>
                <div className='modal-body'>
                  {socialMedia?.map((social) => SocialIcons(social))}
                </div>
                {/* <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-primary download_QR_btn'
                onClick={downloadQR}
              >
                Download QR
              </button>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Template03
