
export default function useApiHook() {
    const BASE_URL = process.env.REACT_APP_API_URL
    const token = localStorage.getItem('token')
    // get function
    const onGet = async (route: string) => {
        const headers = {
            method: "GET",

            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "access-control-allow-origin": "*",
                "Access-Control-Allow-Origin": `${BASE_URL}`,
                Authorization: "Bearer " + token,
            }
        };

        let status;
        let response;

        await fetch(BASE_URL + route, headers)
            .then(res => {
                status = res.status;
                return res.json();
            })
            .then((res) => {
                response = res.data;
            });

        const res = { "status": status, "data": response };
        return res
    }

    //!Function for POST -----
    const onPost = async (route: string, data: any) => {
        const header = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'access-control-allow-origin': '*',
                'Access-Control-Allow-Origin': `${BASE_URL}`,
                Authorization: 'Bearer ' + token,
            },
        }
        let status;
        let response;
        await fetch(BASE_URL + route, header)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then((res) => {
                response = res.data;
            });
        const res = { "status": status, "data": response };
        return res
    };


    const onPut = async (route: string, data: any) => {
        const header = {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "access-control-allow-origin": "*",
                "Access-Control-Allow-Origin": `${BASE_URL}`,
                Authorization: "Bearer " + token,
            },
        };
        let status;
        let response;
        await fetch(BASE_URL + route, header)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then((res) => {
                response = res.data;
            });
        const res = { "status": status, "data": response };
        return res
    };

    //!function for PUT
    const usePut = async (route: string, data: any) => {
        const header = {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "access-control-allow-origin": "*",
                "Access-Control-Allow-Origin": `${BASE_URL}`,
                Authorization: "Bearer " + token,
            },
        };
        let status;
        let response;
        await fetch(BASE_URL + route, header)
            .then((response) => {
                status = response.status;
                return response.json();
            })
            .then((res) => {
                response = res.data;
            });
        const res = { "status": status, "data": response };
        return res
    };

    const onPostWithFormdata = async (route: string, data: any) => {
        const header = {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                'access-control-allow-origin': '*',
                'Access-Control-Allow-Origin': `${BASE_URL}`,
                Authorization: 'Bearer ' + token,
            },
        }
        let status
        let response
        await fetch(BASE_URL + route, header)
            .then((response) => {
                status = response.status
                return response.json()
            })
            .then((res) => {
                response = res.data
            })
        const res = { status: status, data: response }
        return res
    }
    //! return statement
    return { onGet, onPost, usePut, onPut, onPostWithFormdata }
}