/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {
  SocialType,
  SocialTypeInfo,
} from '../../../../app/modules/widgets/components/admin/inerfaces/SocialMediaInterface'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import {SOCIAL_MEDIA_URL} from '../../../../app/modules/helpers/imageUrl'
import axios from 'axios'
import {APPURL} from '../../../../app/modules/helpers/imageUrl'
import MetaTagWrapper from '../../../../app/modules/helpers/MetaTagWrapper'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const socialMediaURL = SOCIAL_MEDIA_URL
const BASE_URL = APPURL

const axiosInstance = axios.create({
  // Axios instance configuration
  baseURL: BASE_URL,
  // Other options...
})
type Props = {
  className: string
  tabIndex: any
}

interface PermissionType {
  id?: number
  name?: string
}
interface NameInfo {
  name?: string
}

const PermisionList: React.FC<Props> = ({className, tabIndex}) => {
  const {onPost, onGet} = useApiHook()
  const [permissionList, setPermissionList] = useState<PermissionType[]>([])
  const [showModal, setShowModal] = useState(false)
  const [showModalAddIcon, setShowModalAddIcon] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [inputValue, setInputValue] = useState<SocialTypeInfo>({})
  const [statusCode, setStatusCode] = useState<any>(1)
  const [name, setName] = useState('')
  const [toBedeleted, settoBedeleted] = useState<number>()
  const getPermissionList = async () => {
    try {
      let response =  await onGet('/admin/show_permissions');
      const res: any = response.data;
       const permission_types: PermissionType[] = res;
       setPermissionList(permission_types)
      console.log(response.data)
    } catch (error) {}
  }
  useEffect(() => {
    getPermissionList()
  }, [])
  const handleDeleteMediaIcon = async () => {
    setShowModal(true)
  }
  const handleSubmit = async () => {
    try {
      let response = await onPost('/admin/create_permission', {name: name})
      console.log(response.data, 'data')
      if (response.status == 201) {
        toast.success('Permision created Successfully', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setShowModalAddIcon(false);
        getPermissionList()
      } else if (response.status == 422) {
        toast.error(`${name} has been already registered`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }
  const deletePermission = async ()=>{
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'access-control-allow-origin': '*',
        'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
      },
    }
    try {
      const response = await axiosInstance.delete(`/remove_permission/${toBedeleted}`, config);
      if(response.status==200){
        toast.success('Permission deleted successfully', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setShowModal(false)
        getPermissionList()
      }
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }
  return (
    <>
      <MetaTagWrapper title='Social Media || Tap Savvy' />
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title fw-bold text-muted'>Permission</h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary btn-sm btn-active-light-primary'
              onClick={() => setShowModalAddIcon(true)}
            >
              Add Permisions
            </button>
            {showModalAddIcon && (
              <div className='modal fade show' style={{display: 'block'}}>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                        Add Permision
                      </h5>
                    </div>
                    <form>
                      <div className='modal-body'>
                        <div className='pb-10 pb-lg-5'>
                          <label className='form-label fs-6'>Permision Name</label>
                          <input
                            type='text'
                            name='name'
                            className='form-control'
                            placeholder='Enter the Role Name'
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button
                          className='btn btn-danger'
                          onClick={() => setShowModalAddIcon(false)}
                        >
                          close
                        </button>
                        <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-2'>
          {/* begin::Item */}
          <div className='d-flex flex-column mb-3'>
            {/* begin::Description */}
            {permissionList &&
              permissionList?.map((item, index) => (
                <div
                  className='d-flex align-items-center justify-content-between mb-8'
                  key={item.id}
                >
                  <div className='d-flex align-items-center ms-5'>
                    <span className='text-muted fw-semibold d-block mx-5'>{item.name}</span>
                  </div>
                  <div className='d-flex align-items-center me-5'>
                    <button
                      className='badge badge-light-danger border-0 fs-8 fw-bold me-3'
                      onClick={() => {
                        setShowModal(true)
                        settoBedeleted(item.id)
                      }}
                    >
                      Delete
                    </button>
                    {showModal && (
                      <div className='modal fade show' style={{display: 'block'}}>
                        <div className='modal-dialog modal-dialog-centered'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                                Are you sure you want to delete this permission ?
                              </h5>
                            </div>
                            <div className='modal-body'>
                              <h2>You can add this permission  later if you want !</h2>
                            </div>
                            <div className='modal-footer'>
                              <button
                                className='btn btn-secondary'
                                onClick={() => setShowModal(false)}
                              >
                                close
                              </button>
                              <button
                                type='button'
                                className='btn text-white'
                                style={{backgroundColor: '#F24183'}}
                                onClick={() => {
                                  deletePermission()
                                }}
                              >
                                delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            {/* end::Description */}
          </div>
        </div>
        {/* end::Body */}
      </div>
      <ToastContainer />
    </>
  )

  // function openModal(item: any) {
  //   return (
  //     <div key={item} className='modal fade' id={`${item}`} tabIndex={tabIndex}>
  //       <div className='modal-dialog modal-dialog-centered'>
  //         <div className='modal-content'>
  //           <div className='modal-header'>
  //             <h1 className='modal-title text-gray-800 fw-bold fs-6' id='exampleModalLabel'>
  //               Are you sure you want to delete this social media icon ?
  //             </h1>
  //             <button
  //               type='button'
  //               className='btn-close'
  //               data-bs-dismiss='modal'
  //               aria-label='Close'
  //             ></button>
  //           </div>
  //           <div className='modal-body'>
  //             <h2>You can add this social media icon later if you want !</h2>
  //           </div>
  //           <div className='modal-footer'>
  //             <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
  //               Close
  //             </button>
  //             <button
  //               type='button'
  //               className='btn text-white'
  //               style={{ backgroundColor: '#F24183' }}
  //               onClick={() => {
  //                 handleDeleteMediaIcon(item)
  //               }}
  //             >
  //               Delete{' '}
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
}

export {PermisionList}
