import React, { FC } from 'react'
import { ListsWidget1 } from '../../../../../_metronic/partials/widgets'

const AllUserList:FC = () => {
    return (
        <div className='row g-5 g-xl-8'>
            <div className='col-xl'>
                <ListsWidget1 className='card-xl-stretch mb-5 mb-xl-8' />
            </div>
        </div>
    )
}

export default AllUserList