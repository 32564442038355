import React, {FC, useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {APPURL} from '../../../helpers/imageUrl'
import useApiHook from '../../../hook/useApiHook'
import DateFormateHelper from '../../../../../_metronic/helpers/DateFormateHelper'
import '../../../../../_metronic/assets/ticketReply.css'
export interface ImagePath {
  file?: File
  preview?: any
}
const BASE_URL = APPURL
const TicketReply: FC = () => {
  const {onGet, onPost} = useApiHook()
  const params = useParams()
  const [imageFile, setImageFile] = useState<ImagePath | null>()
  const [reply, setReply] = useState('')
  const [ticketDetails, setTicketDetails] = useState<any>([])
  const handleReply = async () => {
    try {
      let response = await onPost('/reply_ticket', {userId: localStorage.getItem('user_id')})
    } catch (error) {}
    console.log(reply, imageFile?.file)
  }
  const getTicketDetails = async () => {
    try {
      let response = await onGet(`/tickets/` + params.id)
      setTicketDetails(response.data)
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getTicketDetails()
  }, [])
  return (
    <div className='row g-5 g-xl-8'>
      <div className='col-xl'>
        {/* <PermisionList className='' tabIndex={tabIndex} /> */}
        <div className='card-xl-stretch mb-2 mb-xl-8'>
          <div>
            <h3 className='card-title text-center  fw-bold text-muted' style={{fontSize: '30px'}}>
              #{ticketDetails?.ticket_code} {ticketDetails?.subject}
            </h3>
          </div>
          <div className='row my-5  '>
            <div className='col-8 '>
              <div className='chatBox border me-4 mt-5 py-5 px-9  bg-light rounded'>
                <div className='chartHistory'>
                  {/* chat start */}
                  <h6 className='mb-10 mt-3 d-flex'>
                    <p className='d-flex justify-content-center align-items-center grayBorder'>
                      <div className='blueDot'></div>
                    </p>
                    <p className='ps-7'>
                      Ticket submitted on {DateFormateHelper(ticketDetails.created_at)}
                    </p>
                  </h6>
                  <div className='chat mb-20'>
                    <div className='d-flex'>
                      <p className='d-flex justify-content-center align-items-center profileBorder'>
                        <img
                          style={{height: '18px', width: '18px', borderRadius: '50%',  border:"1px solid gray", padding:"10px"}}
                          src="/media/logos/dummyUser.png"
                        />
                      </p>
                      <div className='avtar ps-7 d-flex'>
                        <img
                          src='/media/logos/dummyProfile.png'
                          className='profileAvtar'
                        />
                        <h4 className='ms-3 my-auto'>Think Again Lab</h4>
                      </div>
                    </div>

                    <p className='my-3 ms-9 p-6 border borderRadius'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam in ab
                      maxime neque, ut libero suscipit atque nulla. Animi maxime reprehenderit
                      ducimus sed vero assumenda at! Non, aspernatur? Ratione aut aliquam eos culpa!
                    </p>
                  </div>
                  <div className='chat mb-20'>
                    <div className='d-flex'>
                      <p className='d-flex justify-content-center align-items-center profileBorder'>
                        <img
                          style={{height: '25px', width: '25px', borderRadius: '50%'}}
                          src='https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI='
                        />
                      </p>
                      <div className='avtar ps-7 d-flex'>
                        <img
                          src='https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000'
                          style={{borderRadius: '50%', height: '50px', width: '50px'}}
                        />
                        <h4 className='ms-3 my-auto'>Think Again Lab</h4>
                      </div>
                    </div>

                    <p className='my-3 ms-9 p-6 border borderRadius'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam in ab
                      maxime neque, ut libero suscipit atque nulla. Animi maxime reprehenderit
                      ducimus sed vero assumenda at! Non, aspernatur? Ratione aut aliquam eos culpa!
                    </p>
                    <a
                      href='https://img.freepik.com/free-vector/abstract-website-banner-with-modern-shapes_1361-1738.jpg?w=2000'
                      target='blank'
                    >
                      <img
                        className='ms-15 fileImg'
                        src='https://img.freepik.com/free-vector/abstract-website-banner-with-modern-shapes_1361-1738.jpg?w=2000'
                      />
                    </a>
                  </div>

                  {/* chat end */}
                </div>
              </div>
            </div>
            <div className='col-4 '>
              <div className='replyBox border ms-4 mt-5 p-5  bg-light rounded'>
                <div>
                  <h2 className='card-title text-center fw-bold  border  py-4 rounded'>
                    <img
                      className='ticketIcon'
                      src='/media/logos/tickets.png'
                    />
                    Ticket Information
                  </h2>
                </div>
                <div className='my-5'>
                  <h6 className='fw-bold text-muted mb-0'>Subject</h6>
                  <p className='fw-bold'>
                    # {ticketDetails?.ticket_code} {ticketDetails?.subject}
                  </p>
                  <div className='d-flex justify-content-between'>
                  {ticketDetails?.status == 1 && (
                    <div className='badge badge-light-success py-3 px-10 border-0 fs-8 fw-bold '>
                      {' '}
                      Open
                    </div>
                  )}
                  {ticketDetails?.status == 2 && (
                    <div className='badge badge-light-warning py-3 px-10 border-0 fs-8 fw-bold '>
                      {' '}
                      Replied
                    </div>
                  )}
                  {ticketDetails?.status == 3 && (
                    <div className='badge badge-light-danger py-3 px-10 border-0 fs-8 fw-bold '>
                      {' '}
                      Closed
                    </div>
                  )}
                  <span className='btn btn-danger btn-sm py-2'>Mark As Resolve</span>
                  </div>
                  
                </div>
                <div>
                  <h6 className='fw-bold text-muted mb-0'>Description</h6>
                  <p className='fw-bold'>{ticketDetails?.description}</p>
                </div>
                <div className='d-flex justify-content-between'>
                  <div>
                    <h6 className='fw-bold text-muted mb-0'>Related Service</h6>
                    <p className='fw-bold'>{ticketDetails?.related_service}</p>
                  </div>
                  <div>
                    <h6 className='fw-bold text-muted mb-0'>Last Update</h6>
                    <p className='fw-bold'>{DateFormateHelper(ticketDetails.updated_at)}</p>
                  </div>
                </div>
                {ticketDetails?.closed_by && (
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h6 className='fw-bold text-muted mb-0'>Closed By</h6>
                      <p className='fw-bold'>{ticketDetails?.closed_by}</p>
                    </div>
                    <div>
                      <h6 className='fw-bold text-muted mb-0'>Closed At</h6>
                      <p className='fw-bold'>{DateFormateHelper(ticketDetails.closed_at)}</p>
                    </div>
                  </div>
                )}

                <div className='mt-3'>
                  <textarea
                    className='form-control'
                    onChange={(e) => setReply(e.target.value)}
                    placeholder='Write a message'
                    rows={4}
                  />
                  <input
                    type='file'
                    className='form-control my-3 fileInput'
                    onChange={(e) =>
                      setImageFile({
                        preview: URL.createObjectURL(e.target.files?.[0]!),
                        file: e.target.files?.[0],
                      })
                    }
                  />
                  <div style={{cursor: 'pointer'}} className='mt-3'>
                    <img
                      className='filePrev'
                      src={
                        imageFile?.preview
                          ? imageFile?.preview
                          : 'https://p.kindpng.com/picc/s/157-1571884_icon-cloud-upload-cloud-storage-saving-upload-circle.png'
                      }
                    />
                    <span className='fw-bold text-muted ms-3'>Upload Image</span>
                  </div>
                  <div className='d-flex justify-content-between mt-4'>
                    <button className='btn btn-primary btn-sm' onClick={handleReply}>
                      Reply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketReply
