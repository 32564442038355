import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import Customization from '../customization/customization'
// import facebook from '../../../../public/media/svg/social-logos/facebook.svg'

const CardDetails: FC = () => {
  
  return (
    // <div className='row row-cols-2 justify-content-around'>
    //   {' '}
    //   <div className='card p-0' style={{width: '28rem'}}>
    //     <img
    //       src='https://images.pexels.com/photos/1323206/pexels-photo-1323206.jpeg?cs=srgb&dl=pexels-mixu-1323206.jpg&fm=jpg'
    //       className='card-img-top'
    //       alt='...'
    //       height={180}
    //     />
    //     <img
    //       src='https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116__340.jpg'
    //       // className='profi'
    //       height={100}
    //       width={100}
    //       alt='...'
    //       style={{
    //         borderRadius: '50%',

    //         // backgroundImage: new URL('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvp5RZg2cdXiaEMaKf0yWp-4B7hQzfVJJJYKCmf_Dl&s') as any,
    //         // background-image: url('path/to/profile/photo.jpg');
    //         backgroundSize: 'cover',
    //         border: '3px solid white',
    //         backgroundPosition: 'center',
    //         marginTop: '-50px' /* To center the profile picture */,
    //         marginLeft: '35%',
    //         // marginLeft: '30%' /* To add some space between the profile and cover pictures */,
    //       }}
    //     />
    //     <div className='card-body text-center'>
    //       <h5 className='text-center fs-3'>Tap Savvy</h5>
    //       <h5 className='text-center fw-light text-info fs-6'>Premium Personal Card Brand</h5>
    //       <p className='card-text text-center'>
    //         Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nostrum esse quas magnam
    //         nesciunt harum mollitia veritatis nam molestias rem incidunt eius minima beatae laborum,
    //         animi eveniet illo quia iure voluptatibus!
    //       </p>

    //       <div className='row justify-content-center g-1 p-5' style={{width: '100%'}}>
    //         {icons('/media/svg/social-logos/facebook.svg')}
    //         {icons('/media/svg/social-logos/instagram.svg')}
    //         {icons('/media/svg/social-logos/linkedin.svg')}
    //         {icons('/media/svg/social-logos/twitter.svg')}
    //         {icons('/media/svg/social-logos/youtube.svg')}
    //       </div>

    //       <div className='row row-cols-1 m-5'>
    //         <div className='p-2 mb-6 rounded-pill shadow-sm' style={{}}>
    //           <div className=' row items-center'>
    //             {icons('/media/svg/brand-logos/google-icon.svg')}
    //             <p className=' mb-2 text-center text-muted'>panditsoumya35@gmail.com</p>
    //           </div>
    //         </div>
    //         <div className='p-2 mb-6 rounded-pill shadow-sm' style={{}}>
    //           <div className=' row items-center'>
    //             {icons('/media/svg/brand-logos/google-icon.svg')}
    //             <p className=' mb-2 text-center text-muted'>20th Dec, 2023</p>
    //           </div>
    //         </div>
    //         <div className='p-2 mb-6 rounded-pill shadow-sm' style={{}}>
    //           <div className=' row items-center'>
    //             {icons('/media/svg/brand-logos/smartphone.svg')}
    //             <p className=' mb-2 text-center text-muted'>7001120224</p>
    //           </div>
    //         </div>
    //         <div className='p-2 mb-6 rounded-pill shadow-sm' style={{}}>
    //           <div className=' row items-center'>
    //             {icons('/media/svg/brand-logos/google-icon.svg')}
    //             <p className=' mb-2 text-center text-muted'>Hooghly, West Bengal</p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* <CardDetails2 /> */}
    //   {/* <Customization  /> */}
    // </div>
    <>
      <Customization
        styleConfig={{
          backgroundColor: '#6c53a6',
          blurAmount: 0,
          fontColor: '#ffffff',
          buttonFontColor: '#ffffff',
          coverHeight: 180,
          profilePostion: 'center',
        }}
        cardconfig={{
          title: 'Tap Savvy',
          subtitle: 'Premium Personal card brand',
          about:
            'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit ex necessitatibus consectetur est voluptatum! Facilis tempore, vero, ipsum, dolores ut accusantium nulla non laudantium possimus omnis sit alias totam dolorum! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nostrum esse quas magnamnesciunt harum mollitia veritatis nam molestias rem incidunt eius minima beatae laborumanimi eveniet illo quia iure voluptatibus!',
          dob: '20th Dec, 1998',
          email: 'tapsavvy@email.com',
          phone: '7001120224',
          address: 'Kolkata, WB',
          profilePic: 'https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116__340.jpg',
          coverPic:
            'https://images.pexels.com/photos/1323206/pexels-photo-1323206.jpeg?cs=srgb&dl=pexels-mixu-1323206.jpg&fm=jpg',
        }}
      />
    </>
  )
}

export default CardDetails

export function icons(url: string) {
  return <img className='col ' src={toAbsoluteUrl(url)} alt='fb' height={20} width={20} />
}
