/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const userType: any = localStorage.getItem('user_type')

  return (
    <>
      {userType == 0 ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
            </div>
          </div>
          {/* <SidebarMenuItemWithSub
            to='/crafted/pages'
            title='Profile'
            fontIcon='bi-person'
            icon='profile-circle'
          > */}
          {/* <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}> */}
          {/* <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          /> */}
          {/* <SidebarMenuItem
              to='/pages/profile/cardpreview'
              title='Customise Card'
              hasBullet={true}
            /> */}
          <SidebarMenuItem to='/pages/wizards/horizontal' title='Create New' hasBullet={true} />
          {/* <SidebarMenuItem
            to='/crafted/pages/profile/gallerypreview'
            title='Gallery'
            hasBullet={true}
          /> */}
          {/* </SidebarMenuItemWithSub> */}

          {/* <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Cards' hasBullet={true}> */}
          {/* <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} /> */}
          {/* </SidebarMenuItemWithSub> */}
          {/* </SidebarMenuItemWithSub> */}
          {/* <SidebarMenuItemWithSub
            to='/crafted/accounts'
            title='Accounts'
            icon='element-plus'
            fontIcon='bi-person'
          > */}
          <SidebarMenuItem to='/crafted/widgets/lists' title='My Cards' hasBullet={true} />
          {/* <SidebarMenuItemWithSub
            to='/apps/chat'
            title='Support'
            fontIcon='bi-chat-left'
          > */}
            {/* <SidebarMenuItem to='/showTicket' title='Show My Tickets' hasBullet={true} />
            <SidebarMenuItem to='/createTicket' title='Create Ticket' hasBullet={true} /> */}
            {/* <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} /> */}
          {/* </SidebarMenuItemWithSub> */}
          {/* <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} /> */}

          {/* <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} /> */}
          {/* </SidebarMenuItemWithSub> */}
          {/* <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
          {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
          {/* <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
            </div>
          </div> */}
          {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      /> */}
          {/* <div className='menu-item'>
            <a
              target='_blank'
              className='menu-link'
              href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
            >
              <span className='menu-icon'>
                <KTIcon iconName='code' className='fs-2' />
              </span>
              <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
            </a>
          </div> */}
        </>
      ) : userType == 1 ? (
        <>
          {/* <SidebarMenuItem
            to='/crafted/widgets/allcards'
            icon='element-11'
            // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            title='All Cards'
            fontIcon='bi-app-indicator'
          /> */}
          <SidebarMenuItem to='/crafted/widgets/allcards?draft=false' title='All Cards' hasBullet={true} />
          <SidebarMenuItem to='socialmedia' title='Social Media' hasBullet={true} />
          <SidebarMenuItem to='all-user' title='Registered User' hasBullet={true} />
          <SidebarMenuItem to='/crafted/widgets/allcards?draft=true' title='Draft Card' hasBullet={true} />
          {/* <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} /> */}
          <SidebarMenuItem to='/pages/wizards/horizontal' title='Create New' hasBullet={true} />
          <SidebarMenuItem to='bulk-card-creation' title='Bulk Card Creation' hasBullet={true} />
          {/* <SidebarMenuItem to='roles' title='Admin Roles' hasBullet={true} /> */}
          {/* <SidebarMenuItem to='permisions' title='Permisions' hasBullet={true} /> */}

          {/* <SidebarMenuItemWithSub to='' title='Ticket Option' fontIcon='cheque' >
            <SidebarMenuItem to='showTicket' title='Show Ticket' hasBullet={true} />
            <SidebarMenuItem to='/createTicket' title='Create Ticket' hasBullet={true} />
          </SidebarMenuItemWithSub> */}
          {/* <SidebarMenuItem to='all-tickets-Manage' title='Ticket Manage' hasBullet={true} /> */}

          {/* <SidebarMenuItemWithSub
            to='/crafted/widgets'
            title='Manage User'
            fontIcon='bi-layers'
          >
            <SidebarMenuItem to='/crafted/widgets/create-sub-admin' title='Create Sub Admin' hasBullet={true} />
            <SidebarMenuItem to='/crafted/widgets/charts' title='Sub Admin Role List' hasBullet={true} />
            <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
            <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
            <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
          </SidebarMenuItemWithSub> */}

          <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
          {/* <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} /> */}
          {/* </SidebarMenuItemWithSub> */}
        </>
      ) : null}
    </>
  )
}

export { SidebarMenuMain }
