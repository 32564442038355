/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {ListAllCardInterface} from '../../../../app/modules/widgets/components/admin/inerfaces/ListviewInterface'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import {Link} from 'react-router-dom'
import {PROFILE_IMAGE_URL, COVER_IMAGE_URL} from '../../../../app/modules/helpers/imageUrl'
import MetaTagWrapper from '../../../../app/modules/helpers/MetaTagWrapper'
import DateFormateHelper from '../../../helpers/DateFormateHelper'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  tabIndex: any
}

const profileImageUrl = PROFILE_IMAGE_URL

const ListsWidget7: React.FC<Props> = ({className, tabIndex}) => {
  const {onGet} = useApiHook()
  const [allCards, setAllCards] = useState<ListAllCardInterface[]>([])
  const [openmodal, setopenModal] = useState(false)
  const [isloading, setisloading] = useState(false)

  const getAllCardsData = async () => {
    try {
      setisloading(true)
      const response = await onGet('/user_all_cards')

      // console.log(response);
      // console.log(response?.data?.['cards']);

      const cardData: any = response?.data?.['cards']
      const AllCardInfoData: ListAllCardInterface[] = cardData
      setAllCards(AllCardInfoData)
      setisloading(false)
    } catch (error) {
      console.log(error)
      setisloading(false)
    }
  }

  const handleDeleteCard = async (id: any) => {
    console.log(id)

    try {
      const response = await onGet(`/deactivate/${id}`)

      // console.log(response);
      if (response.status === 200) {
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllCardsData()
  }, [])

  return (
    <>
      <MetaTagWrapper title='All Cards || Tap Savvy' />
      <div className='card card-xl-stretch mb-xl-8'>
        {/* begin::Header */}
        <div className='card-header align-items-center border-0 mt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold text-dark'>My cards</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Articles and publications</span> */}
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-link btn-lg text-primary'
              onClick={() => getAllCardsData()}
            >
              <img
                src={toAbsoluteUrl('/media/svg/refresh-svgrepo-com (1).svg')}
                height={20}
                width={20}
                alt='refresh'
              />
            </button>
          </div>
        </div>

        {/* end::Header */}
        {/* begin::Body */}
        {isloading ? (
          <div className='d-flex justify-content-center align-items-center custom-spinner'>
            <div className='spinner-grow text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body pt-3'>
            {/* begin::Item */}
            {allCards?.map((item, index) => (
              <div className='d-flex align-items-sm-center mb-7' key={item.id}>
                {/* begin::Symbol */}
                <div className='symbol symbol-60px symbol-2by3 me-4'>
                  <div
                    className='symbol-label'
                    style={{
                      backgroundImage: item.order_details?.at(0)?.template?.profile_image
                        ? `url(${
                            profileImageUrl + item.order_details?.at(0)?.template?.profile_image
                          })`
                        : `url(${
                            COVER_IMAGE_URL + item.order_details?.at(0)?.template?.cover_image
                          })`,
                    }}
                  ></div>
                </div>
                {/* end::Symbol */}
                {/* begin::Title */}
                <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                  <div className='flex-grow-1 me-2'>
                    <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
                      {item.first_name}
                    </span>
                    <span className='text-muted fw-semibold d-block pt-1 fs-7'>
                      Card Code: {item.card_code}
                    </span>
                    <a
                      href={item.order_details?.at(0)?.card_url}
                      className='text-blue fw-semibold d-block pt-1 fs-7'
                    >
                      {item.order_details?.length! > 0 ? (
                        item.order_details?.at(0)?.card_url
                      ) : (
                        <p className='text-danger'>Draft</p>
                      )}
                    </a>
                  </div>

                  <div className='mx-10 text-muted fw-semibold d-block pt-1 fs-8'>
                    Created At:{' '}
                    <span className='pt-1 fs-9'>
                      {DateFormateHelper(item.order_details?.at(0)?.created_at)}
                    </span>
                  </div>

                  <Link
                    to={`/pages/wizards/editcard/${item.id}?iscompleted=${
                      item.order_details?.length! > 0 ? true : false
                    }`}
                  >
                    <button
                      type='button'
                      className='badge badge-light-primary border border-0 fs-8 fw-bold my-2 mx-2'
                    >
                      Edit
                    </button>
                  </Link>

                  {item.order_details?.length! > 0 && (
                    <Link
                      to={`/customise/card/${item.id}`}
                      // onClick={() => setShowCreateAppModal(true)}
                      className='badge badge-light-primary border border-0 fs-8 fw-bold my-2 mx-2 btn-primary'
                    >
                      Customize
                    </Link>
                  )}
                  {/* <button
                type='button'
                className='badge badge-light-success border border-0 fs-8 fw-bold my-2 me-2'
              >
                Approved
              </button> */}

                  {item.order_details?.length! > 0 && (
                   <Link to={`/user/dashboard/${item.id}`}>
                   <button
                     type='button'
                     className='badge badge-light-primary border border-0 fs-8 fw-bold my-2 mx-2'
                   >
                     Analytics
                   </button>
                 </Link>
                  )}
                  {/* <button
                type='button'
                className='badge badge-light-success border border-0 fs-8 fw-bold my-2 me-2'
              >
                Approved
              </button> */}

                  
                  {/* <button
                  type='button'
                  className='badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2'
                  data-bs-toggle='modal'
                  data-bs-target={`#${item.card_code}`}
                >
                  Deactivate
                </button> */}
                  {/* Modal start */}
                  {/* {openModal(item.card_code!)} */}
                  {/* Modal end */}
                </div>
                {/* end::Title */}
              </div>
            ))}
            {/* end::Item */}
            {/* begin::Item */}
          </div>
        )}
        {/* end::Body */}
      </div>
    </>
  )
}

export {ListsWidget7}
