/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, FC } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { register } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetaTagWrapper from '../../helpers/MetaTagWrapper'

const initialValues = {
  name: '',
  email: '',
  password: '',
  phone_number: '',
  changepassword: '',
  acceptTerms: false,
}

interface ChildProps {
  id?: any;
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 Characters')
    .max(50, 'Maximum 50 symbols')
    .matches(/[0-9]/, 'Include at least 1 number')
    .matches(/[A-Z]/, 'Include at least 1 capital letter')
    .matches(/[!@#$%^&*]/, 'Include at least 1 special character')
    .required('Password is required'),
  // phone_number: Yup.string()
  //   .length(10, 'Phone number must be exactly 10 characters')
  //   .required('Phone number is required'),
  changepassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export const Registration: FC<ChildProps> = ({ id }) => {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await register(
          id,
          values.name,
          values.email,
          values.password,
          values.changepassword,
          // values.phone_number
        )

        saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        // setCurrentUser(user)

        // session storage 
        // sessionStorage.setItem('token', auth.data.token);

        console.log(auth);
        // console.log(auth.data.message);
        setErrors('')

        if (auth.data.message === 'Register successful') {
          // console.log(auth);
          toast.success('Successfully Login', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate('/verify-otp');
        }

        // set token into localstorage 
        sessionStorage.setItem('token', auth.data.token);
        const emailss = sessionStorage.setItem('emailss', values.email);

        if (auth.data.message === 'Register successful') {
          toast.success('Successfully Login', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        else {
          toast.error('Invalid username or password', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

      } catch (error: any) {
        // console.error(error)
        // console.log(error.response.data?.data?.['errors'][0])
        setErrors(error.response.data?.data?.['errors'][0])
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)

        toast.error(`${error.response.data?.data?.['errors'][0]}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const handleToggleEye = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleToggleEyeConfirm = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  }

  return (
    <>
      <MetaTagWrapper title='Registration || Tap Savvy' />
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
          {/* end::Title */}

          {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
        </div>
        {/* end::Heading */}

        {/* begin::Login options */}
        {/* <div className='row g-3 mb-9'> */}
        {/* begin::Col */}
        {/* <div className='col-md-6'>
          
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
          
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6'>
        
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div> */}
        {/* end::Col */}
        {/* </div> */}
        {/* end::Login options */}

        {/* <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
        </div> */}

        {/* {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
            <p>Here are few things you can try</p>
            <ul>
              
              <li>Use a stronger password</li>

              <li>Use a Different Number</li>
              <li>Use a Different Email</li>
            </ul>
          </div>
        )} */}
        {errors ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{errors}</div>
          </div>
        ) : null}

        {/* begin::Form group name */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Name</label>
          <input
            placeholder='Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Form group phone number */}
        {/* <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Phone</label>
          <input
            placeholder='Phone'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('phone_number')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.phone_number && formik.errors.phone_number,
              },
              {
                'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
              }
            )}
          />
          {formik.touched.phone_number && formik.errors.phone_number && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phone_number}</span>
              </div>
            </div>
          )}
        </div> */}

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
            <div className='position-relative mb-3'>
              <input
                type={`${passwordVisible ? 'text' : 'password'}`}
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
              <i
                style={{
                  fontSize: '24px',
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '40px',
                  top: '10px',
                }}
                className={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`}
                onClick={handleToggleEye}
              ></i>
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            ></div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            <ul>
              <li>Use 8 or more characters</li>
              <li>Use 1 special character</li>
              <li>Capital letters</li>
              <li>Numbers</li>
            </ul>
          </div>
        </div>

        {/* begin::Form group Confirm Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
            <div className='position-relative mb-3'>
              <input
                type={`${confirmPasswordVisible ? 'text' : 'password'}`}
                placeholder='Confirm password'
                autoComplete='off'
                {...formik.getFieldProps('changepassword')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                  }
                )}
              />
              {formik.touched.changepassword && formik.errors.changepassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.changepassword}</span>
                  </div>
                </div>
              )}
              <i
                style={{
                  fontSize: '24px',
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '40px',
                  top: '10px',
                }}
                className={`bi ${confirmPasswordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`}
                onClick={handleToggleEyeConfirm}
              ></i>
            </div>
            {/* begin::Meter */}
            {/* <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div> */}
            {/* end::Meter */}
          </div>
          {/* <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div> */}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('acceptTerms')}
            />
            <span>
              I Accept the{' '}
              <a href='https://tap-savvy.com/faq/' target='_blank' className='ms-1 link-primary'>
                Terms
              </a>
              .
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Login
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
      <ToastContainer />
    </>
  )
}
