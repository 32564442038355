import React, { FC } from 'react'
import { TicketList } from '../../../../../_metronic/partials/widgets/lists/TicketList'

const ShowTicket:FC = () => {
    const tabIndex: any = '-1'
  return (
    <div className='row g-5 g-xl-8'>
      {/* <div className='col-xl'>
          <ListsWidget7 className='card-xl-stretch mb-xl-8' />
        </div> */}
      <div className='col-xl'>
        <TicketList className='card-xl-stretch mb-5 mb-xl-8' tabIndex={tabIndex} />
      </div>
    </div>
  )
}

export default ShowTicket