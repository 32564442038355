/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useAuth } from '../core/Auth'
import useApiHook from '../../hook/useApiHook'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { UserModel } from '../core/_models'
import MetaTagWrapper from '../../helpers/MetaTagWrapper'

const initialValues = {
  email: '',
  password: '',
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser, getShowProfileData } = useAuth()
  const navigate = useNavigate()
  const [errors, setErrors] = useState<any>('')
  const [logErrors, setLogErrors] = useState<any>(null)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const { onPost } = useApiHook()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      localStorage.setItem('email', values.email)
      // try {
      // const {data: auth} = await login(values.email, values.password)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // setCurrentUser(user)

      const response = await onPost('/user/login', {
        email: values.email,
        password: values.password,
      })

      // console.log(response?.data?.['errors'][0])
      setErrors(response?.data?.['errors'][0])

      console.log(response)
      // console.log(response.data?.['user']['user_type']);
      // console.log(response.data?.['user']['name']);

      // setting token to localstorage
      // let userName: any = response.data?.['user']['name']
      // let userType: any = response.data?.['user']['user_type']
      // let tokenItem: any = response.data?.['token']


      if (response.status === 200) {
        localStorage.setItem('token', response.data?.['token']!)
        localStorage.setItem('user_type', response.data?.['user']['user_type']!)
        localStorage.setItem('user_name', response.data?.['user']['name']!)
        const user: UserModel = {
          id: response.data?.['user']['id'],
          username: response.data?.['user']['name'],
          email: response.data?.['user']['email'],
          phone: response.data?.['user']['phone_number'],
        }

        toast.success('Successfully Login', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })

        if (response.data?.['user']['user_type'] == 0) {
          console.log("user",response.data?.['user']['user_type']);
          navigate('/crafted/widgets/lists')
        } else {
          // console.log("admin");
          navigate('/crafted/widgets/allcards?draft=false')
        }

        setCurrentUser(user)
        // getShowProfileData()

      } else if (response.status === 202) {
        console.log(response.data)
        sessionStorage.setItem('token', response.data?.['token']!)
        navigate('/verify-otp')
      } else {
        setLogErrors(response?.data?.['errors'][0])

        toast.error('Invalid username or password', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })

        setLoading(false)
      }

      // } catch (error : any) {
      //   console.log(error)
      //   if (error.response) {
      //     // Access the response JSON
      //     const responseData = error.response.json()
      //     // Handle the response data as needed
      //     console.log(responseData)
      //     // ...
      //   }
      //   saveAuth(undefined)
      //   setStatus('The login details are incorrect')
      //   setSubmitting(false)
      //   setLoading(false)
      // }
    },
  })

  // console.log(logErrors)

  const handleToggleEye = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <>
      <MetaTagWrapper title='Login || Tap Savvy' />
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
          {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
        </div>
        {/* begin::Heading */}

        {/* begin::Login options */}
        <div className='row g-3 mb-9'>
          {/* begin::Col */}
          {/* <div className='col-md-6'> */}
          {/* begin::Google link */}
          {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a> */}
          {/* end::Google link */}
          {/* </div> */}
          {/* end::Col */}

          {/* begin::Col */}
          {/* <div className='col-md-6'>
        
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        
        </div> */}
          {/* end::Col */}
        </div>
        {/* end::Login options */}

        {/* begin::Separator */}
        {/* <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
        </div> */}
        {/* end::Separator */}

        {/* { */}
        {/* {logErrors ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {formik.status}
        {logErrors}
          </div>
        </div>
      ) : (
        null
        )
      } */}

        {
          errors ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {errors}
              </div>
            </div>
          ) : null
        }

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <div className='password__show__wrapper'>
            <input
              type={`${passwordVisible ? 'text' : 'password'}`}
              autoComplete='off'
              placeholder='Password'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
            <i style={{
              fontSize: '24px',
              cursor: 'pointer',
              position: 'absolute',
              right: '40px',
              top: '10px'
            }}
              className={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`}
              onClick={handleToggleEye}></i>
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          {/* begin::Link */}
          <Link to='/forgot-password' className='link-primary'>
            Forgot Password ?
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          Not a Member yet?{' '}
          <Link to='/registration' className='link-primary'>
            Sign up
          </Link>
        </div>
      </form>
      <ToastContainer />
    </>
  )
}
