import React, {ChangeEvent, useEffect, useState} from 'react'
import styleConfig from '../cardDetails/interfaces/styleconfig'
import cardDetailsConfig from '../cardDetails/interfaces/cardDetailsConfig'
import {icons} from '../cardDetails/CardDetails'
import {ChromePicker} from 'react-color'
import Cookies from 'js-cookie'
import {useNavigate, useParams} from 'react-router-dom'
import useApiHook from '../../modules/hook/useApiHook'
import {CardInfo, Template} from '../cardDetails/interfaces/cardDetails'
import {SocialIcons} from '../cardDetails/CardDetails3'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DemoCard from '../cardDetails/cardDetails2'

import {
  COVER_IMAGE_URL,
  PROFILE_IMAGE_URL,
  APPURL,
  CARD_BG_IMAGE_URL,
} from '../../modules/helpers/imageUrl'
import axios from 'axios'
import MetaTagWrapper from '../../modules/helpers/MetaTagWrapper'
import CardDetails4 from '../cardDetails/cardDetails4'
import {log} from 'console'
import {abort} from 'process'
import CardDetails5 from '../cardDetails/CardDetails5'

const BASE_URL = APPURL
const coverImageUrl = COVER_IMAGE_URL
const profileImageUrl = PROFILE_IMAGE_URL
const backgroundImgUrl = CARD_BG_IMAGE_URL

// const BASE_URL = 'https://card.tap-savvy.com/'
const API_BASE_URL = process.env.REACT_APP_API_URL
const token = localStorage.getItem('token')

const headers = new Headers()
headers.append('Authorization', `Bearer ${token}`)

interface Props {
  styleConfig?: styleConfig
  cardconfig?: cardDetailsConfig
}

export interface ImagePath {
  file?: File
  preview?: any
}

const Customization = (_props: Props) => {
  const [styleConfig, setstyleConfig] = useState(_props.styleConfig)
  const [cardDetailsData, setCardDetailsData] = useState<CardInfo | null>({})
  const [template, setTemplate] = useState<Template>({})
  const [profileImage, setProfileImage] = useState<ImagePath | null>()
  const [coverImage, setCoverImage] = useState<ImagePath | null>()
  const [backGroundImage, setbackGroundImage] = useState<ImagePath | null>()
  const [saveTemplate, setSaveTemplate] = useState(false)
  const [newTemplateID, setNewTemplateID] = useState<Number | String | null>()
  const [themeID, setThemeID] = useState<Number | String | null>()
  const [firstName, setFirstName] = useState<String | null>()
  const [lastName, setLastName] = useState<String | null>()
  const [companyName, setCompanyName] = useState<String | null>()
  const [designation, setDesignation] = useState<String | null>()
  const [profileAbout, setProfileAbout] = useState<String | null>()
  const [templateprofileImage, setTemplateProfileImage] = useState<String | null>()
  const [templatecoverImage, setTemplateCoverImage] = useState<String | null>()

  // const styleConfig = _props.styleConfig
  // const cardConfig = _props.cardconfig
  const socialMedia = cardDetailsData?.social_links

  const navigate = useNavigate()
  const {onGet} = useApiHook()
  const id = localStorage.getItem('card_type')
  const {cid} = useParams()

  // console.log(cid);

  const [collapsableFields, setcollapsableFields] = useState({
    cardBackground: false,
    fontColor: false,
    buttonBackground: false,
    buttonFontColor: false,
  })

  function save() {
    Cookies.set('styleconfig', JSON.stringify(styleConfig))
    Cookies.set('cardconfig', JSON.stringify(cardDetailsData))

    navigate(`/preview/${cardDetailsData?.id}`)
  }

  const getCardDetails = async () => {
    try {
      const response = await onGet(`/card_details/${cid}`)
      const apiData: any = response?.data?.['card_details']
      let cardInfo: CardInfo = apiData
      setCardDetailsData(cardInfo)
    } catch (error) {
      console.log(error)
    }
  }

  const templateData = async () => {
    try {
      console.log(styleConfig)

      // const response = await onPut('/store/template', )
    } catch (error) {}
  }

  useEffect(() => {
    setTemplate(cardDetailsData?.order_details?.at(0)?.template!)
  }, [cardDetailsData])

  useEffect(() => {
    getCardDetails()
  }, [id])

  useEffect(() => {
    setThemeID(template?.theme_id)
    setTemplateProfileImage(template?.profile_image)
    setTemplateCoverImage(template?.cover_image)
  }, [template, cardDetailsData])

  useEffect(() => {
    setFirstName(cardDetailsData?.first_name)
    setLastName(cardDetailsData?.last_name)
    setCompanyName(cardDetailsData?.company_name)
    setDesignation(cardDetailsData?.designation)
    setProfileAbout(cardDetailsData?.about)
    setNewTemplateID(cardDetailsData?.order_details?.at(0)?.template?.id)
  }, [cardDetailsData, id])

  // const [formData, setFormData] = useState({
  //   backgroundImage: '',
  //   backgroundColor: '',
  //   profilePic: '',
  //   coverPic: '',
  //   fontColor: '',
  //   coverHeight: '',
  //   alignment: '',
  //   blurAmount: '',
  // })

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const {name, value} = event.target
  //   setFormData((prevFormData: any) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }))
  // }
  //!form submit fucntion
  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const formData = new FormData()
      formData.append('_method', 'PUT')
      // formData.append('card_id', cid!)
      formData.append('background_color', template?.background_color!)
      formData.append('cover_image_height', template?.cover_image_height!.toString())
      formData.append('profile_position', template?.profile_position!)
      formData.append('font_color', template?.font_color!)
      formData.append('font_style', template?.font_style!)
      formData.append('background_blur', template?.background_blur!)
      formData.append('button_color', template?.button_color!)
      formData.append('button_background', template?.button_background!.toString())
      formData.append('button_text_color', template?.button_text_color!)

      if (profileImage) {
        formData.append('profile_image', profileImage.file!)
      }
      //  else if (template.profile_image) {
      // const response = await axios.get(
      //   'https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_1280.jpg',
      //   {
      //     headers: {
      //       responseType: 'blob',
      //       'access-control-allow-origin': '*',
      //       'Access-Control-Allow-Origin': '*',
      //     }, // Set the response type to 'blob' to receive the image as a binary blob
      //   }
      // )

      // try {
      //   const response = await fetch(profileImageUrl + template.profile_image)
      //   const blob = await response.blob()
      //   const src = URL.createObjectURL(blob)
      //   formData.append('profile_image', blob)
      // } catch (error) {
      //   console.error('Error fetching image:', error)
      // }
      // const file = new File([response.data], 'image.jpg', {type: 'image/jpeg'})
      // }

      if (coverImage) {
        formData.append('cover_image', coverImage.file!)
      }
      if (backGroundImage) {
        formData.append('background_image', backGroundImage.file!)
      }

      const token = localStorage.getItem('token')

      // axios instance
      const axiosInstance = axios.create({
        // Axios instance configuration
        baseURL: BASE_URL,
        // Other options...
      })

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
        },
      }

      const response = await axiosInstance.post('/update/template/' + template.id, formData, config)

      console.log(response.data)

      if (response.status === 200) {
        setSaveTemplate(true)
        getCardDetails()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFontChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    setTemplate({
      ...template!,
      font_style: event.target.value,
    })
  }

  function handleAlignment(event: ChangeEvent<HTMLSelectElement>): void {
    setTemplate({
      ...template!,
      profile_position: event.target.value,
    })
  }

  const handleIncrement = () => {
    setTemplate((prev) => ({
      ...template,
      cover_image_height: Number(prev?.cover_image_height) + 10,
    }))
  }

  const handleDecrement = () => {
    setTemplate((prev) => ({
      ...template,
      cover_image_height: Number(prev?.cover_image_height) - 10,
    }))
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      // setTemplate({
      //   ...template!,
      //   background_image: URL.createObjectURL(file),
      // })
      setbackGroundImage({
        file: file,
        preview: URL.createObjectURL(file),
      })
    }
  }

  const handleDeleteProfile = async (id: any) => {
    try {
      const token = localStorage.getItem('token')

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
        },
      }

      if (profileImage) {
        setProfileImage(null)
      } else {
        const response = await axios.delete(`${BASE_URL}/remove_profile_image/${id}`, config)
        // console.log(response)

        if (response.status === 200) {
          getCardDetails()
          setProfileImage(null)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteCoverImage = async (id: any) => {
    try {
      const token = localStorage.getItem('token')

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
        },
      }

      if (coverImage) {
        setCoverImage(null)
      } else {
        const response = await axios.delete(`${BASE_URL}/remove_cover_image/${id}`, config)
        // console.log(response)

        if (response.status === 200) {
          getCardDetails()
          setCoverImage(null)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteBackgroundImage = async (id: any) => {
    try {
      const token = localStorage.getItem('token')

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
        },
      }

      if (backGroundImage) {
        setbackGroundImage(null)
      } else {
        const response = await axios.delete(`${BASE_URL}/remove_background_image/${id}`, config)
        console.log(response)

        if (response.status === 200) {
          getCardDetails()
          setbackGroundImage(null)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  //Function to switch template theme

  const templateThemeChange = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonElement = event.target as HTMLElement
    const selectedThemeID = Number(buttonElement.getAttribute('data-selectThemeID'))
    console.log('Selected Theme ID:', selectedThemeID)
    try {
      const formData = new FormData()
      formData.append('theme_id', `${selectedThemeID}`)
      formData.append('_method', 'PUT')
      const response = await fetch(`${API_BASE_URL}/update/template/${newTemplateID}`, {
        method: 'POST',
        body: formData,
        headers: headers,
      })

      if (response.status === 200) {
        await toast.success('Template Switch succesful', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } else {
        toast.error(`Some issue occured, couldn't update template`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (err) {
      console.log('Some Erro has occuresd')
    }
  }
  // console.log(template?.profile_image!)
  // console.log(template)

  return (
    <>
      <ToastContainer />
      <MetaTagWrapper title='Customize Card || Tap Savvy' />
      <div className='row sm-row-cols-1 justify-content-between'>
        {/* Display cards based on their theme id */}

        {themeID === 3 ? (
          <div
            className='card p-0 col-4'
            style={{
              width: '28rem',
              zIndex: 0,
            }}
          >
            <CardDetails5
              styleConfig={{
                coverHeight: 160,
                profileImage: profileImage?.preview,
                coverImage: coverImage?.preview,
                backgroundColor: template?.background_color,
                fontColor: template?.font_color,
                fontFamily: template?.font_style,
              }}
              firstName={firstName ? firstName : ' '}
              lastName={lastName ? lastName : ' '}
              OCompanyName={companyName ? companyName : ' '}
              ODesignation={designation ? designation : ' '}
              OAbout={profileAbout ? profileAbout : ' '}
              OCoverPic={templatecoverImage ? templatecoverImage : ''}
              OProfilePic={templateprofileImage ? templateprofileImage : ''}
            />
          </div>
        ) : themeID === 2 ? (
          <div
            className='card p-0 col-4'
            style={{
              width: '28rem',
              zIndex: 0,
            }}
          >
            <CardDetails4
              styleConfig={{
                coverHeight: 160,
                profileImage: profileImage?.preview,
                coverImage: coverImage?.preview,
                backgroundColor: template?.background_color,
                fontColor: template?.font_color,
                fontFamily: template?.font_style,
                buttonFontColor: template?.button_text_color,
                buttonbackgroundColor: template?.button_color,
              }}
              firstName={firstName ? firstName : ' '}
              lastName={lastName ? lastName : ' '}
              OCompanyName={companyName ? companyName : ' '}
              ODesignation={designation ? designation : ' '}
              OAbout={profileAbout ? profileAbout : ' '}
              OCoverPic={templatecoverImage ? templatecoverImage : ''}
              OProfilePic={templateprofileImage ? templateprofileImage : ''}
            />
          </div>
        ) : (
          // <>This is template 1</>
          <div
            className='card p-0 col-4'
            style={{
              width: '28rem',
              // position: 'relative',
              zIndex: 0,
              backgroundColor: template?.background_color,
              color: template?.font_color,
              backgroundImage: `url(${
                backGroundImage
                  ? backGroundImage.preview
                  : backgroundImgUrl + template?.background_image!
              })`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              fontFamily: template?.font_style,
            }}
          >
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                zIndex: 1,
                backdropFilter: `blur(${template?.background_blur}px)`,
              }}
            >
              {template?.cover_image || coverImage ? (
                <i
                  className='bi bi-trash-fill p-2 cursor-pointer rounded-circle'
                  style={{
                    position: 'absolute',
                    color: '#fff',
                    padding: '10px',
                    marginTop: '10px',
                    top: `${template?.cover_image_height}px`,
                    backgroundColor: 'red',
                    right: '10px',
                    display: template?.background_image! || backGroundImage ? 'block' : 'none',
                  }}
                  onClick={() => handleDeleteBackgroundImage(template?.card_id!)}
                ></i>
              ) : (
                <i
                  className='bi bi-trash-fill p-2 cursor-pointer rounded-circle'
                  style={{
                    position: 'absolute',
                    color: '#fff',
                    padding: '10px',
                    top: '10px',
                    backgroundColor: 'red',
                    right: '10px',
                    display: template?.background_image! || backGroundImage ? 'block' : 'none',
                  }}
                  onClick={() => handleDeleteBackgroundImage(template?.card_id!)}
                ></i>
              )}

              <div
                // src='https://images.pexels.com/photos/1323206/pexels-photo-1323206.jpeg?cs=srgb&dl=pexels-mixu-1323206.jpg&fm=jpg'
                className='card-img-top'
                style={{
                  height:
                    template?.cover_image !== null || coverImage
                      ? template?.cover_image_height + 'px'
                      : 0,
                  width: '100%',
                  backgroundImage: `url(${
                    coverImage
                      ? coverImage.preview
                      : coverImageUrl + cardDetailsData?.order_details?.at(0)?.template?.cover_image
                  })`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  position: 'relative',
                }}
              >
                {template?.cover_image || coverImage ? (
                  <i
                    className='bi bi-trash-fill p-2 cursor-pointer rounded-circle'
                    style={{
                      position: 'absolute',
                      color: '#fff',
                      padding: '10px',
                      backgroundColor: 'red',
                      top: '10px',
                      right: '10px',
                    }}
                    onClick={() => handleDeleteCoverImage(template?.card_id!)}
                  ></i>
                ) : (
                  ''
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent:
                    template?.profile_position === 'left'
                      ? 'left'
                      : template?.profile_position === 'right'
                      ? 'right'
                      : 'center',
                }}
              >
                {template?.profile_image || profileImage ? (
                  <>
                    <div
                      // src='https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116__340.jpg'
                      // className='profile__image'

                      style={{
                        position: 'relative',
                        borderRadius: '50%',
                        // backgroundImage: new URL('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvp5RZg2cdXiaEMaKf0yWp-4B7hQzfVJJJYKCmf_Dl&s') as any,
                        // background-image: url('path/to/profile/photo.jpg');
                        backgroundSize: 'cover',
                        backgroundImage: `url(${
                          profileImage
                            ? profileImage.preview
                            : profileImageUrl +
                              cardDetailsData?.order_details?.at(0)?.template?.profile_image
                        })`,
                        borderColor: 'white',
                        backgroundPosition: 'center',
                        border: '3px solid white',
                        marginTop:
                          template?.cover_image || coverImage
                            ? '-50px'
                            : '50px' /* To center the profile picture */,
                        height: 100,
                        width: 100,
                        marginRight: template?.profile_position === 'right' ? '20px' : '0',
                        marginLeft:
                          template?.profile_position === 'left'
                            ? '20px'
                            : '0' /* To add some space between the profile and cover pictures */,
                        // marginRight: styleConfig.profilePostion === 'right'? '20px': '0px',
                      }}
                    >
                      <i
                        className='bi bi-trash-fill p-2 cursor-pointer rounded-circle'
                        style={{
                          position: 'absolute',
                          right: '4px',
                          top: '75px',
                          color: '#fff',
                          padding: '10px',
                          backgroundColor: 'red',
                        }}
                        onClick={() => handleDeleteProfile(template?.card_id!)}
                      ></i>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                className={
                  template?.profile_position === 'center'
                    ? 'card-body text-center'
                    : template?.profile_position === 'right'
                    ? 'card-body text-end'
                    : 'card-body'
                }
              >
                <h3 className='fs' style={{color: template?.font_color}}>
                  {cardDetailsData?.company_name}
                </h3>
                {
                  <h5 className='' style={{color: template?.font_color, fontWeight: 'normal'}}>
                    {cardDetailsData?.first_name} {cardDetailsData?.middle_name}{' '}
                    {cardDetailsData?.last_name}
                  </h5>
                }
                <h5 className='' style={{color: template?.font_color, fontWeight: 'lighter'}}>
                  {cardDetailsData?.designation}
                </h5>
                <div
                // className={styleConfig.aboutcardBackground ? 'p-3 shadow rounded-3' : ' p-3'}
                // style={{
                //   background: styleConfig.
                //     ? styleConfig.aboutCardbackgroundColor
                //     : 'none',
                // }}
                >
                  <p className='card-text'>{cardDetailsData?.about}</p>
                </div>
                <div
                  className='row row-cols-5 justify-content-center g-1 p-5'
                  style={{width: '100%'}}
                >
                  {socialMedia?.map((social) => SocialIcons(social,cid))}
                </div>
                <div className='row row-cols-1'>
                  {cardDetailsData?.address?.length! > 0 && (
                    <div
                      className='p-2 mr-3 ml-3 mb-2  rounded-pill shadow'
                      style={{
                        backgroundColor:
                          template?.button_background == 0
                            ? template?.button_color
                            : template?.background_color,

                        color: template?.font_color,
                      }}
                    >
                      <div className='row items-center'>
                        {' '}
                        {/* <img
                    className=''
                    src={toAbsoluteUrl('/media/svg/location-sign-svgrepo-com.svg')}
                    alt='fb'
                    height={20}
                    width={20}
                  /> */}
                        <svg
                          height={20}
                          width={20}
                          fill='white'
                          version='1.1'
                          id='Capa_1'
                          xmlns='http://www.w3.org/2000/svg'
                          xmlnsXlink='http://www.w3.org/1999/xlink'
                          // width='800px'
                          // height='800px'
                          viewBox='0 0 395.71 395.71'
                          xmlSpace='preserve'
                        >
                          <g>
                            <path
                              d='M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
		c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
		C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
		c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z'
                            />
                          </g>
                        </svg>
                        <p className='' style={{fontSize: 10, color: template?.button_text_color}}>
                          {cardDetailsData?.address?.at(0)?.address_line1},
                          {cardDetailsData?.address?.at(0)?.city},
                          {cardDetailsData?.address?.at(0)?.state}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className='row row-cols-2'>
                  <div
                    className='p-2 mr-3 ml-3 mb-2  rounded-pill shadow'
                    style={{
                      backgroundColor:
                        template?.button_background == 0
                          ? template?.button_color
                          : template?.background_color,

                      color: template?.font_color,
                    }}
                  >
                    <div className=' row items-center'>
                      {icons('/media/svg/social-logos/phone-call.svg')}
                      <a
                        href={`tel:+91${cardDetailsData?.mobile_no}`}
                        className='font-semibold'
                        style={{color: template?.button_text_color}}
                      >
                        <p style={{fontSize: 10}} className=' mb-2 text-center '>
                          Call {cardDetailsData?.mobile_no}
                        </p>
                      </a>{' '}
                    </div>
                  </div>
                  <div
                    className='p-2 mr-3 ml-3 mb-2  rounded-pill shadow'
                    style={{
                      backgroundColor:
                        template?.button_background === 0
                          ? template?.button_color
                          : template?.background_color,
                      color: template?.font_color,
                    }}
                  >
                    <div className=' row items-center'>
                      {icons('/media/svg/social-logos/whatsapp.svg')}
                      <p style={{fontSize: 10}} className=' mb-2 text-center '>
                        <a
                          href={`https://api.whatsapp.com/send?phone=${cardDetailsData?.whats_app_no}&text=Hello%20there!`}
                          target='_blank'
                          rel='noreferrer'
                          style={{color: template?.button_text_color}}
                        >
                          {cardDetailsData?.whats_app_no}
                        </a>
                      </p>
                    </div>
                  </div>

                  {/* <div
                className='p-2 mr-3 ml-3 mb-2  rounded-pill shadow'
                style={{
                  backgroundColor: styleConfig?.buttonBackground
                    ? styleConfig?.buttonbackgroundColor
                    : 'none',

                  color: styleConfig?.fontColor,
                }}
              >
                <div className=' row items-center'>
                  {icons('/media/svg/brand-logos/google-icon.svg')}
                  <p style={{fontSize: 8}} className=' mb-2 text-center '>
                    {cardDetailsData?.address[0]!}
                  </p>
                </div>
              </div> */}
                </div>
                <div className='row'>
                  {/* add email here */}
                  <div
                    className='p-2 mr-3 ml-3 mb-2  rounded-pill shadow'
                    style={{
                      backgroundColor:
                        template?.button_background === 0
                          ? template?.button_color
                          : template?.background_color,
                      color: template?.font_color,
                    }}
                  >
                    <div className=' row items-center'>
                      {icons('/media/svg/social-logos/email.svg')}
                      <a
                        href={`mailto:${cardDetailsData?.email}`}
                        target='_blank'
                        className='font-semibold'
                        rel='noreferrer'
                        style={{color: template?.button_text_color}}
                      >
                        <p style={{fontSize: 10}} className=' mb-2 text-center '>
                          {cardDetailsData?.email}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='col'>
          <form onSubmit={handleSubmit}>
            <div className='pb-10 pb-lg-12'>
              {' '}
              <label htmlFor='profilepic' className='form-label fs-6 fw-bolder'>
                Profile Picture
              </label>
              <input
                type='file'
                name='profilepic'
                accept='.png,.jpg,.jpeg'
                id=''
                onChange={(e) =>
                  setProfileImage({
                    preview: URL.createObjectURL(e.target.files?.[0]!),

                    file: e.target.files?.[0],
                  })
                }
                className='form-control bg-transparent'
              />
            </div>
            <div className='pb-10 pb-lg-12'>
              {' '}
              <label htmlFor='coverpic' className='form-label fs-6 fw-bolder'>
                Cover Picture
              </label>
              <input
                type='file'
                name='coverpic'
                accept='.png,.jpg,.jpeg'
                id=''
                onChange={(e) =>
                  setCoverImage({
                    preview: URL.createObjectURL(e.target.files?.[0]!),
                    file: e.target.files?.[0],
                  })
                }
                className='form-control bg-transparent'
              />{' '}
            </div>
            <div className='pb-10 pb-lg-12'>
              {' '}
              {themeID === 1 ? (
                <>
                  <label htmlFor='background_image' className='form-label fs-6 fw-bolder'>
                    Background Image
                  </label>
                  <input
                    type='file'
                    name='background_image'
                    accept='.png,.jpg,.jpeg'
                    id=''
                    onChange={handleFileChange}
                    className='form-control bg-transparent'
                  />{' '}
                </>
              ) : null}
            </div>
            <div className='row justify-content-between'>
              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Card Background Color</label>{' '}
                <span className='p-2'>{template?.background_color}</span>{' '}
                <button
                  className=''
                  style={{
                    backgroundColor: template?.background_color,
                    width: '60px',
                    border: 'none',
                    borderRadius: '40px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setcollapsableFields((prevState) => ({
                      ...collapsableFields,

                      cardBackground: !prevState.cardBackground,
                    }))
                  }}
                >
                  <span>&#9662;</span>
                </button>
              </div>
              {collapsableFields.cardBackground ? (
                <div className='p-4'>
                  {' '}
                  <ChromePicker
                    color={template?.background_color}
                    onChange={(updatedColor: any) => {
                      setTemplate({
                        ...template!,
                        background_color: updatedColor.hex,
                      })
                      console.log(updatedColor)
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* //! Font Color Field */}
            <div className='row justify-content-between'>
              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Font Color</label>
                <span className='p-2'>{template?.font_color}</span>
                <button
                  className=''
                  style={{
                    backgroundColor: template?.font_color,
                    width: '60px',
                    border: 'none',
                    borderRadius: '40px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setcollapsableFields((prevState) => ({
                      ...collapsableFields,
                      fontColor: !prevState.fontColor,
                    }))
                  }}
                >
                  <span>&#9662;</span>
                </button>
              </div>
              {collapsableFields.fontColor ? (
                <div className='p-4'>
                  <ChromePicker
                    color={template?.font_color}
                    onChange={(updatedColor: any) => {
                      setTemplate({
                        ...template!,
                        font_color: updatedColor.hex,
                      })
                      console.log(updatedColor)
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {themeID !== 3 ? (
              <>
                {/* //!button Background Color */}
                <div className='row justify-content-between'>
                  <div className='fv-row mb-8'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      Button Background Color
                    </label>{' '}
                    <span className='p-2'>{template?.button_color}</span>
                    <button
                      className=''
                      style={{
                        backgroundColor: template?.button_color,
                        width: '60px',
                        border: 'none',
                        borderRadius: '40px',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setcollapsableFields((prevState) => ({
                          ...collapsableFields,
                          buttonBackground: !prevState.buttonBackground,
                        }))
                      }}
                    >
                      <span>&#9662;</span>
                    </button>
                    <div className='m-4'>
                      <input
                        className='form-check-input ml-4 mr-4 '
                        type='checkbox'
                        id='checkbox'
                        checked={template?.button_background == 0 ? true : false}
                        onChange={() => {
                          setTemplate((prevstate) => ({
                            ...template!,
                            button_background: prevstate?.button_background == 0 ? 1 : 0,
                          }))
                          console.log(template?.button_background)
                        }}
                      />
                      <label className='form-check-label ml-4 mr-4 ' htmlFor='checkbox'>
                        Different Background
                      </label>
                    </div>
                  </div>
                  {collapsableFields.buttonBackground ? (
                    <div className='p-4'>
                      <ChromePicker
                        color={template?.button_color}
                        onChange={(updatedColor: any) => {
                          setTemplate({
                            ...template!,
                            button_color: updatedColor.hex,
                          })
                          console.log(updatedColor)
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {/* Button Text Color */}
                <div className='row justify-content-between'>
                  <div className='fv-row mb-8'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Button Font Color</label>{' '}
                    <span className='p-2'>{template?.button_text_color}</span>
                    <button
                      className=''
                      style={{
                        backgroundColor: template?.button_text_color,
                        width: '60px',
                        border: 'none',
                        borderRadius: '40px',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setcollapsableFields((prevState) => ({
                          ...collapsableFields,
                          buttonFontColor: !prevState.buttonFontColor,
                        }))
                      }}
                    >
                      <span>&#9662;</span>
                    </button>
                  </div>
                  {collapsableFields.buttonFontColor ? (
                    <div className='p-4'>
                      <ChromePicker
                        color={
                          template?.button_text_color
                            ? template?.button_text_color
                            : template?.font_color
                        }
                        onChange={(updatedColor: any) => {
                          setTemplate({
                            ...template!,
                            button_text_color: updatedColor.hex,
                          })
                          console.log(updatedColor)
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {themeID === 1 ? (
                  <>
                    {/* //!Alignment field */}
                    <div className='row justify-content-between'>
                      <div className='fv-row mb-8 '>
                        <label
                          htmlFor='alignmentSelect'
                          className='form-label fs-6 fw-bolder text-dark'
                        >
                          Alignment
                        </label>
                        <select
                          className='p-1'
                          style={{
                            marginLeft: '20px',
                            width: '80px',
                            border: 'none',
                            borderRadius: '40px',
                          }}
                          onChange={handleAlignment}
                          id='alignmentSelect'
                        >
                          <option value='left'>Choose Alignment</option>
                          <option value='left'>Left</option>
                          <option value='center'>Center</option>
                          <option value='right'>Right</option>
                        </select>
                      </div>
                    </div>

                    {/* //!Cover Height */}
                    <div className='fv-row mb-8'>
                      <label
                        htmlFor='coverHeightField'
                        className='form-label fs-6 fw-bolder text-dark'
                      >
                        Cover Height
                      </label>

                      <button
                        type='button'
                        className='btn btn-secondary m-3'
                        style={{
                          backgroundColor: template?.button_color,
                          width: '60px',
                          border: 'none',
                          borderRadius: '40px',
                        }}
                        onClick={handleIncrement}
                      >
                        +
                      </button>
                      <button
                        type='button'
                        className='btn btn-secondary m-3'
                        style={{
                          backgroundColor: template?.button_color,
                          width: '60px',
                          border: 'none',
                          borderRadius: '40px',
                        }}
                        onClick={handleDecrement}
                      >
                        -
                      </button>
                    </div>

                    <div className='fv-row mb-8'>
                      <label htmlFor='rangeField' className='form-label fs-6 fw-bolder text-dark'>
                        Background Blur
                      </label>
                      <input
                        type='range'
                        className='form-control-range m-3'
                        id='rangeField'
                        min={0}
                        max={10}
                        value={template?.background_blur}
                        onChange={(e) => {
                          const value = parseInt(e.target.value, 10)
                          setTemplate({...template!, background_blur: value.toString()})
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </>
            ) : null}

            <div>
              <label htmlFor='font-select' className='form-label fs-6 fw-bolder text-dark'>
                Select Font Family:
              </label>
              <select
                id='font-select'
                onChange={handleFontChange}
                className='p-1'
                style={{
                  marginLeft: '20px',
                  // width: '80px',
                  border: 'none',
                  borderRadius: '40px',
                }}
              >
                <option value='Arial, sans-serif' style={{fontFamily: 'Arial, sans-serif'}}>
                  Arial
                </option>
                <option value='Helvetica, sans-serif' style={{fontFamily: 'Helvetica, sans-serif'}}>
                  Helvetica
                </option>
                <option
                  value='Times New Roman, serif'
                  style={{fontFamily: 'Times New Roman, serif'}}
                >
                  Times New Roman
                </option>
                <option
                  value='Courier New, monospace'
                  style={{fontFamily: 'Courier New, monospace'}}
                >
                  Courier New
                </option>
                <option
                  value="Roboto, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
                  style={{fontFamily: "Roboto, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"}}
                >
                  Roboto
                </option>
                <option
                  value="'Open Sans', 'Helvetica Neue', Arial, sans-serif"
                  style={{fontFamily: "'Open Sans', 'Helvetica Neue', Arial, sans-serif"}}
                >
                  Open Sans
                </option>
                <option
                  value="Lato, 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif"
                  style={{
                    fontFamily:
                      "Lato, 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                  }}
                >
                  Lato
                </option>
                <option
                  value="Montserrat, 'Helvetica Neue', Arial, sans-serif"
                  style={{fontFamily: "Montserrat, 'Helvetica Neue', Arial, sans-serif"}}
                >
                  Montserrat
                </option>
                <option value='Caprasimo, sans-serif' style={{fontFamily: 'Caprasimo, sans-serif'}}>
                  Caprasimo
                </option>
                <option
                  value='Belanosima, sans-serif'
                  style={{fontFamily: 'Belanosima, sans-serif'}}
                >
                  Belanosima
                </option>
                <option
                  value='Ysabeau Infant, sans-serif'
                  style={{fontFamily: 'Ysabeau Infant, sans-serif'}}
                >
                  Ysabeau Infant
                </option>
                <option value='Satisfy, sans-serif' style={{fontFamily: 'Satisfy, sans-serif'}}>
                  Satisfy
                </option>
                <option
                  value='Dancing Script, sans-serif'
                  style={{fontFamily: 'Dancing Script, sans-serif'}}
                >
                  Dancing Script
                </option>
                <option
                  value='Bebas Neue, sans-serif'
                  style={{fontFamily: 'Bebas Neue, sans-serif'}}
                >
                  Bebas Neue
                </option>
                <option
                  value='Martian Mono, sans-serif'
                  style={{fontFamily: 'Martian Mono, sans-serif'}}
                >
                  Martian Mono
                </option>
                <option
                  value='Lexend Peta, sans-serif'
                  style={{fontFamily: 'Lexend Peta, sans-serif'}}
                >
                  Lexend Peta
                </option>
              </select>
            </div>
            <div className='row mt-2 justify-between g-3'>
              {' '}
              <button type='submit' className='m-3 col btn btn-primary' onClick={templateData}>
                Save Template
              </button>
              <button onClick={save} className='m-3 col btn btn-primary'>
                Preview in Full Screen
              </button>
              <button
                type='button'
                className='m-3 col btn btn-primary w-100'
                data-bs-toggle='modal'
                data-bs-target='#switchTemplateModal'
              >
                Switch Theme
              </button>
            </div>
          </form>

          {saveTemplate && (
            <div className='modal fade show' style={{display: 'block'}}>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                  <div className='modal-body'>
                    <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                      You saved template successfully !
                    </h5>
                  </div>
                  <div className='modal-footer'>
                    <button className='btn btn-danger' onClick={() => setSaveTemplate(false)}>
                      close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal for switching temaplete  */}

      <div
        className='modal fade '
        id='switchTemplateModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        aria-labelledby='switchTemplateModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content' style={{overflow: 'scroll'}}>
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                Select a template from below
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='alert alert-warning fade show' role='alert'>
              Make sure to save the template before switching <br />
              Use 1:1 Apsect Ratio for Profile pic and 4:3 Aspect Ratio for background pic. Images
              will be center aligned
            </div>

            <div
              className='modal-body d-flex justify-content-between align-items-center gap-5 border'
              style={{
                overflowX: 'scroll',
              }}
            >
              {/* Switch to Template 01  */}
              <div>
                <DemoCard
                  styleConfig={{
                    backgroundColor: template?.background_color,
                    blurAmount: 0,
                    buttonBackground: true,
                    buttonbackgroundColor: '#d8d4d8',
                    coverHeight: 160,
                    fontColor: template?.font_color,
                    buttonFontColor: '#000000',
                    fontFamily: template?.font_style,
                    profilePostion: 'center',
                    profileImage: profileImage?.preview,
                    coverImage: coverImage?.preview,
                    themeID: '1',
                  }}
                  cardconfig={{
                    first_name: `${firstName ? firstName : 'Tap'}`,
                    last_name: `${lastName ? lastName : 'Savvy'}`,
                    card_code: 'CRD3233600',
                    card_type: 'Personal',
                    category: 'Metal',
                    company_name: `${companyName ? companyName : 'Tap Savvy'}`,
                    designation: 'Premium Personal Cards',
                    email: 'tapsavvy@gmail.com',
                    mobile_no: '+91 1234567890',
                    whats_app_no: '+91 1234567890',
                    about:
                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt vestibulum eros nec venenatis. Aenean semper commodo congue. Proin tempor auctor leo, sit amet tincidunt tortor rhoncus eu. Aliquam eu nibh nisl.',
                  }}
                />
                <button
                  type='button'
                  className='btn btn-primary mt-2'
                  data-selectThemeID='1'
                  onClick={templateThemeChange}
                >
                  Switch
                </button>
              </div>

              {/* //Switch to Template 02  */}
              <div>
                <CardDetails4
                  styleConfig={{
                    coverHeight: 160,
                    profileImage: profileImage?.preview,
                    coverImage: coverImage?.preview,
                    backgroundColor: template?.background_color,
                    fontColor: template?.font_color,
                    fontFamily: template?.font_style,
                  }}
                  firstName={firstName ? firstName : ' '}
                  lastName={lastName ? lastName : ' '}
                  OCompanyName={companyName ? companyName : ' '}
                  ODesignation={designation ? designation : ' '}
                  OAbout={
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt vestibulum eros nec venenatis. '
                  }
                  OCoverPic={templatecoverImage ? templatecoverImage : ''}
                  OProfilePic={templateprofileImage ? templateprofileImage : ''}
                />
                <button
                  type='button'
                  className='btn btn-primary mt-2'
                  data-selectThemeID='2'
                  onClick={templateThemeChange}
                >
                  Switch
                </button>
              </div>
              {/* Switch to Template 03  */}
              <div>
                <CardDetails5
                  styleConfig={{
                    coverHeight: 160,
                    profileImage: profileImage?.preview,
                    coverImage: coverImage?.preview,
                    backgroundColor: template?.background_color,
                    fontColor: template?.font_color,
                    fontFamily: template?.font_style,
                  }}
                  firstName={firstName ? firstName : ' '}
                  lastName={lastName ? lastName : ' '}
                  OCompanyName={companyName ? companyName : ' '}
                  ODesignation={designation ? designation : ' '}
                  OAbout={
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt vestibulum eros nec venenatis. Aenean semper commodo congue. Proin tempor auctor leo, sit amet tincidunt tortor rhoncus eu. Aliquam eu nibh nisl.'
                  }
                  OCoverPic={templatecoverImage ? templatecoverImage : ''}
                  OProfilePic={templateprofileImage ? templateprofileImage : ''}
                />
                <button
                  type='button'
                  className='btn btn-primary mt-2'
                  data-selectThemeID='3'
                  onClick={templateThemeChange}
                >
                  Switch
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Customization
