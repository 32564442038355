/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'

type Props = {
  className: string
  datavalues?: any[] | null
}

const TablesWidget5: React.FC<Props> = ({ className, datavalues }) => {
  const imgBASE_URL = 'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/category_pic/'
  const API_BASE_URL = process.env.REACT_APP_API_URL
  const token = localStorage.getItem('token')
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)

  const [productEnquiries, setProductEnquiries] = useState<{ [key: string]: number }>({});

  const fetchProductDetails = async (productID: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/product-enquiries/${productID}`, {
        method: 'GET',
        headers: headers,
      });
      const data = await response.json();
      return data.total_product_enquiries;
    } catch (error) {
      console.error("Error fetching product details:", error);
      return 0; // Return default value or handle error as needed
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const productEnquiriesData: { [key: string]: number } = {};
      for (const data of datavalues || []) {
        const enquiries = await fetchProductDetails(data.id);
        productEnquiriesData[data.id] = enquiries;
      }
      setProductEnquiries(productEnquiriesData);
    };
    fetchData();
  }, [datavalues]);

  return (
    <div className={`card ${className}`}>
      {datavalues && datavalues.length > 0 && (
        <>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>
                Products/Services Enquiry Counts{' '}
              </span>
              <span className='text-muted mt-1 fw-semibold fs-7'></span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='border-0'>
                    <th className='p-3 w-50px  text-center'>Image</th>
                    <th className='p-3 min-w-150px  text-left'>Name</th>
                    {/* <th className='p-3 min-w-150px text-light text-center'></th> */}
                    <th className='p-3 min-w-140px text-center'>Number of Enquiries</th>
                    {/* <th className='p-3 min-w-140px text-light text-start'>Number of Enquiries</th> */}
                    {/* <th className='p-0 min-w-110px'></th> */}
                    {/* <th className='p-0 min-w-50px'></th> */}
                  </tr>
                </thead>
                <tbody>
                  {datavalues.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={toAbsoluteUrl(`${imgBASE_URL}${data.category_pic}`)}
                              className='h-75 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {data.category_name}
                        </a>
                        <span className='text-muted fw-semibold d-block'>{data.select_format}</span>
                      </td>
                      <td className='text-center  fw-semibold'>
                        {productEnquiries[data.id] || 0}
                      </td>
                   
                      <td className='text-end'>
                        <a href='#' className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
                          <KTIcon iconName='arrow-right' className='fs-2' />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export { TablesWidget5 }
