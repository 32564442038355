import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useAuth } from '../core/Auth'
import { resetPassword } from '../core/_requests'
import { useNavigate } from 'react-router'
import MetaTagWrapper from '../../helpers/MetaTagWrapper'
import useApiHook from '../../hook/useApiHook'
import axios from 'axios'

const initialValues = {
    otp: '',
    password: '',
    password_confirmation: '',
}

const resetPasswordSchema = Yup.object().shape({
    otp: Yup.number()
        .required('OTP is required'),

    password: Yup.string()
        .min(8, 'Minimum 8 Characters')
        .max(50, 'Maximum 50 symbols')
        .matches(/[0-9]/, 'Include at least 1 number')
        .matches(/[A-Z]/, 'Include at least 1 capital letter')
        .matches(/[!@#$%^&*]/, 'Include at least 1 special character')
        .required('Password is required'),

    password_confirmation: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

const ResetPassword = () => {

    const [loading, setLoading] = useState(false);
    const { saveAuth, setCurrentUser } = useAuth()
    const navigate = useNavigate()
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [errors, setErrors] = useState<any>('')
    const { onPost, onPut } = useApiHook()

    const email = localStorage.getItem('email')
    const token = sessionStorage.getItem('token')

    console.log(email)

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);

            try {
                const { data: auth } = await resetPassword(
                    values.password,
                    values.password_confirmation,
                    values.otp
                )
                saveAuth(auth);

                // console.log(auth.data.message);
                setErrors('');


                if (auth.data.message === 'Success') {
                    navigate('/login')
                }
            }
            catch (error: any) {
                setErrors(error.response.data?.data?.['errors'][0])
                // console.log(error.response.data?.data?.['errors'][0])
            }
        }
    })

    const handleToggleEye = () => {
        setPasswordVisible(!passwordVisible)
    }
    const handleToggleEyeConfirm = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible)
    }

    // const handleResendOTP = async () => {
    //     try {

    //         const config = {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //                 Authorization: `Bearer ${token}`,
    //                 'access-control-allow-origin': '*',
    //                 'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
    //             },
    //         }

    //         const bodyParams = {
    //             email,
    //             _method: 'PUT'
    //         }

    //         const response = await axios.put('/resend_otp', bodyParams, config)

    //         console.log(response)
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }
    // }

    // console.log(formik.values);


    return (
        <>
            <MetaTagWrapper title='Reset Password || Tap Savvy' />
            <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
            >
                <div className='text-center mb-11'>
                    <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
                </div>

                {/* begin::Form group Email */}
                {/* <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                <input
                    placeholder='Email'
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div> */}
                {/* end::Form group */}

                {
                    errors ? (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>
                                {errors}
                            </div>
                        </div>
                    ) : null
                }

                {/* begin::Form group otp */}
                <div className='fv-row mb-8'>
                    <label className='form-label fs-6 fw-bolder text-dark'>OTP</label>
                    <input
                        placeholder='OTP'
                        {...formik.getFieldProps('otp')}
                        className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': formik.touched.otp && formik.errors.otp },
                            {
                                'is-valid': formik.touched.otp && !formik.errors.otp,
                            }
                        )}
                        type='text'
                        name='otp'
                        autoComplete='off'
                    />
                    {formik.touched.otp && formik.errors.otp && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert'>{formik.errors.otp}</span>
                        </div>
                    )}

                    <div className='resend__otp text-center'>
                        <p>or</p>
                        <span>Resend OTP</span>
                    </div>
                </div>

                {/* begin::Form group Password */}
                <div className='fv-row mb-8' data-kt-password-meter='true'>
                    <div className='mb-1'>
                        <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                        <div className='position-relative mb-3'>
                            <input
                                type={`${passwordVisible ? 'text' : 'password'}`}
                                placeholder='Password'
                                autoComplete='off'
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control bg-transparent',
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password,
                                    },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                )}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}

                            <i
                                style={{
                                    fontSize: '24px',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    right: '40px',
                                    top: '10px',
                                }}
                                className={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`}
                                onClick={handleToggleEye}
                            ></i>
                        </div>


                        {/* begin::Meter */}
                        {/* <div
                        className='d-flex align-items-center mb-3'
                        data-kt-password-meter-control='highlight'
                    >
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div> */}
                        {/* end::Meter */}
                    </div>
                    <div className='text-muted'>
                        Use 8 or more characters with a mix of letters, numbers & symbols.
                    </div>
                </div>

                {/* begin::Form group Confirm Password */}
                <div className='fv-row mb-8' data-kt-password-meter='true'>
                    <div className='mb-1'>
                        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                        <div className='position-relative mb-3'>
                            <input
                                type={`${confirmPasswordVisible ? 'text' : 'password'}`}
                                placeholder='Confirm password'
                                autoComplete='off'
                                {...formik.getFieldProps('password_confirmation')}
                                className={clsx(
                                    'form-control bg-transparent',
                                    {
                                        'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation,
                                    },
                                    {
                                        'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
                                    }
                                )}
                            />
                            {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password_confirmation}</span>
                                    </div>
                                </div>
                            )}
                            <i
                                style={{
                                    fontSize: '24px',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    right: '40px',
                                    top: '10px',
                                }}
                                className={`bi ${confirmPasswordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`}
                                onClick={handleToggleEyeConfirm}
                            ></i>
                        </div>
                    </div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button
                        type='submit'
                        id='kt_password_reset_submit'
                        className='btn btn-primary me-4'
                        disabled={!(formik.isValid && formik.dirty)}>
                        <span className='indicator-label'>Submit</span>
                        {loading && (
                            <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </form>
        </>
    )
}

export default ResetPassword;