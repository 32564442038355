import React, { FC } from 'react'
interface props {
  VideoLink?: string
}


const YouTubeVideo: FC<props> = ({ VideoLink }) => {

  const emdedVideo = (url: string) => {
    if (url.includes('https://youtu.be/')) {
      const specificChar = '/'
      const reversedStr = url.split('').reverse().join('')
      const videoid = reversedStr.slice(0, reversedStr.indexOf(specificChar))
      const videoLink = 'https://www.youtube.com/embed/' + videoid.split('').reverse().join('')
      // console.log(videoLink)

      return videoLink
    } else {
      const specificChar = '='
      const reversedStr = url.split('').reverse().join('')
      const videoid = reversedStr.slice(0, reversedStr.indexOf(specificChar))
      const videoLink = 'https://www.youtube.com/embed/' + videoid.split('').reverse().join('')
      // console.log(videoLink)

      return videoLink
    }
  }

  const videoUrl = emdedVideo(VideoLink!)

  return (
    <div>
      <iframe
        style={{ aspectRatio: 16 / 9 }}
        className='w-100'
        title='YouTube Video'
        // width='640'
        // height='280'
        src={videoUrl}
        frameBorder='0'
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default YouTubeVideo
