import React, { FC } from 'react'
import { icons } from './CardDetails'
import styleConfig from './interfaces/styleconfig'
import { CardInfo } from './interfaces/cardDetails'
// import facebook from '../../../../public/media/svg/social-logos/facebook.svg'
interface Props {
  styleConfig: styleConfig
  cardconfig: CardInfo
}

const DemoCard: FC<Props> = (_props: Props) => {
  const styleconfig = _props.styleConfig
  const cardDetailsData = _props.cardconfig
  const DummyCover = `url(https://images.pexels.com/photos/1323206/pexels-photo-1323206.jpeg?cs=srgb&dl=pexels-mixu-1323206.jpg&fm=jpg)`
  const DummyProfile = `url(https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116__340.jpg)`

  return (
    <div
      className='card p-0 col-3'
      style={{
        width: '26rem',
        height: '650px',
        // position: 'relative',
        zIndex: 0,
        backgroundColor:  styleconfig.backgroundColor,
        color: styleconfig.fontColor,
        backgroundImage: `url(${styleconfig.backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        fontFamily: styleconfig.fontFamily,
      }}
    >
      <div
        style={{
          // position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 1,
          backdropFilter: `blur(${styleconfig.blurAmount}px)`,
        }}
      >
        <div
          // src='https://images.pexels.com/photos/1323206/pexels-photo-1323206.jpeg?cs=srgb&dl=pexels-mixu-1323206.jpg&fm=jpg'
          className='card-img-top'
          style={{
            height: styleconfig.coverHeight ===0 ? 100: styleconfig.coverHeight,
            backgroundImage: styleconfig.coverImage ? `url(${styleconfig.coverImage})` : styleconfig.coverHeight === 0 ? 'none': DummyCover,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <div
          // src='https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116__340.jpg'
          // className='profi'

          style={{
            borderRadius: '50%',
            // backgroundImage: new URL('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvp5RZg2cdXiaEMaKf0yWp-4B7hQzfVJJJYKCmf_Dl&s') as any,
            // background-image: url('path/to/profile/photo.jpg');
            backgroundSize: 'cover',
            backgroundImage: styleconfig.profileImage
              ? `url(${styleconfig.profileImage})`
              : 
              DummyProfile,
            borderColor: 'white',
            backgroundPosition: 'center',
            border: '3px solid white',
            marginTop: '-50px' /* To center the profile picture */,
            height: 100,
            width: 100,
            marginLeft:
              styleconfig.profilePostion === 'center'
                ? '35%'
                : styleconfig.profilePostion === 'right'
                  ? '65%'
                  : '20px' /* To add some space between the profile and cover pictures */,
            // marginRight: styleconfig.profilePostion === 'right'? '20px': '0px',
          }}
        />
        <div
          className={
            styleconfig.profilePostion === 'center'
              ? 'card-body text-center'
              : styleconfig.profilePostion === 'right'
                ? 'card-body text-end'
                : 'card-body'
          }
        >
          <h5 className='fs' style={{ color: styleconfig.fontColor }}>
            {cardDetailsData?.first_name} {cardDetailsData?.last_name}
          </h5>
          <h5 className=' fw-light fs-6' style={{ color: styleconfig.fontColor }}>
            {cardDetailsData?.designation}
          </h5>
          <div
          // className={styleconfig.aboutcardBackground ? 'p-3 shadow rounded-3' : ' p-3'}
          // style={{
          //   background: styleconfig.
          //     ? styleconfig.aboutCardbackgroundColor
          //     : 'none',
          // }}
          >
            <p className='card-text'>{cardDetailsData?.about}</p>
          </div>
          <div className='row justify-content-center g-1 p-5' style={{ width: '100%' }}>
            {icons('/media/svg/social-logos/facebook.svg')}
            {icons('/media/svg/social-logos/instagram.svg')}
            {icons('/media/svg/social-logos/linkedin.svg')}
            {icons('/media/svg/social-logos/twitter.svg')}
            {icons('/media/svg/social-logos/youtube.svg')}
          </div>
          <div className='row row-cols-2'>
            <div
              className='p-2 mr-3 ml-3 mb-2  rounded-pill shadow'
              style={{
                backgroundColor: styleconfig.buttonBackground
                  ? styleconfig.buttonbackgroundColor
                  : styleconfig.backgroundColor,

                color: styleconfig.fontColor,
              }}
            >
              <div className=' row items-center'>
                {icons('/media/svg/social-logos/phone-call.svg')}
                <a
                  href={`tel:+91${cardDetailsData?.mobile_no}`}
                  style={{ color: styleconfig.fontColor }}
                  className='font-semibold'
                >
                  <p style={{ fontSize: 8 }} className=' mb-2 text-center '>
                    Call {cardDetailsData?.mobile_no}
                  </p>
                </a>{' '}
              </div>
            </div>
            <div
              className='p-2 mr-3 ml-3 mb-2  rounded-pill shadow'
              style={{
                backgroundColor: styleconfig.buttonBackground
                  ? styleconfig.buttonbackgroundColor
                  : 'none',

                color: styleconfig.fontColor,
              }}
            >
              <div className=' row items-center'>
                {icons('/media/svg/social-logos/whatsapp.svg')}
                <p style={{ fontSize: 8 }} className=' mb-2 text-center '>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${cardDetailsData?.whats_app_no}&text=Hello%20there!`}
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: styleconfig.fontColor }}
                  >
                    Send WhatsApp Message
                  </a>
                </p>
              </div>
            </div>

            {/* <div
                className='p-2 mr-3 ml-3 mb-2  rounded-pill shadow'
                style={{
                  backgroundColor: styleconfig.buttonBackground
                    ? styleconfig.buttonbackgroundColor
                    : 'none',

                  color: styleconfig.fontColor,
                }}
              >
                <div className=' row items-center'>
                  {icons('/media/svg/brand-logos/google-icon.svg')}
                  <p style={{fontSize: 8}} className=' mb-2 text-center '>
                    {cardDetailsData?.address}
                  </p>
                </div>
              </div> */}
          </div>
          <div className='row'>
            {/* add email here */}
            <div
              className='p-2 mr-3 ml-3 mb-2  rounded-pill shadow'
              style={{
                backgroundColor: styleconfig.buttonBackground
                  ? styleconfig.buttonbackgroundColor
                  : 'none',
                color: styleconfig.fontColor,
              }}
            >
              <div className=' row items-center'>
                {icons('/media/svg/social-logos/email.svg')}
                <a
                  href={`mailto:${cardDetailsData?.email}`}
                  target='_blank'
                  className='font-semibold'
                  rel='noreferrer'
                  style={{ color: styleconfig.fontColor }}
                >
                  <p style={{ fontSize: 8 }} className=' mb-2 text-center '>
                    Send an email at {cardDetailsData?.email}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DemoCard
