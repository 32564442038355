/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import CardView from '../pages/cardview/CardView'
import Maintenance from '../modules/auth/maintance/Maintenance'
import { Login } from '../modules/auth/components/Login'



/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  // console.log(PUBLIC_URL)
  
  const [token, setToken] = useState<string>(localStorage.getItem('token')!)
  const [userType, setUserType] = useState<any>(localStorage.getItem('user_type'))
  // console.log(currentUser);

  useEffect(() => {
    setToken(localStorage.getItem('token')!)
    setUserType(localStorage.getItem('user_type'))
  }, [currentUser])
  
  
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='/:username/:cid' element={<CardView />} />

          {
            // currentUser ||
            token || currentUser ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                {
                  userType == 0 ? (
                  <Route index element={<Navigate to='/crafted/widgets/lists' />} />) :
                  ( <Route index element={<Navigate to='/crafted/widgets/allcards' />} />)
                }
              </>
            ) : (
              <>
               
                <Route path='/*' element={<AuthPage/>} />
                <Route path='*' element={<Navigate to='/login' />} />
                <Route path="/crafted/widgets/lists" element={<Navigate to='/login' />} />
                <Route path="/crafted/widgets/allcards" element={<Navigate to='/login' />} />
              </>
            )
          }
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
