import React, { FC } from 'react'
import { ListsWidget2 } from '../../../../../_metronic/partials/widgets'

const AllTickets: FC = () => {
    return (
        <div className='row g-5 g-xl-8'>
            {/* <div className='col-xl'>
                <ListsWidget7 className='card-xl-stretch mb-xl-8' />
            </div> */}
            <div className='col-xl'>
                <ListsWidget2 className='card-xl-stretch mb-5 mb-xl-8' />
            </div>
        </div>
    )
}

export default AllTickets