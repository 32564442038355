/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import VerifyOtp from './components/VerifyOtp'
import ResetPassword from './components/ResetPassword'

const AuthLayout = () => {
  const navigate = useNavigate()
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, []);


  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        {/* <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/TS Logo.jpg')} className='h-45px' />
          <h1>TapSavvy</h1>
        </a> */}
        <div className='d-flex align-items-center mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/TS Logo.jpg')}
            className='h-40px rounded-full'
          />
          <h1 className='ms-3'>TapSavvy</h1>
        </div>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='https://tap-savvy.com/'  target='_blank' className='text-muted text-hover-primary px-2' rel="noreferrer">
            About
          </a>

        

          <a
            href='https://tap-savvy.com/contact-us/' target='_blank'
            className='text-muted text-hover-primary px-2' rel="noreferrer"
          >
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration id={0} />} /> # for normal user
      <Route path='admin' element={<Registration id={1} />} /> # for admin
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='verify-otp' element={<VerifyOtp />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
