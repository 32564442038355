import React, {FC} from 'react'
import {OrderDetail} from '../interfaces/cardDetails'
import QRCode from 'qrcode.react'
import {saveAs} from 'file-saver'
import {response} from 'express'

interface props {
  orderDetails?: OrderDetail
}
const Qrcode: FC<props> = ({orderDetails}) => {
  const style = orderDetails?.template
  const qrInage = orderDetails?.card_qr_code
  const cardurl = orderDetails?.card_url
  const qrbaseURL =
    'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/card_qr_code'

  const return_cardUrl = (url: string) => {
    if (url.startsWith('/')) {
      return 'https://card.tap-savvy.com' + url
    } else {
      return 'https://card.tap-savvy.com/' + url
    }
  }

  // const handleDownload = () => {
  //   if (Text) {
  //     const canvas = document.getElementById('qr-code') as HTMLCanvasElement
  //     canvas.toBlob((blob) => {
  //       if (blob) {
  //         saveAs(blob, 'qrcode.png')
  //       }
  //     })
  //   }
  // }

  const handleDownload = () => {
    const canvas = document.getElementById('qr-code') as HTMLCanvasElement

    if (orderDetails?.card_qr_code == null) {
      // If card_qr_code is null, download the QR code rendered as canvas
      canvas.toBlob(async (blob) => {
        if (blob) {
          saveAs(blob, 'qrcode.png')
        }
      })
    } else {
      // TODO: fix the download 
      // FIX: QR BUG 

      // If card_qr_code is not null, download the static image

      const qrCodeImageUrl = `${qrbaseURL}/${qrInage}`

      //Normal Save using file saver
      // if(qrCodeImageUrl)
      // {
      //   saveAs(qrCodeImageUrl,'tapsavvyqr.png')
      // }

      //Saving using anchor tag

      

      const anchor = document.createElement('a')
      anchor.style.display = 'none'
      anchor.href = qrCodeImageUrl
      anchor.rel = 'noopener' // Use 'noopener' for security
      anchor.download = qrCodeImageUrl.split('/').pop() || 'tap-savvyqr.png'

      // Remove 'target' attribute to prevent opening in a new tab
      // anchor.target = '_blank';

      document.body.appendChild(anchor)
      anchor.click()

      // document.body.removeChild(anchor)

      // setTimeout(() => {
      //   document.body.removeChild(anchor)
      // }, 100)

      // try {
      //   console.log("fecthing............")
      //   const response = await fetch(qrCodeImageUrl)
      //   console.log("fetching done.......")
      //   const blob = await response.blob()
      //   console.log("Blob conversion done............")
      //   saveAs(blob, 'qrcode.png')
      //   console.log("downloaded...............")
      // } catch (error) {
      //   console.error('Error fetching image:', error)
      // }

      // If card_qr_code is not null, download the static image
      //   const qrCodeImageUrl = `${qrbaseURL}/${qrInage}`

      //   // Create a new image element
      //   const img = new Image()
      //   img.crossOrigin = 'anonymous' // This enables CORS
      //   img.src = qrCodeImageUrl

      //   img.onload = function () {
      //     // Create a new canvas element
      //     const canvas = document.createElement('canvas')
      //     const ctx = canvas.getContext('2d')

      //     // Set the width and height of the canvas to match the image
      //     canvas.width = img.width
      //     canvas.height = img.height

      //     // Draw the image onto the canvas
      //     if (ctx) {
      //       ctx.drawImage(img, 0, 0)
      //     }
      //     // Convert the canvas to a blob
      //     canvas.toBlob((blob) => {
      //       // Download the blob
      //       if (blob) {
      //         saveAs(blob, 'tap-savvyqr.png')
      //       }
      //     })
      //   }

      //   img.onerror = function () {
      //     console.error('Error loading image')
      //   }
    }
  }

  return (
    <div className='card ' style={{fontFamily: style?.font_style}}>
      <div className='card-body rounded-3 p-4' style={{backgroundColor: style?.background_color}}>
        <h5 className='card-title text-center' style={{color: style?.font_color}}>
          QR Code
        </h5>
        <div className='qr-code d-flex justify-content-center align-items-center h-100 m-3'>
          {/* Render the QR code using an image tag */}
          <div className='' style={{padding: '5px', backgroundColor: 'white'}}>
            {orderDetails?.card_qr_code == null ? (
              <QRCode
                size={260}
                id='qr-code'
                value={return_cardUrl(orderDetails?.card_url)}
                renderAs='canvas'
              />
            ) : (
              <>
                <img src={`${qrbaseURL}/${qrInage}`} alt='QR Code' className='img-fluid' />
              </>
            )}
          </div>
        </div>

        <div className='row mx-auto text-center col-5'>
          <a href='#' onClick={handleDownload}>
            <button
              style={{
                fontSize: 12,
                color: style?.button_text_color,
                backgroundColor: style?.button_color,
              }}
              className=' mb-2 text-center btn'
            >
              Download
            </button>
          </a>
        </div>

        <p className='card-text text-center'>
          {/* Render the link */}
          <a
            href={return_cardUrl(orderDetails?.card_url)}
            style={{
              color: style?.font_color,
            }}
          >
            {return_cardUrl(orderDetails?.card_url)}
          </a>
        </p>
      </div>
    </div>
  )
}

export default Qrcode
