/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { ILayout, useLayout } from '../../core'

const Footer = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='text-dark order-2 order-md-1 d-flex justify-content-between align-items-center px-5 footer__order' style={{ width: '100%'}}>
        <div>
          <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
            <li className='menu-item'>
              <a href='https://tap-savvy.com/about-us/' target='_blank' className='menu-link px-2'>
                About
              </a>
            </li>

            {/* <li className='menu-item'>
          <a href='https://devs.keenthemes.com/' target='_blank' className='menu-link px-2'>
            Support
          </a>
        </li> */}

            <li className='menu-item'>
              <a href='https://tap-savvy.com/store/' target='_blank' className='menu-link px-2'>
                Store
              </a>
            </li>

            <li className='menu-item'>
              <a href='https://tap-savvy.com/contact-us/' target='_blank' className='menu-link px-2'>
                Contact
              </a>
            </li>

            <li className='menu-item'>
              <a href='https://tap-savvy.com/faq/' target='_blank' className='menu-link px-2'>
                FAQ
              </a>
            </li>
          </ul>
        </div>

        <div className='text-center'>
          <span className='text-muted fw-semibold me-1'>
            &copy;{new Date().getFullYear().toString()}
          </span>
          <span className='text-muted fw-semibold me-1'>
            All rights reserved.
          </span>
          <a
            href='https://tap-savvy.com/'
            target='_blank'
            className='text-gray-800 text-hover-primary'
          >
            • TAP SAVVY •
          </a>
          &nbsp;
          <span className='text-gray-800 fw-bold" me-1'>
            Powered by
          </span>
          <a href="https://thinkagainlab.com/"
            target='_blank'
            className='text-gray-800 text-hover-primary'
          >
            Think Again Lab
          </a>
        </div>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }
