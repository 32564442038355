import { useEffect, useState } from "react"
import useApiHook from '../../../../app/modules/hook/useApiHook'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import { ListAllCardInterface } from "../../../../app/modules/widgets/components/admin/inerfaces/ListviewInterface";

/* eslint-disable jsx-a11y/anchor-is-valid */
interface ChildProps {
  filterAllCards?: any;
  setFilterAllCards?: any;
  handleAllFilter?: any;
  allCards?: any;
}

// interface CategoriesData {

// }

export const Dropdown1: React.FC<ChildProps> = ({ filterAllCards, setFilterAllCards, handleAllFilter }) => {

  const { onGet } = useApiHook()
  const [email, setEmail] = useState('');
  const [category, setCategory] = useState('');
  const [categoryData, setCategoryData] = useState([]);
  const [card, setCard] = useState('');
  const [date, setDate] = useState('');

  const handleEmail = (e: any) => {
    setEmail(e.target.value)
  }

  const handleCategory = (e: any) => {
    setCategory(e.target.value)
  }

  const handleCard = (e: any) => {
    setCard(e.target.value)
  }

  const handleDate = (e: any) => {
    setDate(e.target.value)
  }

  // console.log(card);

  const handleFilterByEmail = async () => {
    try {

      if(email) {
        const response = await onGet(`/filtter_by_email/${email}`);
      
        console.log(response)
        console.log(response?.data?.['cards'])
        const cardApiEmail: any = response?.data?.['cards']
        const filterCardEmailData: ListAllCardInterface[] = cardApiEmail
        handleAllFilter(filterCardEmailData)
      }
      setCategory('')
      setCard('')
      setDate('')
    } 
    catch (error) {
      console.log(error)
    }
  }

  const handleFilterByCategory = async () => {
    try {
      if(category) {      
        const response = await onGet(`/filtter_card_by_category/${category}`);

        console.log(response)
        const cardApiCategory: any = response?.data?.['cards']
        const filterCardByCategory: ListAllCardInterface[] = cardApiCategory
        handleAllFilter(filterCardByCategory)
      }
      setEmail('')
      setCard('')
      setDate('')
    } 
    catch (error) {
      console.log(error)
    }
  }

  const handleFilterByCard = async () => {
    try {

      if(card) {
        const response = await onGet(`/filtter_card_by_card_code/${card}`);

        console.log(response)
        console.log(response?.data?.['cards'])
        const cardApiData: any = response?.data?.['cards']
        const filterCardData: ListAllCardInterface[] = cardApiData
        handleAllFilter(filterCardData)
      }
    } 
    catch (error) {
      console.log(error)
    }
  }

  const handleFilterByDate = async () => {
    try {

      if(date) {    
        const response = await onGet(`/filtter_card_by_creation_date/${date}`);

        console.log(response)
        const cardApiDate: any = response?.data?.['cards']
        const filterCardByDate: ListAllCardInterface[] = cardApiDate;
        handleAllFilter(filterCardByDate)
      }
    } 
    catch (error) {
      console.log(error)
    }
  }

  const allCategoryCardData = async () => {
    try {
      const response = await onGet('/all_category');

      console.log(response)
      const categoryApiData: any = response?.data?.['categories']
      setCategoryData(categoryApiData)
    } 
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // handleFilterByEmail()
    // handleFilterByCategory()
    // handleFilterByCard()
    // handleFilterByDate()
    // allCategoryCardData()
  }, [])


  // console.log(email + " " + category + " " + card + " " + date + " " + filterAllCards)
  // console.log(categoryData);
  // console.log(card);

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Status:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={'1'}
            >
              <option></option>
              <option value='1'>Approved</option>
              <option value='2'>Pending</option>
              <option value='3'>In Process</option>
              <option value='4'>Rejected</option>
            </select>
          </div>
        </div> */}

        <div className='mb-10'>
          <label className='form-label fw-bold'>Email:</label>
          <div className='d-flex align-items-center'>
            <input 
              className='form-control' 
              type='email' 
              placeholder="Email"
              value={email}
              onChange={handleEmail}
            />
            <div 
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              onClick={handleFilterByEmail}
            >
              <KTIcon iconName='magnifier' className='fs-1' />
            </div>
          </div>
        </div>

        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Category:</label>
          <div className='d-flex align-items-center'>
            <input 
              className='form-control' 
              type='text' 
              placeholder="category"
              value={category}
              onChange={handleCategory}
            />

            <select
              // name='card_type'
              id='cardtype'
              onChange={handleCategory}
              className='form-select bg-transparent'
            >
              <option>Choose Card Type</option>
              <option value='Personal'>Metal</option>
              <option value='PVC'>PVC</option>
              <option value='Wood'>Wood</option>
            </select>
            <div 
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              onClick={handleFilterByCategory}
            >
              <KTIcon iconName='magnifier' className='fs-1' />
            </div>
          </div>
        </div> */}

        <div className='mb-10'>
          <label className='form-label fw-bold'>Card Code:</label>
          <div className='d-flex align-items-center'>
            <input 
              className='form-control' 
              type='text' 
              placeholder="Card" 
              value={card}
              onChange={handleCard}
            />
            <div 
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              onClick={handleFilterByCard}
            >
              <KTIcon iconName='magnifier' className='fs-1' />
            </div>
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-label fw-bold'>Date:</label>
          <div className='d-flex align-items-center'>
            <input 
              className='form-control' 
              type='date' 
              value={date}
              onChange={handleDate}
            />
            <div 
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              onClick={handleFilterByDate}
            >
              <KTIcon iconName='magnifier' className='fs-1' />
            </div>
          </div>
        </div>

        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Notifications:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked={true}
            />
            <label className='form-check-label'>Enabled</label>
          </div>
        </div> */}

        {/* <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Reset
          </button>

          <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
            Apply
          </button>
        </div> */}
      </div>
    </div>
  )
}
