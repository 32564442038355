/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import useApiHook from '../../../../app/modules/hook/useApiHook'

type Props = {
  className: string
  image: string
  title: string
}

const initialValues = {
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
}

const registrationSubAdminSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

const CreateSubAdmin: React.FC<Props> = ({className, image, title}) => {
  const [loading, setLoading] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const {onPost} = useApiHook()
  const handleToggleEye = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleToggleEyeConfirm = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSubAdminSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response = await onPost("/admin/create_user", {...values, user_type: 2});
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    },
  })

  return (
    <div
      className={`card bgi-no-repeat ${className}`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + image)})`,
      }}
    >
      {/* begin::Body */}
      <div className='mx-auto mw-600px w-100 pt-15 pb-10'>
        <div className='card'>
          <div className='card-body'>
            <div className='w-100'>
              <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bold text-muted fs-2 text-center'>{title}</h2>
              </div>

              <form noValidate onSubmit={formik.handleSubmit}>
                <div className='pb-10 pb-lg-15'>
                  <label className='form-label fs-6'>
                    Name <span style={{color: '#EF305E'}}>*</span>
                  </label>
                  <input
                    type='text'
                    autoComplete='off'
                    required
                    placeholder='Enter Name'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.name && formik.errors.name,
                      },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='pb-10 pb-lg-15'>
                  <label className='form-label fs-6'>
                    Email <span style={{color: '#EF305E'}}>*</span>
                  </label>
                  <input
                    type='text'
                    autoComplete='off'
                    placeholder='Enter Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='pb-10 pb-lg-15' style={{position: 'relative'}}>
                  <label className='form-label fs-6'>
                    Password <span style={{color: '#EF305E'}}>*</span>
                  </label>
                  <input
                    type={`${passwordVisible ? 'text' : 'password'}`}
                    autoComplete='off'
                    placeholder='Enter Password'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                  <i
                    style={{
                      fontSize: '24px',
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '40px',
                      top: '38px',
                    }}
                    className={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`}
                    onClick={handleToggleEye}
                  ></i>
                </div>

                <div className='pb-10 pb-lg-15' style={{position: 'relative'}}>
                  <label className='form-label fs-6'>
                    Confirm Password <span style={{color: '#EF305E'}}>*</span>
                  </label>
                  <input
                    type={`${confirmPasswordVisible ? 'text' : 'password'}`}
                    autoComplete='off'
                    placeholder='Enter Email'
                    {...formik.getFieldProps('password_confirmation')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.password_confirmation && formik.errors.password_confirmation,
                      },
                      {
                        'is-valid':
                          formik.touched.password_confirmation && !formik.errors.password_confirmation,
                      }
                    )}
                  />
                  {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password_confirmation}</span>
                      </div>
                    </div>
                  )}
                  <i
                    style={{
                      fontSize: '24px',
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '40px',
                      top: '38px',
                    }}
                    className={`bi ${confirmPasswordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`}
                    onClick={handleToggleEyeConfirm}
                  ></i>
                </div>

                <div className='d-flex justify-content-end'>
                  <button type='submit' className='btn btn-lg btn-primary mb-5'>
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {CreateSubAdmin}
