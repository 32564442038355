import React from 'react'
import {Helmet} from 'react-helmet'

type MetaTagWrapperProps = {
  title?: string
  description?: string
  image?: string
  keywords?: string
  url?: string
  twitter?: string
  icon?: string
}

const MetaTagWrapper: React.FC<MetaTagWrapperProps> = ({
  title,
  description,
  image,
  keywords,
  url,
  twitter,
  icon,
}) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name='description' content={description} />}
      {keywords && <meta name='keywords' content={keywords} />}
      {title && <meta property='og:title' content={title} />}
      {description && <meta property='og:description' content={description} />}
      {image && <meta property='og:image' content={image} />}
      {url && <meta property='og:url' content={url} />}
      {twitter && <meta name='twitter:card' content={twitter} />}
      {icon && <link rel='icon' href={icon} />}
    </Helmet>
  )
}

export default MetaTagWrapper
