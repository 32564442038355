import React, {FC, useEffect, useState} from 'react'
import {BottomNav} from './BottomNav'
import CardDetails3 from '../cardDetails/CardDetails3'
import Gallery from '../cardDetails/Gallery/gallery'
import Qrcode from '../cardDetails/qrcode/Qrcode'
import Cookies from 'js-cookie'
import styleConfig from '../cardDetails/interfaces/styleconfig'
import cardDetailsConfig from '../cardDetails/interfaces/cardDetailsConfig'
import ProductServices from '../cardDetails/productservices/ProductServices'
import FeedbackForm from '../cardDetails/Feedback/Feedback'
import ContactForm from '../cardDetails/Enquiry/Enquiry'
import YouTubeVideo from '../cardDetails/youtube/VideoPlayer'
import {CardInfo, OrderDetail} from '../cardDetails/interfaces/cardDetails'
import useApiHook from '../../modules/hook/useApiHook'
import {Link, NavLink, useLocation, useParams} from 'react-router-dom'
import Payment from '../cardDetails/Payment/Payment'
import {PROFILE_IMAGE_URL} from '../../modules/helpers/imageUrl'
import {Helmet} from 'react-helmet'
import MetaTagWrapper from '../../modules/helpers/MetaTagWrapper'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Template02 from '../customization/savedTemplates/template02'
import Template03 from '../customization/savedTemplates/template03'
import CardDetails from '../cardDetails/CardDetails'
// const cookie1 = Cookies.get('styleconfig')
// const cookie2 = Cookies.get('cardconfig')

// const styleconfig: styleConfig = cookie1 ? JSON.parse(cookie1) : null
// const cardConfig: CardInfo = cookie2 ? JSON.parse(cookie2) : null

const CardView: FC = () => {




  const {cid} = useParams()
  const [cardDetailsData, setCardDetailsData] = useState<CardInfo>({})
  const [orderDetails, setOrderDetails] = useState<OrderDetail>({})
  const [otherDesignation, setOtherDesignation] = useState<any>()
  const [Isloaded, setIsloaded] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const {onGet, onPost} = useApiHook()
  const [whatsappNo, setWhatsappNo] = useState('')
  const [whatsAppLink, setWhatsAppLink] = useState('')
  // const [themeID,setThemeID] = useState('')

  const API_BASE_URL = process.env.REACT_APP_API_URL
  const QRImage_BASE_URL = "https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/card_qr_code/"
  const staticURL = "https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/card_qr_code/d3596a1b058a61061c51ea17ec836724.png";

  // const id = localStorage.getItem('card_type')
  const [id, setId] = useState<any>(null)
  let cardUrl = useLocation()
  useEffect(() => {
    setWhatsAppLink(
      `https://api.whatsapp.com/send?phone=${whatsappNo}&text=${`https://card.tap-savvy.com${cardUrl.pathname}`}`
    )
  }, [whatsappNo])
  const getCardDetails = async () => {
    try {
      const response = await onPost(`/card_details/${cid}`, {_id: cid})
      const other_designationsApi: any = response?.data?.['other_designations']
      let other_designationsData: any = other_designationsApi
      setOtherDesignation(JSON.parse(other_designationsData))
      const apiData: any = response?.data?.['card_details']
      const cardinfo: CardInfo = apiData
      const apiOrderDetails: any = response?.data?.['card_details']['order_details'][0]
      const orderDetailsInfo: OrderDetail = apiOrderDetails

      // console.log("Gallery:", response?.data?.['card_details']['galleries'])
      
      setCardDetailsData(cardinfo)
      setOrderDetails(orderDetailsInfo)

      setIsloaded(true)
    } catch (error: any) {
      setIsloaded(true)
      console.log(error)

    }
  }
  const storeUserIp = async () => {
    try {
      let response = await axios.post(process.env.REACT_APP_API_URL + '/store-ip')
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    storeUserIp()
  }, [])

  // console.log("Debug Statement:", cardDetailsData.id)
  localStorage.setItem("usercardID", `${cardDetailsData.id}`);

  // console.log(orderDetails)
  // console.log(cardDetailsData?.order_details?.at(0)?.template)

  // useEffect(() => {
  //   const shareButtonValues = {
  //     firstName: cardDetailsData.first_name ? cardDetailsData.first_name + ' ' : '',
  //     middleName: cardDetailsData.middle_name ? cardDetailsData.middle_name + ' ' : '',
  //     lastName: cardDetailsData.last_name ? cardDetailsData.last_name : '',
  //     companyName: cardDetailsData.company_name ? ' || ' + cardDetailsData.company_name : '',
  //   }

  //   document.title =
  //     shareButtonValues.firstName +
  //     shareButtonValues.middleName +
  //     shareButtonValues.lastName +
  //     shareButtonValues.companyName

  //     const head = document.head

  //     // Create or update the <meta> tags
  //     const descriptionMeta = document.createElement('meta')
  //     descriptionMeta.name = 'description'
  //     descriptionMeta.content = cardDetailsData.designation!

  //     const ogImageMeta = document.createElement('meta')
  //     ogImageMeta.setAttribute('property', 'og:image')
  //     ogImageMeta.content = PROFILE_IMAGE_URL + orderDetails.template?.profile_image

  //     const keywordsMeta = document.createElement('meta')
  //     keywordsMeta.name = 'keywords'
  //     keywordsMeta.content = `${cardDetailsData.designation},${cardDetailsData.first_name},${cardDetailsData.middle_name},${cardDetailsData.last_name},${cardDetailsData.company_name},${cardDetailsData.id}, tap-savvy, tapsavvy,Tapsavvycards, tap savvy cards`

  //     const ogUrlMeta = document.createElement('meta')
  //     ogUrlMeta.setAttribute('property', 'og:url')
  //     ogUrlMeta.content = window.location.href

  //     const twitterCardMeta = document.createElement('meta')
  //     twitterCardMeta.name = 'twitter:card'
  //     twitterCardMeta.content = cardDetailsData.about!

  //     // Remove existing <meta> tags, if any
  //     const existingMetaTags = head.querySelectorAll(
  //       'meta[name="description"], meta[property="og:image"], meta[name="keywords"], meta[property="og:url"], meta[name="twitter:card"]'
  //     )
  //     existingMetaTags.forEach((tag) => tag.remove())

  //     // Append the new <meta> tags to the head element
  //     head.appendChild(descriptionMeta)
  //     head.appendChild(ogImageMeta)
  //     head.appendChild(keywordsMeta)
  //     head.appendChild(ogUrlMeta)
  //     head.appendChild(twitterCardMeta)
  // }, [cardDetailsData])

  // console.log(cardDetailsData)
  // console.log(orderDetails)
  const style = orderDetails?.template
  const checkProtected = async () => {
    try {
      let response = await onGet('/is_protected/' + cid)
      if (response.status == 200) {
        getCardDetails()
      } else {
        setShowPassword(true)
        toast.warning('This card is protected')
      }
    } catch (error) {}
  }
  useEffect(() => {
    checkProtected()
  }, [])
  const [cardPassword, setCardPassword] = useState<any>('')

  const handleUnlockFunc = async () => {
    try {
      const response = await onPost(`/card_details/${cid}`, {password: cardPassword})
      if (response.status == 200) {
        const other_designationsApi: any = response?.data?.['other_designations']
        let other_designationsData: any = other_designationsApi
        setOtherDesignation(JSON.parse(other_designationsData))
        const apiData: any = response?.data?.['card_details']
        const cardinfo: CardInfo = apiData
        const apiOrderDetails: any = response?.data?.['card_details']['order_details'][0]
        const orderDetailsInfo: OrderDetail = apiOrderDetails


        setCardDetailsData(cardinfo)
        setOrderDetails(orderDetailsInfo)
        // console.log(cardDetailsData)
        // console.log(orderDetails)
        setIsloaded(true)
        setShowPassword(false)
      } else {
        toast.error('Something went wrong')
      }
    } catch (error: any) {
      setIsloaded(true)
      console.log(error)
    }
  }
  // if(true){

    // WARN : not any template 

    // return (
    //   <>
    //     {showPassword ? (
    //       <div className='vh-100 bg-dark d-flex justify-content-center align-items-center'>
    //         <div className='p-lg-0 p-5'>
    //           <h1 className='text-light text-center'>This card has been locked by the user </h1>
    //           <br />
    //           <div>
    //             <label className='text-light label-control'>
    //               Please enter password to view the card
    //             </label>
    //             <br />
    //             <input
    //               className='form-control'
    //               type='password'
    //               placeholder='Enter Password eg. 123456'
    //               value={cardPassword}
    //               onChange={(e) => setCardPassword(e.target.value)}
    //             />
    //             <button
    //               className={
    //                 'btn btn-sm btn-primary my-2 w-100' +
    //                 (cardPassword.length == 6 ? '' : ' disabled')
    //               }
    //               onClick={handleUnlockFunc}
    //             >
    //               Submit
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     ) : (
    //       <>
    //         {!Isloaded ? (
    //           <div className='loader'>
    //             {' '}
    //             <div className='lds-facebook'>
    //               <div></div>
    //               <div></div>
    //               <div></div>
    //             </div>
    //           </div>
    //         ) : Isloaded && !cardDetailsData.id ? (
    //           <>
    //             <div className='error-container'>
    //               {/* <img src="error-illustration.svg" alt="Error Illustration" className="illustration"/> */}
    //               <h2 className='display-4 text-center mb-4'>Sorry, this card doesn't exist</h2>
    //               <Link to='/pages/wizards/horizontal'>
    //                 <button className='btn btn-primary btn-lg'>Create Your Card</button>
    //               </Link>
    //             </div>
    //           </>
    //         ) : Isloaded && cardDetailsData.status === 0 ? (
    //           <div className='card m-5'>
    //             <h1 className='text-center p-5'>Your card is waiting for admin's approval!</h1>
    //             <div className='row justify-content-center g-3 p-5'>
    //               <button className='btn btn-outline-secondary col-5'>
    //                 <Link to='/'>Go home </Link>
    //               </button>
    //               <button className='btn btn-primary col-5 '>
    //                 <Link to='/pages/wizards/horizontal'>
    //                   <span className='text-white'> Create card</span>{' '}
    //                 </Link>
    //               </button>
    //             </div>
    //           </div>
    //         ) : (
    //           <div className='container mb-4'>
    //             <MetaTagWrapper
    //               title={
    //                 cardDetailsData?.first_name +
    //                 ' ' +
    //                 (cardDetailsData?.last_name ? cardDetailsData?.last_name : '') +
    //                 ' ' +
    //                 (cardDetailsData?.company_name ? `|| ${cardDetailsData?.company_name}` : '')
    //               }
    //               description={cardDetailsData.designation}
    //               image={PROFILE_IMAGE_URL + orderDetails.template?.profile_image}
    //               url={window.location.href}
    //               twitter='summary_large_image'
    //               icon={PROFILE_IMAGE_URL + orderDetails.template?.profile_image}
    //               keywords={`${cardDetailsData.designation},${cardDetailsData.first_name},${cardDetailsData.middle_name},${cardDetailsData.last_name},${cardDetailsData.company_name},${cardDetailsData.id}, tap-savvy, tapsavvy,Tapsavvycards, tap savvy cards`}
    //             />
    //             {/* <Helmet>
    //           <title>tap savvy</title>
    //           <meta name='description' content={cardDetailsData.designation} />
    //           <meta
    //             property='og:image'
    //             content={PROFILE_IMAGE_URL + orderDetails.template?.profile_image}
    //           />
    //           <meta
    //             name='keywords'
    //             content={`${cardDetailsData.designation},${cardDetailsData.first_name},${cardDetailsData.middle_name},${cardDetailsData.last_name},${cardDetailsData.company_name},${cardDetailsData.id}, tap-savvy, tapsavvy,Tapsavvycards, tap savvy cards`}
    //           />
    //           <meta property='og:url' content={window.location.href} />
    //           <meta name='twitter:card' content={cardDetailsData.about} />
    //         </Helmet> */}
    //             <div className='row'>
    //               <div className='col-12 col-md-4 offset-md-4'>
    //                 <div id='home' className='mb-8'>
    //                   <div>
    //                     <img  style={{height:"200px"}} className='img-fluid'/>
    //                   </div>
    //                   <div >
    //                     <img src="/media/tempIcon/profilePic.png" style={{height:"200px"}} className='img-fluid'/>
    //                     <div>
    //                       <h3>{cardDetailsData?.first_name} {cardDetailsData?.middle_name}</h3>
    //                       <h3>{cardDetailsData?.last_name}</h3>
    //                       <h6>{cardDetailsData?.company_name}</h6>
    //                       <p>{cardDetailsData?.designation}</p>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div>
    //                   <div className='card mb-8' style={{fontFamily: style?.font_style}}>
    //                     <div
    //                       className='card-body rounded-3 p-4'
    //                       style={{backgroundColor: style?.background_color}}
    //                     >
    //                       <h1 className='card-title text-center' style={{color: style?.font_color}}>
    //                         Connect On whatsapp
    //                       </h1>
    //                       <div className='row'>
    //                         <div className='col-8'>
    //                           <input
    //                             value={whatsappNo}
    //                             onChange={(e) => setWhatsappNo(e.target.value)}
    //                             className='form-control'
    //                             placeholder='Enter number with country code'
    //                           />
    //                         </div>
    //                         <div className='col-4'>
    //                           <button
    //                             style={{background: orderDetails?.template?.button_color}}
    //                             className={'btn  w-100 ' + (whatsappNo.length == 0 && ' disabled')}
    //                           >
    //                             <a
    //                               target='blank'
    //                               href={whatsAppLink}
    //                               style={{
    //                                 textDecoration: 'none',
    //                                 color: orderDetails?.template?.button_text_color,
    //                               }}
    //                             >
    //                               Send
    //                             </a>
    //                           </button>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 {otherDesignation && (
    //                   <div id='qrcode' className='mb-8 '>
    //                     {' '}
    //                     <div className='card ' style={{fontFamily: style?.font_style}}>
    //                       <div
    //                         className='card-body rounded-3 p-4'
    //                         style={{backgroundColor: style?.background_color}}
    //                       >
    //                         <h1 className='card-title text-center' style={{color: style?.font_color}}>
    //                           Other Designation
    //                         </h1>
    //                         {otherDesignation &&
    //                           otherDesignation.map((v: String, i: Number) => {
    //                             return (
    //                               <div className='text-center m-3'>
    //                                 <div
    //                                   style={{
    //                                     color: style?.font_color,
    //                                     border: `1px solid ${style?.font_color}`,
    //                                   }}
    //                                   className=' p-2 rounded m-2'
    //                                 >
    //                                   <b>{v}</b>
    //                                 </div>
    //                               </div>
    //                             )
    //                           })}
    //                       </div>
    //                     </div>
    //                   </div>
    //                 )}
    //                 <div id='qrcode' className='mb-8 '>
    //                   {' '}
    //                   <Qrcode orderDetails={orderDetails} />
    //                 </div>
    //                 {cardDetailsData.product_services?.length! > 0 && (
    //                   <div id='products' className='mb-8 '>
    //                     {' '}
    //                     <ProductServices
    //                       orderDetails={orderDetails}
    //                       productDetails={cardDetailsData?.product_services}
    //                       whatsappNo={cardDetailsData.whats_app_no}
    //                     />
    //                   </div>
    //                 )}
    //                 {cardDetailsData.galleries?.length! > 0 && (
    //                   <div id='gallery' className='mb-8'>
    //                     <Gallery orderDetails={orderDetails} gallery={cardDetailsData?.galleries} />
    //                   </div>
    //                 )}
    //                 {cardDetailsData?.video_link && (
    //                   <div id='youtube' className='mb-8'>
    //                     {' '}
    //                     <YouTubeVideo VideoLink={cardDetailsData?.video_link} />
    //                   </div>
    //                 )}
    //                 {cardDetailsData.payment_details?.length! > 0 && (
    //                   <div id='payment' className='mb-8'>
    //                     <Payment
    //                       paymentDetails={cardDetailsData.payment_details}
    //                       orderDetails={orderDetails}
    //                     />
    //                   </div>
    //                 )}

    //                 {/* <div id='feedback' className='mb-8'>
    //               {' '}
    //               <FeedbackForm orderDetails={orderDetails} />
    //             </div>
    //             <div id='contact' className='mb-8'>
    //               {' '}
    //               <ContactForm />
    //             </div> */}
    //               </div>

    //               <div className='text-center mb-10'>
    //                 <a href='https://tap-savvy.com/' target='_blank'>
    //                   <button
    //                     type='button'
    //                     className='border-0 px-6 py-4 rounded'
    //                     style={{
    //                       backgroundColor:
    //                         cardDetailsData?.order_details?.at(0)?.template?.button_background == 0
    //                           ? cardDetailsData?.order_details?.at(0)?.template?.button_color
    //                           : 'none',
    //                       color: cardDetailsData?.order_details?.at(0)?.template?.button_text_color,
    //                       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.7)',
    //                     }}
    //                   >
    //                     Create New Card !
    //                   </button>
    //                 </a>
    //               </div>
    //             </div>
    //             <div className='mt-10'>
    //               <BottomNav orderDetails={orderDetails} cardDetailsData={cardDetailsData} />
    //             </div>
    //           </div>
    //         )}
    //       </>
    //     )}
    //     <ToastContainer />
    //   </>
    // )


  // }

 
  // WARN : Template 02 03 lower body 

  // return (
  //   <div className='d-flex justify-content-center   mx-lg-5'>
  //     <div className='col-lg-4 col-md-8 col-12'>
  //       <div
  //         className='topNav d-flex justify-content-between py-5 px-5'
  //         style={{background: 'rgba(253, 253, 253, 0.63)'}}
  //       >
  //         <div>
  //           <img
  //             src='/media/tempIcon/shareIcon.png'
  //             className='img-fluid'
  //             style={{height: '15px'}}
  //           />
  //         </div>
  //         <div className='d-flex'>
  //           <img
  //             src='/media/tempIcon/phoneIcon.png'
  //             className='img-fluid mx-5'
  //             style={{height: '15px'}}
  //           />
  //           <img src='/media/tempIcon/pin.png' className='img-fluid' style={{height: '15px'}} />
  //         </div>
  //       </div>
  //       {false ? (
  //         <div style={{background: '#D9D9D9'}}>
  //           <div className='coverImg'>
  //             <img
  //               src='http://getwallpapers.com/wallpaper/full/0/0/9/146966.jpg'
  //               className='img-fluid'
  //             />
  //           </div>
  //           <div
  //             className='otherDetails pt-10 pb-0 px-10'
  //             style={{
  //               borderRadius: '50px 50px 0px 0px',
  //               background: '#D9D9D9',
  //               position: 'relative',
  //               bottom: '53px',
  //             }}
  //           >
  //             <div className='d-flex'>
  //               <img
  //                 src='https://i.pinimg.com/originals/07/33/ba/0733ba760b29378474dea0fdbcb97107.png'
  //                 className='img-fluid'
  //                 style={{
  //                   height: '110px',
  //                   width: '110px',
  //                   border: '3px solid #535353',
  //                   borderRadius: '50%',
  //                 }}
  //               />
  //               <div className='my-auto mx-3'>
  //                 <h2
  //                   style={{
  //                     color: '#3F3F3F',
  //                     fontSize: '23px',
  //                     fontWeight: '600',
  //                     lineHeight: '25px',
  //                   }}
  //                   className='mb-0'
  //                 >
  //                   Naveeen Upadhyay
  //                 </h2>
  //                 <p
  //                   className='mb-0'
  //                   style={{color: '#6D6D6D', fontSize: '12px', lineHeight: '25px'}}
  //                 >
  //                   Managing Team
  //                 </p>
  //                 <h6 style={{color: '#626262', fontSize: '15px', lineHeight: '27px'}}>
  //                   BALAJI TRADERS
  //                 </h6>
  //               </div>
  //             </div>
  //             <p
  //               className='mt-5'
  //               style={{
  //                 color: '#686868',
  //                 fontSize: '15px',
  //                 fontWeight: '500',
  //                 lineHeight: '17px',
  //               }}
  //             >
  //               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  //               incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
  //               exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  //             </p>
  //             <div className='mx-10' style={{height: '2px', background: '#515151'}}></div>
  //             <p
  //               className='text-center px-3 mt-5'
  //               style={{
  //                 color: '000',
  //                 fontFamily: 'Poppins',
  //                 fontSize: '18px',
  //                 fontStyle: 'normal',
  //                 fontWeight: '400',
  //                 lineHeight: '20px',
  //               }}
  //             >
  //               <img src='/media/tempIcon/pin.png' style={{height: '18px'}} className='mb-2' />
  //               33, Gurukripa Building, 2nd Floor, Kika Street
  //             </p>
  //           </div>
  //         </div>
  //       ) : (
  //         <div style={{background: '#D9D9D9', padding: '30px 0px 20px 0px'}}>
  //           <div>
  //             <div
  //               className='navigation d-flex mx-3'
  //               style={{borderRadius: '44px', background: '#BBB', fontSize: '12px'}}
  //             >
  //               <div
  //                 style={{
  //                   background: '#2B2B2B',
  //                   color: 'white',
  //                   borderRadius: '22px 0px 0px 22px',
  //                   textAlign: 'center',
  //                   padding: '12px 0px',
  //                   width: '33.33%',
  //                 }}
  //               >
  //                 Products
  //               </div>
  //               <div
  //                 style={{
  //                   background: '#BBB',
  //                   textAlign: 'center',
  //                   padding: '12px 0px',
  //                   width: '33.33%',
  //                 }}
  //               >
  //                 Gallery
  //               </div>
  //               <div
  //                 style={{
  //                   background: '#BBB',
  //                   borderRadius: '0px 22px 22px 0px',
  //                   textAlign: 'center',
  //                   padding: '12px 0px',
  //                   width: '33.34%',
  //                 }}
  //               >
  //                 Payments
  //               </div>
  //             </div>
  //             {false ? (
  //               <div className='row my-10'>
  //                 <div className='col-6'>
  //                   <div className='m-3'>
  //                     <img
  //                       src='/media/tempIcon/iceCreamImg.png'
  //                       className='img-fluid'
  //                       style={{borderRadius: '20px 20px 0px 0px'}}
  //                     />
  //                     <div>
  //                       <h5 className='text-center py-1 mb-0' style={{background: '#9A9A9A'}}>
  //                         Lorem picsul
  //                       </h5>
  //                       <p className='text-center pb-1 mb-0' style={{background: '#9A9A9A'}}>
  //                         hello world{' '}
  //                       </p>
  //                       <div
  //                         className='d-flex'
  //                         style={{
  //                           background: '#3C3C3C',
  //                           borderRadius: '0px 0px 20px 20px',
  //                           overflow: 'hidden',
  //                         }}
  //                       >
  //                         <p
  //                           className='text-center text-light mb-0 '
  //                           style={{
  //                             width: '50%',
  //                             borderRight: '1px white solid',
  //                             padding: '6px 0px',
  //                           }}
  //                         >
  //                           view
  //                         </p>
  //                         <p
  //                           className='text-center text-light mb-0 '
  //                           style={{width: '50%', padding: '6px 0px'}}
  //                         >
  //                           enquiry
  //                         </p>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className='col-6'>
  //                   <div className='m-3'>
  //                     <img
  //                       src='/media/tempIcon/iceCreamImg.png'
  //                       className='img-fluid'
  //                       style={{borderRadius: '20px 20px 0px 0px'}}
  //                     />
  //                     <div>
  //                       <h5 className='text-center py-1 mb-0' style={{background: '#9A9A9A'}}>
  //                         Lorem picsul
  //                       </h5>
  //                       <p className='text-center pb-1 mb-0' style={{background: '#9A9A9A'}}>
  //                         hello world{' '}
  //                       </p>
  //                       <div
  //                         className='d-flex'
  //                         style={{
  //                           background: '#3C3C3C',
  //                           borderRadius: '0px 0px 20px 20px',
  //                           overflow: 'hidden',
  //                         }}
  //                       >
  //                         <p
  //                           className='text-center text-light mb-0 '
  //                           style={{
  //                             width: '50%',
  //                             borderRight: '1px white solid',
  //                             padding: '6px 0px',
  //                           }}
  //                         >
  //                           view
  //                         </p>
  //                         <p
  //                           className='text-center text-light mb-0 '
  //                           style={{width: '50%', padding: '6px 0px'}}
  //                         >
  //                           enquiry
  //                         </p>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className='col-6'>
  //                   <div className='m-3'>
  //                     <img
  //                       src='/media/tempIcon/iceCreamImg.png'
  //                       className='img-fluid'
  //                       style={{borderRadius: '20px 20px 0px 0px'}}
  //                     />
  //                     <div>
  //                       <h5 className='text-center py-1 mb-0' style={{background: '#9A9A9A'}}>
  //                         Lorem picsul
  //                       </h5>
  //                       <p className='text-center pb-1 mb-0' style={{background: '#9A9A9A'}}>
  //                         hello world{' '}
  //                       </p>
  //                       <div
  //                         className='d-flex'
  //                         style={{
  //                           background: '#3C3C3C',
  //                           borderRadius: '0px 0px 20px 20px',
  //                           overflow: 'hidden',
  //                         }}
  //                       >
  //                         <p
  //                           className='text-center text-light mb-0 '
  //                           style={{
  //                             width: '50%',
  //                             borderRight: '1px white solid',
  //                             padding: '6px 0px',
  //                           }}
  //                         >
  //                           view
  //                         </p>
  //                         <p
  //                           className='text-center text-light mb-0 '
  //                           style={{width: '50%', padding: '6px 0px'}}
  //                         >
  //                           enquiry
  //                         </p>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className='col-6'>
  //                   <div className='m-3'>
  //                     <img
  //                       src='/media/tempIcon/iceCreamImg.png'
  //                       className='img-fluid'
  //                       style={{borderRadius: '20px 20px 0px 0px'}}
  //                     />
  //                     <div>
  //                       <h5 className='text-center py-1 mb-0' style={{background: '#9A9A9A'}}>
  //                         Lorem picsul
  //                       </h5>
  //                       <p className='text-center pb-1 mb-0' style={{background: '#9A9A9A'}}>
  //                         hello world{' '}
  //                       </p>
  //                       <div
  //                         className='d-flex'
  //                         style={{
  //                           background: '#3C3C3C',
  //                           borderRadius: '0px 0px 20px 20px',
  //                           overflow: 'hidden',
  //                         }}
  //                       >
  //                         <p
  //                           className='text-center text-light mb-0 '
  //                           style={{
  //                             width: '50%',
  //                             borderRight: '1px white solid',
  //                             padding: '6px 0px',
  //                           }}
  //                         >
  //                           view
  //                         </p>
  //                         <p
  //                           className='text-center text-light mb-0 '
  //                           style={{width: '50%', padding: '6px 0px'}}
  //                         >
  //                           enquiry
  //                         </p>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             ) : (
  //               <div className='row px-5 py-10'>
  //                 <div className='col-4 p-0'>
  //                   <div className='m-4'>
  //                     <img src='/media/tempIcon/iceCreamImg.png' className='img-fluid' />
  //                   </div>
  //                 </div>
  //                 <div className='col-4 p-0'>
  //                   <div className='m-4'>
  //                     <img src='/media/tempIcon/iceCreamImg.png' className='img-fluid' />
  //                   </div>
  //                 </div>
  //                 <div className='col-4 p-0'>
  //                   <div className='m-4'>
  //                     <img src='/media/tempIcon/iceCreamImg.png' className='img-fluid' />
  //                   </div>
  //                 </div>
  //                 <div className='col-4 p-0'>
  //                   <div className='m-4'>
  //                     <img src='/media/tempIcon/iceCreamImg.png' className='img-fluid' />
  //                   </div>
  //                 </div>
  //                 <div className='col-4 p-0'>
  //                   <div className='m-4'>
  //                     <img src='/media/tempIcon/iceCreamImg.png' className='img-fluid' />
  //                   </div>
  //                 </div>
  //                 <div className='col-4 p-0'>
  //                   <div className='m-4'>
  //                     <img src='/media/tempIcon/iceCreamImg.png' className='img-fluid' />
  //                   </div>
  //                 </div>
  //                 <div className='col-4 p-0'>
  //                   <div className='m-4'>
  //                     <img src='/media/tempIcon/iceCreamImg.png' className='img-fluid' />
  //                   </div>
  //                 </div>
  //                 <div className='col-4 p-0'>
  //                   <div className='m-4'>
  //                     <img src='/media/tempIcon/iceCreamImg.png' className='img-fluid' />
  //                   </div>
  //                 </div>
  //                 <div className='col-4 p-0'>
  //                   <div className='m-4'>
  //                     <img src='/media/tempIcon/iceCreamImg.png' className='img-fluid' />
  //                   </div>
  //                 </div>

  //               </div>
  //             )}

  //             <div
  //               style={{
  //                 borderRadius: '20px',
  //                 marginBottom: '40px',
  //                 background: '#454545',
  //                 color: 'white',
  //               }}
  //               className='text-center py-3 mx-3 mb-35'
  //             >
  //               Load more
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //       <div className='d-flex justify-content-evenly'>
  //         <img src='/media/tempIcon/scan.png' style={{height: '20px'}} />
  //         <img src='/media/tempIcon/email.png' style={{height: '20px'}} />
  //         <div
  //           style={{
  //             padding: '20px',
  //             background: 'black',
  //             borderRadius: '50%',
  //             position: 'relative',
  //             bottom: '40px',
  //           }}
  //         >
  //           <img src='/media/tempIcon/contactShare.png' style={{height: '20px'}} />
  //         </div>
  //         <img src='/media/tempIcon/whatsapp.png' style={{height: '20px'}} />
  //         <img src='/media/tempIcon/tag.png' style={{height: '20px'}} />
  //       </div>
  //     </div>
  //   </div>
  // )


 // WARN: TEMPLATE ID 02 (incomplete)

  // return (
  //   <div className='d-flex justify-content-center  mx-lg-5'>
  //     <div className='col-lg-4 col-md-8 col-12'>
  //       <div className='coverImg'>
  //         <img
  //           src='http://getwallpapers.com/wallpaper/full/0/0/9/146966.jpg'
  //           className='img-fluid'
  //         />
  //       </div>
  //       <div
  //         style={{
  //           background: 'linear-gradient(0deg, #DDD 18.94%, rgba(241, 249, 255, 0.00) 69.92%)',
  //         }}
  //       >
  //         <div
  //           className='profileDiv bg-light  row mx-15'
  //           style={{
  //             height: '230px',
  //             position: 'relative',
  //             top: '-60px',
  //             borderRadius: '40px',
  //             overflow: 'hidden',
  //           }}
  //         >
  //           <div className='col-6 p-0 '>
  //             <img
  //               src='https://i.pinimg.com/originals/07/33/ba/0733ba760b29378474dea0fdbcb97107.png'
  //               className='img-fluid'
  //               style={{height: '100%'}}
  //             />
  //           </div>
  //           <div className='col-6 bg-light mt-auto'>
  //             <h1
  //               style={{
  //                 fontSize: '25px',
  //                 color: '#2E2E2E',
  //                 fontWeight: '800',
  //               }}
  //               className='mb-0'
  //             >
  //               Naveen Upadhyan
  //             </h1>
  //             <h2
  //               className='mt-3 mb-1'
  //               style={{
  //                 fontSize: '15px',
  //                 color: '#2B2B2B',
  //                 fontWeight: '400',
  //               }}
  //             >
  //               BALAJI TRADERS
  //             </h2>
  //             <p
  //               className='mb-10'
  //               style={{
  //                 fontSize: '15px',
  //                 color: '#2B2B2B',
  //                 fontWeight: '300',
  //                 fontFamily: 'popins',
  //               }}
  //             >
  //               (Morning Team)
  //             </p>
  //           </div>
  //         </div>
  //         <div
  //           className=' mx-7 row '
  //           style={{marginTop: '-40px', borderRadius: '50px', overflow: 'hidden'}}
  //         >
  //           <div className='col-6 p-0 text-center py-5 bg-dark'>
  //             <i className='fa fa-phone'></i>
  //           </div>
  //           <div className='col-6 p-0 text-center py-5 bg-secondary '>
  //             <i className='fa fa-message'></i>
  //           </div>
  //         </div>
  //         <div
  //           className='mx-7 text-center py-5'
  //           style={{
  //             fontWeight: '500',
  //             color: '#565656',
  //             fontSize: '14px',
  //             borderBottom: '1.5px solid #000',
  //           }}
  //         >
  //           LEMI is an Nationally recognized expert in the field of hardware interior fittings
  //           Established since year 2003. As an established expert in the field of hardware with an
  //           experience of 20 years. Worked with many builders, interior designers and helping many
  //           people to select the products matching their interior and budget friendly.
  //         </div>
  //         <p
  //           className='text-center px-3 mt-5'
  //           style={{
  //             color: '000',
  //             fontFamily: 'Poppins',
  //             fontSize: '18px',
  //             fontStyle: 'normal',
  //             fontWeight: '500',
  //             lineHeight: '20px',
  //           }}
  //         >
  //           <img src='/media/tempIcon/pin.png' style={{height: '18px'}} className='mb-2' />
  //           33, Gurukripa Building, 2nd Floor, Kika Street
  //         </p>
  //         <div className='socialIcon d-flex justify-content-center mt-3 pb-5 mb-5'>
  //           <img src='/media/tempIcon/whatsapp.png' className='mx-2' style={{height: '35px'}} />
  //           <img src='/media/tempIcon/facebook.png' className='mx-2' style={{height: '35px'}} />
  //           <img src='/media/tempIcon/instagram.png' className='mx-2' style={{height: '35px'}} />
  //           <img src='/media/tempIcon/linkedin.png' className='mx-2' style={{height: '35px'}} />
  //         </div>
  //         <div className='d-flex justify-content-center'>
  //           <div
  //             className='saveContactBtn'
  //             style={{
  //               borderRadius: '30px',
  //               background: '#585858',
  //               padding: '10px 20px',
  //               color: '#FFF',
  //               fontFamily: 'Raleway',
  //               fontSize: '20px',
  //               fontStyle: 'normal',
  //               fontWeight: '600',
  //               lineHeight: '25px',
  //               position: 'relative',
  //               top: '20px',
  //             }}
  //           >
  //             Save Contact
  //           </div>
  //         </div>
  //       </div>
  //       {/* qr code start */}
  //       <div className='py-5 bg-light'>
  //         <div className=' mt-5 pt-10 row'>
  //           <div className='col-3'></div>
  //           <div className='col-6'>
  //             <img src='/media/tempIcon/qrcode.png' className='img-fluid ' />
  //           </div>
  //           <div className='col-3'></div>
  //         </div>
  //         <p
  //           className='text-center mt-4 mb-3'
  //           style={{
  //             color: '#555353',
  //             fontFamily: 'Poppins',
  //             fontSize: '20px',
  //             fontStyle: 'normal',
  //             fontWeight: '300',
  //             lineHeight: 'normal',
  //           }}
  //         >
  //           Scan The QR Code
  //         </p>
  //         <div className='buttonGroup mb-10 row mx-17'>
  //           <div className='col-6 text-center' style={{background: '#929292'}}>
  //             <img
  //               src='/media/tempIcon/directDownLoad.png'
  //               className='py-3'
  //               style={{height: '30px'}}
  //             />
  //           </div>
  //           <div className='col-6 text-center' style={{background: '#585858'}}>
  //             <img src='/media/tempIcon/link.png' className='py-3' style={{height: '30px'}} />
  //           </div>
  //         </div>
  //       </div>
  //       {/* qr code end */}

  //       {/* product and services start */}
  //       <div style={{background: '#585858', padding: '30px 20px 60px 20px'}}>
  //         <p
  //           style={{
  //             color: '#FFF',
  //             textAlign: 'center',
  //             fontFamily: 'monospace',
  //             fontSize: '20px',
  //             fontStyle: 'normal',
  //             fontWeight: '600',
  //             lineHeight: 'normal',
  //           }}
  //         >
  //           Products & Services
  //         </p>
  //         <div className='my-10' style={{background: '#F6F6F6', padding: '20px 0px'}}>
  //           <div
  //             className='text-center '
  //             style={{
  //               position: 'relative',
  //               left: '50%',
  //               transform: 'translate(-50%, 0%)',
  //               bottom: '40px',
  //               background: '#D9D9D9',
  //               boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  //               width: '200px',
  //               padding: '8px 0px',
  //               color: '#2E2E2E',
  //               fontSize: '16px',
  //               fontWeight: '700',
  //             }}
  //           >
  //             Laser Brass Handles
  //           </div>
  //           <div className='mx-10'>
  //             <img
  //               src='/media/tempIcon/iceCreamImg.png'
  //               style={{height: '250px'}}
  //               className='img-fluid'
  //             />
  //             <div className='d-flex my-5 justify-content-between'>
  //               <div
  //                 style={{
  //                   background: '#333',
  //                   color: '#DFDFDF',
  //                   fontSize: '12px',
  //                   fontWeight: '400',
  //                   width: '90px',
  //                   padding: '8px 0px',
  //                   alignItems: 'center',
  //                   textAlign: 'center',
  //                 }}
  //               >
  //                 view
  //               </div>
  //               <div
  //                 style={{
  //                   background: '#333',
  //                   color: '#DFDFDF',
  //                   fontSize: '12px',
  //                   fontWeight: '400',
  //                   width: '90px',
  //                   padding: '8px 0px',
  //                   alignItems: 'center',
  //                   textAlign: 'center',
  //                 }}
  //               >
  //                 enquiry
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='my-10' style={{background: '#F6F6F6', padding: '20px 0px'}}>
  //           <div
  //             className='text-center '
  //             style={{
  //               position: 'relative',
  //               left: '50%',
  //               transform: 'translate(-50%, 0%)',
  //               bottom: '40px',
  //               background: '#D9D9D9',
  //               boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  //               width: '200px',
  //               padding: '8px 0px',
  //               color: '#2E2E2E',
  //               fontSize: '16px',
  //               fontWeight: '700',
  //             }}
  //           >
  //             Laser Brass Handles
  //           </div>
  //           <div className='mx-10'>
  //             <img
  //               src='/media/tempIcon/iceCreamImg.png'
  //               style={{height: '250px'}}
  //               className='img-fluid'
  //             />
  //             <div className='d-flex my-5 justify-content-between'>
  //               <div
  //                 style={{
  //                   background: '#333',
  //                   color: '#DFDFDF',
  //                   fontSize: '12px',
  //                   fontWeight: '400',
  //                   width: '90px',
  //                   padding: '8px 0px',
  //                   alignItems: 'center',
  //                   textAlign: 'center',
  //                 }}
  //               >
  //                 view
  //               </div>
  //               <div
  //                 style={{
  //                   background: '#333',
  //                   color: '#DFDFDF',
  //                   fontSize: '12px',
  //                   fontWeight: '400',
  //                   width: '90px',
  //                   padding: '8px 0px',
  //                   alignItems: 'center',
  //                   textAlign: 'center',
  //                 }}
  //               >
  //                 enquiry
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='my-10' style={{background: '#F6F6F6', padding: '20px 0px'}}>
  //           <div
  //             className='text-center '
  //             style={{
  //               position: 'relative',
  //               left: '50%',
  //               transform: 'translate(-50%, 0%)',
  //               bottom: '40px',
  //               background: '#D9D9D9',
  //               boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  //               width: '200px',
  //               padding: '8px 0px',
  //               color: '#2E2E2E',
  //               fontSize: '16px',
  //               fontWeight: '700',
  //             }}
  //           >
  //             Laser Brass Handles
  //           </div>
  //           <div className='mx-10'>
  //             <img
  //               src='/media/tempIcon/iceCreamImg.png'
  //               style={{height: '250px'}}
  //               className='img-fluid'
  //             />
  //             <div className='d-flex my-5 justify-content-between'>
  //               <div
  //                 style={{
  //                   background: '#333',
  //                   color: '#DFDFDF',
  //                   fontSize: '12px',
  //                   fontWeight: '400',
  //                   width: '90px',
  //                   padding: '8px 0px',
  //                   alignItems: 'center',
  //                   textAlign: 'center',
  //                 }}
  //               >
  //                 view
  //               </div>
  //               <div
  //                 style={{
  //                   background: '#333',
  //                   color: '#DFDFDF',
  //                   fontSize: '12px',
  //                   fontWeight: '400',
  //                   width: '90px',
  //                   padding: '8px 0px',
  //                   alignItems: 'center',
  //                   textAlign: 'center',
  //                 }}
  //               >
  //                 enquiry
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='my-10' style={{background: '#F6F6F6', padding: '20px 0px'}}>
  //           <div
  //             className='text-center '
  //             style={{
  //               position: 'relative',
  //               left: '50%',
  //               transform: 'translate(-50%, 0%)',
  //               bottom: '40px',
  //               background: '#D9D9D9',
  //               boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  //               width: '200px',
  //               padding: '8px 0px',
  //               color: '#2E2E2E',
  //               fontSize: '16px',
  //               fontWeight: '700',
  //             }}
  //           >
  //             Laser Brass Handles
  //           </div>
  //           <div className='mx-10'>
  //             <img
  //               src='/media/tempIcon/iceCreamImg.png'
  //               style={{height: '250px'}}
  //               className='img-fluid'
  //             />
  //             <div className='d-flex my-5 justify-content-between'>
  //               <div
  //                 style={{
  //                   background: '#333',
  //                   color: '#DFDFDF',
  //                   fontSize: '12px',
  //                   fontWeight: '400',
  //                   width: '90px',
  //                   padding: '8px 0px',
  //                   alignItems: 'center',
  //                   textAlign: 'center',
  //                 }}
  //               >
  //                 view
  //               </div>
  //               <div
  //                 style={{
  //                   background: '#333',
  //                   color: '#DFDFDF',
  //                   fontSize: '12px',
  //                   fontWeight: '400',
  //                   width: '90px',
  //                   padding: '8px 0px',
  //                   alignItems: 'center',
  //                   textAlign: 'center',
  //                 }}
  //               >
  //                 enquiry
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       {/* product and services end */}
  //       {/* gallery start */}
  //       <div className='gallery' style={{background: '#FFF', padding: '30px 0px 0px 0px'}}>
  //         <p
  //           style={{
  //             color: '#333',
  //             fontSize: '18px',
  //             fontWeight: '600',
  //             textAlign: 'center',
  //             margin: '10px 20px',
  //           }}
  //         >
  //           Gallery
  //         </p>
  //         <div className='mx-10 row '>
  //           <div className='col-4 p-0'>
  //             <img src='/media/tempIcon/rectangle.png' className='img-fluid p-3' />
  //           </div>
  //           <div className='col-4 p-0'>
  //             <img src='/media/tempIcon/rectangle.png' className='img-fluid p-3' />
  //           </div>
  //           <div className='col-4 p-0'>
  //             <img src='/media/tempIcon/rectangle.png' className='img-fluid p-3' />
  //           </div>
  //           <div className='col-4 p-0'>
  //             <img src='/media/tempIcon/rectangle.png' className='img-fluid p-3' />
  //           </div>
  //           <div className='col-4 p-0'>
  //             <img src='/media/tempIcon/rectangle.png' className='img-fluid p-3' />
  //           </div>
  //           <div className='col-4 p-0'>
  //             <img src='/media/tempIcon/rectangle.png' className='img-fluid p-3' />
  //           </div>
  //           <div className='col-4 p-0'>
  //             <img src='/media/tempIcon/rectangle.png' className='img-fluid p-3' />
  //           </div>
  //           <div className='col-4 p-0'>
  //             <img src='/media/tempIcon/rectangle.png' className='img-fluid p-3' />
  //           </div>
  //           <div className='col-4 p-0'>
  //             <img src='/media/tempIcon/rectangle.png' className='img-fluid p-3' />
  //           </div>
  //         </div>
  //         <p className='text-center'>www.lemifittings.com</p>
  //       </div>
  //       {/* gallery end */}
  //       <div className='paymentMethod pb-20 ' style={{background: '#FFF'}}>
  //         <div className='paytmDiv pt-5 pb-10' style={{background: '#D9D9D9'}}>
  //           <p className='text-center mb-5'>Payment Method</p>
  //           <div className='row mt-5 mx-10'>
  //             <div className='col-3 p-0'>
  //               <div className='mx-4'>
  //                 <img src='/media/tempIcon/paytm.png' className='img-fluid' />
  //               </div>
  //             </div>
  //             <div className='col-3 p-0'>
  //               <div className='mx-4'>
  //                 <img src='/media/tempIcon/paytm.png' className='img-fluid' />
  //               </div>
  //             </div>
  //             <div className='col-3 p-0'>
  //               <div className='mx-4'>
  //                 <img src='/media/tempIcon/paytm.png' className='img-fluid' />
  //               </div>
  //             </div>
  //             <div className='col-3 p-0'>
  //               <div className='mx-4'>
  //                 <img src='/media/tempIcon/paytm.png' className='img-fluid' />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )




// WARN: TEMPLATE ID 01
  
  // return (
  //   <>
  //     {showPassword ? (
  //       <div className='vh-100 bg-dark d-flex justify-content-center align-items-center'>
  //         <div className='p-lg-0 p-5'>
  //           <h1 className='text-light text-center'>This card has been locked by the user </h1>
  //           <br />
  //           <div>
  //             <label className='text-light label-control'>
  //               Please enter password to view the card
  //             </label>
  //             <br />
  //             <input
  //               className='form-control'
  //               type='password'
  //               placeholder='Enter Password eg. 123456'
  //               value={cardPassword}
  //               onChange={(e) => setCardPassword(e.target.value)}
  //             />
  //             <button
  //               className={
  //                 'btn btn-sm btn-primary my-2 w-100' +
  //                 (cardPassword.length == 6 ? '' : ' disabled')
  //               }
  //               onClick={handleUnlockFunc}
  //             >
  //               Submit
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     ) : (
  //       <>
  //         {!Isloaded ? (
  //           <div className='loader'>
  //             {' '}
  //             <div className='lds-facebook'>
  //               <div></div>
  //               <div></div>
  //               <div></div>
  //             </div>
  //           </div>
  //         ) : Isloaded && !cardDetailsData.id ? (
  //           <>
  //             <div className='error-container'>
  //               {/* <img src="error-illustration.svg" alt="Error Illustration" className="illustration"/> */}
  //               <h2 className='display-4 text-center mb-4'>Sorry, this card doesn't exist</h2>
  //               <Link to='/pages/wizards/horizontal'>
  //                 <button className='btn btn-primary btn-lg'>Create Your Card</button>
  //               </Link>
  //             </div>
  //           </>
  //         ) : Isloaded && cardDetailsData.status === 0 ? (
  //           <div className='card m-5'>
  //             <h1 className='text-center p-5'>Your card is waiting for admin's approval!</h1>
  //             <div className='row justify-content-center g-3 p-5'>
  //               <button className='btn btn-outline-secondary col-5'>
  //                 <Link to='/'>Go home </Link>
  //               </button>
  //               <button className='btn btn-primary col-5 '>
  //                 <Link to='/pages/wizards/horizontal'>
  //                   <span className='text-white'> Create card</span>{' '}
  //                 </Link>
  //               </button>
  //             </div>
  //           </div>
  //         ) : (
  //           <div className='container mb-4'>
  //             <MetaTagWrapper
  //               title={
  //                 cardDetailsData?.first_name +
  //                 ' ' +
  //                 (cardDetailsData?.last_name ? cardDetailsData?.last_name : '') +
  //                 ' ' +
  //                 (cardDetailsData?.company_name ? `|| ${cardDetailsData?.company_name}` : '')
  //               }
  //               description={cardDetailsData.designation}
  //               image={PROFILE_IMAGE_URL + orderDetails.template?.profile_image}
  //               url={window.location.href}
  //               twitter='summary_large_image'
  //               icon={PROFILE_IMAGE_URL + orderDetails.template?.profile_image}
  //               keywords={`${cardDetailsData.designation},${cardDetailsData.first_name},${cardDetailsData.middle_name},${cardDetailsData.last_name},${cardDetailsData.company_name},${cardDetailsData.id}, tap-savvy, tapsavvy,Tapsavvycards, tap savvy cards`}
  //             />
  //             {/* <Helmet>
  //           <title>tap savvy</title>
  //           <meta name='description' content={cardDetailsData.designation} />
  //           <meta
  //             property='og:image'
  //             content={PROFILE_IMAGE_URL + orderDetails.template?.profile_image}
  //           />
  //           <meta
  //             name='keywords'
  //             content={`${cardDetailsData.designation},${cardDetailsData.first_name},${cardDetailsData.middle_name},${cardDetailsData.last_name},${cardDetailsData.company_name},${cardDetailsData.id}, tap-savvy, tapsavvy,Tapsavvycards, tap savvy cards`}
  //           />
  //           <meta property='og:url' content={window.location.href} />
  //           <meta name='twitter:card' content={cardDetailsData.about} />
  //         </Helmet> */}
  //             <div className='row'>
  //               <div className='col-12 col-md-4 offset-md-4'>
  //                 <div id='home' className='mb-8'>
  //                   <CardDetails3
  //                     id={id}
  //                     cardDetailsData={cardDetailsData}
  //                     orderDetails={orderDetails}
  //                   />
  //                 </div>
  //                 <div>
  //                   <div className='card mb-8' style={{fontFamily: style?.font_style}}>
  //                     <div
  //                       className='card-body rounded-3 p-4'
  //                       style={{backgroundColor: style?.background_color}}
  //                     >
  //                       <h1 className='card-title text-center' style={{color: style?.font_color}}>
  //                         Connect On whatsapp
  //                       </h1>
  //                       <div
  //                         className='row'
  //                         style={{padding: '10px', boxSizing: 'border-box', overflow: 'hidden'}}
  //                       >
  //                         <div className='col-8'>
  //                           <input
  //                             value={whatsappNo}
  //                             onChange={(e) => setWhatsappNo(e.target.value)}
  //                             className='form-control'
  //                             placeholder='Enter number with country code'
  //                             style={{marginTop: '10px'}}
  //                           />
  //                         </div>
  //                         <div className='col-4'>
  //                           <button
  //                             // style={{background: orderDetails?.template?.button_color}}
  //                             style={{background: 'none'}}
  //                             className={'btn  w-100 ' + (whatsappNo.length < 10 && ' disabled')}
  //                           >
  //                             <a
  //                               target='blank'
  //                               href={whatsAppLink}
  //                               style={{
  //                                 textDecoration: 'none',
  //                                 color: orderDetails?.template?.button_text_color,
  //                               }}
  //                             >
  //                               <div
  //                                 style={{
  //                                   width: '70px',
  //                                   height: '20px',
  //                                   display: 'flex',
  //                                   alignItems: 'center',
  //                                   justifyContent: 'center',
  //                                   backgroundColor: '#25d366',
  //                                   border: 'none',
  //                                   padding: '20px 50px',
  //                                   borderRadius: '12px',
  //                                   marginTop: '0',
  //                                   color: '#ffffff',
  //                                   fontWeight: 'bold',
  //                                   cursor: 'pointer',
  //                                 }}
  //                               >
  //                                 {/* Text content */}
  //                                 <span
  //                                   style={{
  //                                     marginRight: '5px',
  //                                     fontFamily: 'Arial, sans-serif',
  //                                     fontWeight: 'lighter',
  //                                   }}
  //                                 >
  //                                   Share
  //                                 </span>

  //                                 {/* Image */}
  //                                 <img
  //                                   src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/2044px-WhatsApp.svg.png'
  //                                   alt='WhatsApp Icon'
  //                                   style={{width: '20px', height: '20px'}}
  //                                 />
  //                               </div>
  //                             </a>
  //                           </button>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 {otherDesignation && (
  //                   <div id='qrcode' className='mb-8 '>
  //                     {' '}
                     
  //                     <div className='card ' style={{fontFamily: style?.font_style}}>
  //                       <div
  //                         className='card-body rounded-3 p-4'
  //                         style={{backgroundColor: style?.background_color}}
  //                       >
  //                         <h1 className='card-title text-center' style={{color: style?.font_color}}>
  //                           Other Designation
  //                         </h1>
  //                         {otherDesignation &&
  //                           otherDesignation.map((v: String, i: Number) => {
  //                             return (
  //                               <div className='text-center m-3'>
  //                                 <div
  //                                   style={{
  //                                     color: style?.font_color,
  //                                     border: `1px solid ${style?.font_color}`,
  //                                   }}
  //                                   className=' p-2 rounded m-2'
  //                                 >
  //                                   <b>{v}</b>
  //                                 </div>
  //                               </div>
  //                             )
  //                           })}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 )}
  //                 <div id='qrcode' className='mb-8 '>
  //                   {' '}
  //                   {/* <img
  //                       src={staticURL}
  //                       alt='QR Code'
  //                       className='img-fluid'
  //                     /> */}
  //                   <Qrcode orderDetails={orderDetails} />
  //                 </div>
  //                 {cardDetailsData.product_services?.length! > 0 && (
  //                   <div id='products' className='mb-8 '>
  //                     {' '}
  //                     <ProductServices
  //                       orderDetails={orderDetails}
  //                       productDetails={cardDetailsData?.product_services}
  //                       whatsappNo={cardDetailsData.whats_app_no}
  //                     />
  //                   </div>
  //                 )}
  //                 {cardDetailsData.galleries?.length! > 0 && (
  //                   <div id='gallery' className='mb-8'>
  //                     <Gallery orderDetails={orderDetails} gallery={cardDetailsData?.galleries} />
  //                   </div>
  //                 )}
  //                 {cardDetailsData?.video_link && (
  //                   <div id='youtube' className='mb-8'>
  //                     {' '}
  //                     <YouTubeVideo VideoLink={cardDetailsData?.video_link} />
  //                   </div>
  //                 )}
  //                 {cardDetailsData.payment_details?.length! > 0 && (
  //                   <div id='payment' className='mb-8'>
  //                     <Payment
  //                       paymentDetails={cardDetailsData.payment_details}
  //                       orderDetails={orderDetails}
  //                     />
  //                   </div>
  //                 )}

  //                 {/* <div id='feedback' className='mb-8'>
  //               {' '}
  //               <FeedbackForm orderDetails={orderDetails} />
  //             </div>
  //             <div id='contact' className='mb-8'>
  //               {' '}
  //               <ContactForm />
  //             </div> */}
  //               </div>

  //               <div className='text-center mb-10'>
  //                 <a href='https://card.tap-savvy.com/login' target='_blank'>
  //                   <button
  //                     type='button'
  //                     className='border-0 px-6 py-4 rounded'
  //                     style={{
  //                       backgroundColor:
  //                         cardDetailsData?.order_details?.at(0)?.template?.button_background == 0
  //                           ? cardDetailsData?.order_details?.at(0)?.template?.button_color
  //                           : 'none',
  //                       color: cardDetailsData?.order_details?.at(0)?.template?.button_text_color,
  //                       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.7)',
  //                     }}
  //                   >
  //                     Login
  //                   </button>
  //                 </a>
  //               </div>
  //             </div>
  //             <div className='mt-10'>
  //               <BottomNav orderDetails={orderDetails} cardDetailsData={cardDetailsData} />
  //             </div>
  //           </div>
  //         )}
  //       </>
  //     )}
  //     <ToastContainer />
  //   </>
  // )
  

  return(
<>

{orderDetails.template?.theme_id === 3?
(<>

{
  showPassword?
  (
    <div className='vh-100 bg-dark d-flex justify-content-center align-items-center'>
        <div className='p-lg-0 p-5'>
          <h1 className='text-light text-center'>This card has been locked by the user </h1>
          <br />
          <div>
            <label className='text-light label-control'>
              Please enter password to view the card
            </label>
            <br />
            <input
              className='form-control'
              type='password'
              placeholder='Enter Password eg. 123456'
              value={cardPassword}
              onChange={(e) => setCardPassword(e.target.value)}
            />
            <button
              className={
                'btn btn-sm btn-primary my-2 w-100' +
                (cardPassword.length === 6 ? '' : ' disabled')
              }
              onClick={handleUnlockFunc}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
  ):(
    <>
    {
      !Isloaded ?
      (<div className='loader'>
      {' '}
      <div className='lds-facebook'>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>)
      :
      (
        <>
        {Isloaded && !cardDetailsData.id ? (
              <div className='error-container'>
                <h2 className='display-4 text-center mb-4'>
                  Sorry, this card doesn't exist
                </h2>
                <Link to='/pages/wizards/horizontal'>
                  <button className='btn btn-primary btn-lg'>
                    Create Your Card
                  </button>
                </Link>
              </div>)
              :
              (<>
                {Isloaded && cardDetailsData.status === 0 ? (
                  <div className='card m-5'>
                    <h1 className='text-center p-5'>
                      Your card is waiting for admin's approval!
                    </h1>
                    <div className='row justify-content-center g-3 p-5'>
                      <button className='btn btn-outline-secondary col-5'>
                        <Link to='/'>Go home </Link>
                      </button>
                      <button className='btn btn-primary col-5 '>
                        <Link to='/pages/wizards/horizontal'>
                          <span className='text-white'> Create card</span>{' '}
                        </Link>
                      </button>
                    </div>
                  </div>
                ) : (
                  <Template03
                    id={cardDetailsData?.id}
                    first_name={cardDetailsData?.first_name}
                    last_name={cardDetailsData?.last_name}
                    about={cardDetailsData?.about}
                    company_name={cardDetailsData?.company_name}
                    designation={cardDetailsData.designation}
                    profile_image={orderDetails.template?.profile_image}
                    background_image={orderDetails.template?.cover_image}
                    background_color={orderDetails.template?.background_color}
                    font_color={orderDetails.template?.font_color}
                    font_style={orderDetails.template?.font_style}
                    country_code={cardDetailsData.country_code}
                    mobile_no={cardDetailsData?.mobile_no}
                    whatsapp_no={cardDetailsData?.whats_app_no}
                    email={cardDetailsData?.email}
                    website_link={cardDetailsData?.website_link}
                    qr_code_image={orderDetails?.card_qr_code}
                    product={cardDetailsData?.product_services}
                    address={cardDetailsData?.address}
                    galleries={cardDetailsData?.galleries}
                    payment={cardDetailsData?.payment_details}
                    socialMedia={cardDetailsData?.social_links}
                  />
                )}
              </>)}
        </>
      )
    }
    </>
  )
}

</>):

orderDetails.template?.theme_id === 2 ? (
  <>
    {showPassword ? (
      <div className='vh-100 bg-dark d-flex justify-content-center align-items-center'>
        <div className='p-lg-0 p-5'>
          <h1 className='text-light text-center'>This card has been locked by the user </h1>
          <br />
          <div>
            <label className='text-light label-control'>
              Please enter password to view the card
            </label>
            <br />
            <input
              className='form-control'
              type='password'
              placeholder='Enter Password eg. 123456'
              value={cardPassword}
              onChange={(e) => setCardPassword(e.target.value)}
            />
            <button
              className={
                'btn btn-sm btn-primary my-2 w-100' +
                (cardPassword.length === 6 ? '' : ' disabled')
              }
              onClick={handleUnlockFunc}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    ) : (
      <>
        {!Isloaded ? (
          <div className='loader'>
            {' '}
            <div className='lds-facebook'>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <>
            {Isloaded && !cardDetailsData.id ? (
              <div className='error-container'>
                <h2 className='display-4 text-center mb-4'>
                  Sorry, this card doesn't exist
                </h2>
                <Link to='/pages/wizards/horizontal'>
                  <button className='btn btn-primary btn-lg'>
                    Create Your Card
                  </button>
                </Link>
              </div>
            ) : (
              <>
                {Isloaded && cardDetailsData.status === 0 ? (
                  <div className='card m-5'>
                    <h1 className='text-center p-5'>
                      Your card is waiting for admin's approval!
                    </h1>
                    <div className='row justify-content-center g-3 p-5'>
                      <button className='btn btn-outline-secondary col-5'>
                        <Link to='/'>Go home </Link>
                      </button>
                      <button className='btn btn-primary col-5 '>
                        <Link to='/pages/wizards/horizontal'>
                          <span className='text-white'> Create card</span>{' '}
                        </Link>
                      </button>
                    </div>
                  </div>
                ) : (
                  <Template02
                    id = {cardDetailsData?.id}
                    first_name={cardDetailsData?.first_name}
                    last_name={cardDetailsData?.last_name}
                    about={cardDetailsData?.about}
                    company_name={cardDetailsData?.company_name}
                    designation={cardDetailsData.designation}
                    profile_image={orderDetails.template?.profile_image}
                    background_image={orderDetails.template?.cover_image}
                    country_code={cardDetailsData.country_code}
                    background_color={orderDetails.template?.background_color}
                    bg_image={orderDetails.template?.background_image}
                    font_color={orderDetails.template?.font_color}
                    font_style={orderDetails.template?.font_style}
                    button_background_color={orderDetails.template?.button_color}
                    button_font_color={orderDetails.template?.button_text_color}
                    mobile_no={cardDetailsData?.mobile_no}
                    whatsapp_no={cardDetailsData?.whats_app_no}
                    email={cardDetailsData?.email}
                    website_link={cardDetailsData?.website_link}
                    qr_code_image={orderDetails?.card_qr_code}
                    product={cardDetailsData?.product_services}
                    address={cardDetailsData?.address}
                    galleries={cardDetailsData?.galleries}
                    payment={cardDetailsData?.payment_details}
                    socialMedia={cardDetailsData?.social_links}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    )}
  </>
) : 

  (
      <>
      {showPassword ? (
        <div className='vh-100 bg-dark d-flex justify-content-center align-items-center'>
          <div className='p-lg-0 p-5'>
            <h1 className='text-light text-center'>This card has been locked by the user </h1>
            <br />
            <div>
              <label className='text-light label-control'>
                Please enter password to view the card
              </label>
              <br />
              <input
                className='form-control'
                type='password'
                placeholder='Enter Password eg. 123456'
                value={cardPassword}
                onChange={(e) => setCardPassword(e.target.value)}
              />
              <button
                className={
                  'btn btn-sm btn-primary my-2 w-100' +
                  (cardPassword.length == 6 ? '' : ' disabled')
                }
                onClick={handleUnlockFunc}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!Isloaded ? (
            <div className='loader'>
              {' '}
              <div className='lds-facebook'>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : Isloaded && !cardDetailsData.id ? (
            <>
              <div className='error-container'>
                {/* <img src="error-illustration.svg" alt="Error Illustration" className="illustration"/> */}
                <h2 className='display-4 text-center mb-4'>Sorry, this card doesn't exist</h2>
                <Link to='/pages/wizards/horizontal'>
                  <button className='btn btn-primary btn-lg'>Create Your Card</button>
                </Link>
              </div>
            </>
          ) : Isloaded && cardDetailsData.status === 0 ? (
            <div className='card m-5'>
              <h1 className='text-center p-5'>Your card is waiting for admin's approval!</h1>
              <div className='row justify-content-center g-3 p-5'>
                <button className='btn btn-outline-secondary col-5'>
                  <Link to='/'>Go home </Link>
                </button>
                <button className='btn btn-primary col-5 '>
                  <Link to='/pages/wizards/horizontal'>
                    <span className='text-white'> Create card</span>{' '}
                  </Link>
                </button>
              </div>
            </div>
          ) : (
            <div className='container mb-4'>
              <MetaTagWrapper
                title={
                  cardDetailsData?.first_name +
                  ' ' +
                  (cardDetailsData?.last_name !=null ? cardDetailsData?.last_name : '') +
                  ' ' +
                  (cardDetailsData?.company_name !=null ? `|| ${cardDetailsData?.company_name}` : '')
                }
                description={cardDetailsData?.designation !=null ? cardDetailsData?.designation : ''}
                image={PROFILE_IMAGE_URL + orderDetails.template?.profile_image}
                url={window.location.href}
                twitter='summary_large_image'
                icon={PROFILE_IMAGE_URL + orderDetails.template?.profile_image}
                keywords={`${cardDetailsData.designation},${cardDetailsData.first_name},${cardDetailsData.middle_name},${cardDetailsData.last_name},${cardDetailsData.company_name},${cardDetailsData.id}, tap-savvy, tapsavvy,Tapsavvycards, tap savvy cards`}
              />
            
              <div className='row'>
                <div className='col-12 col-md-4 offset-md-4'>
                  <div id='home' className='mb-8'>
                    <CardDetails3
                      id={id}
                      cardDetailsData={cardDetailsData}
                      orderDetails={orderDetails}
                    />
                  </div>
                  <div>
                    <div className='card mb-8' style={{fontFamily: style?.font_style}}>
                      <div
                        className='card-body rounded-3 p-4'
                        style={{backgroundColor: style?.background_color}}
                      >
                        <h1 className='card-title text-center' style={{color: style?.font_color}}>
                          Connect On Whatsapp
                        </h1>
                        <div
                          className='row'
                          style={{padding: '10px', boxSizing: 'border-box', overflow: 'hidden'}}
                        >
                          <div className='col-8'>
                            <input
                              value={whatsappNo}
                              onChange={(e) => setWhatsappNo(e.target.value)}
                              className='form-control'
                              placeholder='Enter number with country code'
                              style={{marginTop: '10px'}}
                            />
                          </div>
                          <div className='col-4'>
                            <button
                              // style={{background: orderDetails?.template?.button_color}}
                              style={{background: 'none'}}
                              className={'btn  w-100 ' + (whatsappNo.length < 10 && ' disabled')}
                            >
                              <a
                                target='blank'
                                href={whatsAppLink}
                                style={{
                                  textDecoration: 'none',
                                  color: orderDetails?.template?.button_text_color,
                                }}
                              >
                                <div
                                  style={{
                                    width: '70px',
                                    height: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#25d366',
                                    border: 'none',
                                    padding: '20px 50px',
                                    borderRadius: '12px',
                                    marginTop: '0',
                                    color: '#ffffff',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {/* Text content */}
                                  <span
                                    style={{
                                      marginRight: '5px',
                                      fontFamily: 'Arial, sans-serif',
                                      fontWeight: 'lighter',
                                    }}
                                  >
                                    Share
                                  </span>

                                  {/* Image */}
                                  <img
                                    src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/2044px-WhatsApp.svg.png'
                                    alt='WhatsApp Icon'
                                    style={{width: '20px', height: '20px'}}
                                  />
                                </div>
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {otherDesignation && (
                    <div id='qrcode' className='mb-8 '>
                      {' '}
                    
                      <div className='card ' style={{fontFamily: style?.font_style}}>
                        <div
                          className='card-body rounded-3 p-4'
                          style={{backgroundColor: style?.background_color}}
                        >
                          <h1 className='card-title text-center' style={{color: style?.font_color}}>
                            Other Designation
                          </h1>
                          {otherDesignation &&
                            otherDesignation.map((v: String, i: Number) => {
                              return (
                                <div className='text-center m-3'>
                                  <div
                                    style={{
                                      color: style?.font_color,
                                      border: `1px solid ${style?.font_color}`,
                                    }}
                                    className=' p-2 rounded m-2'
                                  >
                                    <b>{v}</b>
                                  </div>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  )}
                  <div id='qrcode' className='mb-8 '>
                    {' '}
                    {/* <img
                        src={staticURL}
                        alt='QR Code'
                        className='img-fluid'
                      /> */}
                    <Qrcode orderDetails={orderDetails} />
                  </div>
                  {cardDetailsData.product_services?.length! > 0 && (
                    <div id='products' className='mb-8 '>
                      {' '}
                      <ProductServices
                        orderDetails={orderDetails}
                        productDetails={cardDetailsData?.product_services}
                        whatsappNo={cardDetailsData.whats_app_no}
                      />
                    </div>
                  )}
                  {cardDetailsData.galleries?.length! > 0 && (
                    <div id='gallery' className='mb-8'>
                      <Gallery orderDetails={orderDetails} gallery={cardDetailsData?.galleries} />
                    </div>
                  )}
                  {cardDetailsData?.video_link && (
                    <div id='youtube' className='mb-8'>
                      {' '}
                      <YouTubeVideo VideoLink={cardDetailsData?.video_link} />
                    </div>
                  )}
                  {cardDetailsData.payment_details?.length! > 0 && (
                    <div id='payment' className='mb-8'>
                      <Payment
                        paymentDetails={cardDetailsData.payment_details}
                        orderDetails={orderDetails}
                      />
                    </div>
                  )}

                  {/* <div id='feedback' className='mb-8'>
                {' '}
                <FeedbackForm orderDetails={orderDetails} />
              </div>
              <div id='contact' className='mb-8'>
                {' '}
                <ContactForm />
              </div> */}
                </div>

                <div className='text-center mb-10'>
                  <a href='https://card.tap-savvy.com/login' target='_blank'>
                    <button
                      type='button'
                      className='border-0 px-6 py-4 rounded'
                      style={{
                        backgroundColor:
                          cardDetailsData?.order_details?.at(0)?.template?.button_background == 0
                            ? cardDetailsData?.order_details?.at(0)?.template?.button_color
                            : 'none',
                        color: cardDetailsData?.order_details?.at(0)?.template?.button_text_color,
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.7)',
                      }}
                    >
                      Login    
                    </button>
                  </a>
                </div>
              </div>     
              <div className='mt-10'>
                <BottomNav orderDetails={orderDetails} cardDetailsData={cardDetailsData} />
              </div>
            </div>
          )}
        </>
      )}
      <ToastContainer />
    </>
  
  )
    
  }

</>
  )
  

  



}

export default CardView
