import React, { FC } from 'react'
import { ListsWidget5 } from '../../../../../_metronic/partials/widgets'

const BulkCardCreation: FC = () => {

    return (
        <>
            <div className='row g-5 g-xl-8'>
                <div className='col-xl'>
                    <div className='col-xl'>
                        <ListsWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BulkCardCreation