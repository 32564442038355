/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import MetaTagWrapper from '../../../../app/modules/helpers/MetaTagWrapper'
import { AllRegisteredUsers } from '../../../../app/modules/widgets/components/admin/inerfaces/AllRegisteredUser'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import { Link } from 'react-router-dom'
import { PROFILE_URL_IMAGE } from '../../../../app/modules/helpers/imageUrl'

type Props = {
  className?: string
}

const ListsWidget1: FC<Props> = ({ className }) => {

  const { onGet } = useApiHook()
  const [allRegisteredUser, setAllRegisteredUser] = useState<AllRegisteredUsers[]>([])

  const getAllRegisterUserData = async () => {
    try {
      const response = await onGet('/user_list')
      // console.log(response.data)
      // console.log(response?.data?.['users'])

      const registeredUserData: any = response?.data?.['users']
      const AllRegisteredInfoData: AllRegisteredUsers[] = registeredUserData
      setAllRegisteredUser(AllRegisteredInfoData)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllRegisterUserData()
  }, [])

  console.log(allRegisteredUser)

  return (
    <>
      <MetaTagWrapper title='Registered Users || Tap Savvy' />
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-muted'>Registered Users</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Pending 10 tasks</span> */}
          </h3>

          {/* <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <Dropdown1 />
          </div> */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body pt-5'>
          {/* begin::Item */}
          {
            allRegisteredUser && allRegisteredUser.map(item => (
              <>
                <div className='d-flex justify-content-between align-items-center' key={item.id}>
                  <div className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                      {
                        item.profile_pic ? (
                          <>
                            <img 
                              src={PROFILE_URL_IMAGE + item.profile_pic} 
                              alt="profile__image"
                              style={{
                                backgroundColor: 'transparent',
                                fontSize: '25px',
                                fontWeight: '700',
                                color: 'white'
                              }} />
                          </>
                        ) : (
                          <>
                            <span className='symbol-label' style={{
                              backgroundColor: '#800080',
                              fontSize: '25px',
                              fontWeight: '700',
                              color: 'white'
                            }}>
                              {/* <KTIcon iconName='abstract-26' className='fs-2x text-success' /> */}
                              {/* <KTIcon iconName='abstract-26' className='fs-2x text-success' /> */}
                              {
                                item.name?.substring(0, 1).toUpperCase()
                              }
                            </span>
                          </>
                        )
                      }
                    </div>
                    <div className='d-flex flex-column'>
                      <Link to={''} className='text-dark text-hover-primary fs-6 fw-bold'>
                        {item.name}
                      </Link>
                      <span className='text-muted fw-semibold'>{item.email}</span>
                    </div>
                  </div>
                  <div>
                    {
                      item.email_verified_at && item.email_verified_at ? (
                        <span className='badge badge-light-success fs-8 fw-bold'>Verified</span>
                      ) : (
                        <span className='badge badge-light-danger fs-8 fw-bold'>Not Verified</span>
                      )
                    }
                  </div>
                </div>
              </>
            ))
          }
          {/* end::Item */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export { ListsWidget1 }
