/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { Languages } from './Languages'
import { toAbsoluteUrl } from '../../../helpers'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import { APPURL, PROFILE_URL_IMAGE } from '../../../../app/modules/helpers/imageUrl'
import axios from 'axios'

const BASE_URL = APPURL
const PROFILE_IMAGE_URL = PROFILE_URL_IMAGE

interface UpdateProfile {
  name?: string;
  phone_number?: string;
}

const HeaderUserMenu: FC = () => {
  const navigate = useNavigate()
  const { currentUser, logout, profileData, getShowProfileData } = useAuth()
  const { onGet } = useApiHook()
  const { setCurrentUser } = useAuth()
  const [showProfile, setShowProfile] = useState(false)
  const [inputValue, setInputValue] = useState<UpdateProfile>({})
  const [file, setFile] = useState<File | null>(null)
  const token = localStorage.getItem('token')

  useEffect(() => {
    setInputValue({
      name: profileData?.name,
      phone_number: profileData?.phone_number
    })
  }, [])

  // !logout fucntion
  const handlelogout = async () => {
    const { status, data } = await onGet('/logout')
    console.log(status, data)

    if (status === 200) {
      setCurrentUser(null)
      localStorage.setItem('token', '')
      localStorage.setItem('user_type', '')
      navigate('/login')
      // window.location.replace('/login');
    }
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target

    setInputValue({
      ...inputValue,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    try {
      const formData = new FormData()

      if (file) {
        formData.append('profile_pic', file)
      }

      formData.append('name', inputValue?.name!)
      if (inputValue.phone_number !== profileData?.phone_number) {
        
        formData.append('phone_number', inputValue?.phone_number!)
      }
      formData.append('_method', 'PUT')

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
        },
      }

      const response = await axios.post(`${BASE_URL}/user/update_profile`, formData, config)

      // console.log(response);
      // console.log(response?.data?.data?.['user']);
      // console.log(response.status);

      if(response.status === 200) {
        getShowProfileData()
        setShowProfile(false)
      }
    } 
    catch (error) {
      console.log(error)
    }
  }

  // console.log(inputValue)
  // console.log(profileData)
  // console.log(profileData?.name)
  // console.log(name)
useEffect(() => {
  setInputValue({
    name: profileData?.name,
    phone_number: profileData?.phone_number,
  })
}, [profileData])
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
              {
                profileData?.profile_pic ? (
                  <span>
                    <img style={{ width: '46px', height: '46px', borderRadius: '50%' }} src={PROFILE_IMAGE_URL + profileData.profile_pic} alt="profile_image" />
                  </span>
                ) : (
                  <span style={{
                    width: '46px',
                    height: '46px',
                    borderRadius: '50%',
                    backgroundColor: 'purple',
                    color: '#ffffff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '25px',
                    fontWeight: 700
                  }}
                  >{profileData?.name?.substring(0, 1).toUpperCase()}</span>
                )
              }
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {profileData?.name}
                {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {profileData?.email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <span className='menu-link px-5' onClick={() => setShowProfile(true)}>
            Edit Profile
          </span>
        </div>

        {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}

        {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      > */}
        {/* <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

        <div className='separator my-2'></div>

        {/* <Languages />

        <div className='menu-item px-5 my-1' aria-disabled>
          <Link to='/crafted/account/settings' className='menu-link px-5'>
            Account Settings
          </Link>
        </div> */}

        <div className='menu-item px-5'>
          <a onClick={handlelogout} className='menu-link px-5'>
            Sign Out
          </a>
        </div>
      </div>

      {
        showProfile && (
          <div className='modal fade show' style={{ display: 'block' }}>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                    Edit Profile
                  </h5>
                </div>

                <form>
                  <div className='modal-body'>
                    <div className='pb-10 pb-lg-15'>
                      <label className='form-label fs-6'>Name</label>
                      <input
                        type='text'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        value={inputValue.name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='pb-10 pb-lg-15'>
                      <label className='form-label fs-6'>Phone Number</label>
                      <input
                        maxLength={25}
                        type='tel'
                        name='phone_number'
                        className='form-control'
                        placeholder='Phone Number'
                        value={inputValue.phone_number}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='pb-10 pb-lg-15'>
                      <label className='form-label fs-6'>Profile Image</label>
                      <input
                        type='file'
                        accept=".png,.jpg,.jpeg"
                        className='form-control'
                        name='logo'
                        placeholder='Logo'
                        onChange={(e) => setFile(e.target.files?.[0] || null)}
                      />
                    </div>
                  </div>

                  <div className='modal-footer'>
                    <button
                      className='btn btn-secondary'
                      onClick={() => setShowProfile(false)}
                    >
                      Close
                    </button>
                    <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export { HeaderUserMenu }
