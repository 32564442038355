import React, {FC} from 'react'

export const UpdateDashboardAPI = (buttonName:string, id:number) => {
  //API

  const API_BASE_URL = process.env.REACT_APP_API_URL
  const token = localStorage.getItem('token')
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)

  const apicall = async () => {
    try{
        const formData = new FormData();
        formData.append('card_id',id.toString());
         formData.append('button', buttonName.toLowerCase());
  
         const response = await fetch(`${API_BASE_URL}/click`, {
          method: 'POST',
          body: formData,
          headers: headers,
        })
        console.log('response hit: ', response.status)
        
      }
      catch(error)
      {
        console.log(error)
      }
  }

  // calling
  apicall();
  
  return (
    <div>
      {/* Your component JSX */}
    </div>
  );
}

// export default UpdateDashboardAPI
