import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { string } from 'yup'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/user/login`
export const REGISTER_URL = `${API_URL}/user/register/`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const VERIFY_OTP_URL = `${API_URL}/verify_otp`
export const RESET_PASSWORD_URL = `${API_URL}/reset_password`
export const CHANGE_PASSWORD_URL = `${API_URL}/change_password`

// Server should return AuthModel
export function verifyOtp(otp: number) {
  const token = JSON.stringify(sessionStorage.getItem('token')).replace(/["]+/g, '');

  // console.log(typeof token)
  
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const bodyParameters = {
    email_otp: otp.toString()
  };

  return axios.put(VERIFY_OTP_URL,
    bodyParameters,
    config
  )
}

export function resetPassword(
    password: string,
    password_confirmation: string,
    otp: string
  ) {
  const token = JSON.stringify(localStorage.getItem('token')).replace(/["]+/g, '')
  const local_email = localStorage.getItem('email');
  
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const bodyParameters = {
    email: local_email,
    password,
    password_confirmation,
    otp: otp,
  };

  return axios.post(RESET_PASSWORD_URL, 
    bodyParameters,
    config
  )
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  id: number,
  name: string,
  email: string,
  password: string,
  password_confirmation: string,
  phone_number?: number | string
) {
  return axios.post(REGISTER_URL+id, {
    email,
    password,
    password_confirmation,
    name: name,
    // phone_number: phone_number,
  })
}

// server should return object
export function changePasswordUpdateData(
  currentpassword: any,
  newPassword: any,
  passwordConfirmation: any
) {

  const token = JSON.stringify(localStorage.getItem('token'));
  
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const bodyParameters = {
    current_password: currentpassword,
    new_password: newPassword,
    new_password_confirmation: passwordConfirmation
  };

  return axios.put(CHANGE_PASSWORD_URL, {
    bodyParameters,
    config
  })
}

export function CreateSubAdmin(
  name: string,
  email: string,
  password: string,
  confirmPassword: string
) {
  return 
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
