/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {
  SocialType,
  SocialTypeInfo,
} from '../../../../app/modules/widgets/components/admin/inerfaces/SocialMediaInterface'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import { SOCIAL_MEDIA_URL } from '../../../../app/modules/helpers/imageUrl'
import axios from 'axios'
import { APPURL } from '../../../../app/modules/helpers/imageUrl'
import MetaTagWrapper from '../../../../app/modules/helpers/MetaTagWrapper'

const socialMediaURL = SOCIAL_MEDIA_URL
const BASE_URL = APPURL

type Props = {
  className: string
  tabIndex: any
}

interface NameInfo {
  name?: string
}

const ListsWidget3: React.FC<Props> = ({ className, tabIndex }) => {
  const { onGet } = useApiHook()
  const [allSocialMedia, setAllSocialMedia] = useState<SocialType[]>([])
  const [showModal, setShowModal] = useState(false)
  const [showModalAddIcon, setShowModalAddIcon] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [inputValue, setInputValue] = useState<SocialTypeInfo>({})
  const [statusCode, setStatusCode] = useState<any>(1)
  const [file, setFile] = useState<File | null>(null)
  const [fileEdit, setFileEdit] = useState<File | null>(null)
  const [name, setName] = useState('')
  const [toBedeleted, settoBedeleted] = useState<number>()
  
  const getSocialMediaLinks = async () => {
    const response = await onGet('/social_types')
    // console.log(response.data)
    const res: any = response.data?.['social_types']
    // console.log(res)

    const social_types: SocialType[] = res
    setAllSocialMedia(social_types)
  }

  useEffect(() => {
    getSocialMediaLinks()
  }, [])

  const handleDeleteMediaIcon = async () => {
    setShowModal(true)
    try {
      const response = await onGet(`/delete/social_type/${toBedeleted}`)
      console.log(response)

      if (response.status === 200) {
        setShowModal(false)
        // getSocialMediaLinks()
        const filterIcons = allSocialMedia.filter((element: any, item: any) => {
          // console.log(typeof element.id);
          // console.log(element.id);
          // console.log(typeof id);
          // console.log(id);

          return element.id !== toBedeleted
        })

        setAllSocialMedia(filterIcons)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target

    setInputValue({
      ...inputValue,
      [name]: value,
    })
  }

  useEffect(() => {
    setInputValue({
      ...inputValue,
      status: statusCode,
    })
  }, [])

  const handleSubmit = async () => {
    try {
      const formData = new FormData()

      if (file) {
        formData.append('logo', file)
      }

      formData.append('name', inputValue?.name!)
      formData.append('status', statusCode)
      // console.log(formData);

      const token = localStorage.getItem('token')
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
        },
      }
      const response = await axios.post(`${BASE_URL}/store/social_type`, formData, config)
      // console.log(response)

      if (response.status === 200) {
        setShowModalAddIcon(false)
        getSocialMediaLinks()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleShowModalEdit = (name: any) => {
    setShowModalEdit(true)
    setName(name)
  }

  const handleEditUpdate = async (id: any) => {
    try {
      const formData = new FormData()

      if (fileEdit) {
        formData.append('logo', fileEdit)
      }
      formData.append('_method', 'PUT')
      formData.append('name', name)
      formData.append('status', statusCode)

      const token = localStorage.getItem('token')
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'access-control-allow-origin': '*',
          'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
        },
      }

      const response = await axios.post(`${BASE_URL}/update/social_type/${id}`, formData, config)
      console.log(response)

      if (response.status === 200) {
        getSocialMediaLinks()
        setShowModalEdit(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // console.log(allSocialMedia)
  // console.log(inputValue)
  // console.log(name)
  // console.log(fileEdit)

  return (
    <>
      <MetaTagWrapper title='Social Media || Tap Savvy' />
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title fw-bold text-muted'>Social Media</h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary btn-sm btn-active-light-primary'
              onClick={() => setShowModalAddIcon(true)}
            >
              Add Social Media Icons
            </button>
            {showModalAddIcon && (
              <div className='modal fade show' style={{ display: 'block' }}>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                        Add Social Media Icon
                      </h5>
                    </div>
                    <form>
                      <div className='modal-body'>
                        <div className='pb-10 pb-lg-15'>
                          <label className='form-label fs-6'>Name</label>
                          <input
                            type='text'
                            name='name'
                            className='form-control'
                            placeholder='Icon name'
                            onChange={handleChange}
                          />
                        </div>

                        <div className='pb-10 pb-lg-15'>
                          <label className='form-label fs-6'>Logo Icon</label>
                          <input
                            type='file'
                            className='form-control'
                            name='logo'
                            placeholder='Logo'
                            onChange={(e) => setFile(e.target.files?.[0] || null)}
                          />
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button
                          className='btn btn-danger'
                          onClick={() => setShowModalAddIcon(false)}
                        >
                          close
                        </button>
                        <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-2'>
          {/* begin::Item */}
          <div className='d-flex flex-column mb-3'>
            {/* begin::Description */}
            {allSocialMedia &&
              allSocialMedia.map((item, index) => (
                <div className='d-flex align-items-center justify-content-between mb-8' key={item.id}>
                  <div className='d-flex align-items-center ms-5'>
                    <img style={{ width: '50px' }} src={socialMediaURL + item.logo} alt='media icon' />
                    <span className='text-muted fw-semibold d-block mx-5'>{item.name}</span>
                  </div>
                  <div className='d-flex align-items-center me-5'>
                    <button
                      className='badge badge-light-primary border-0 fs-8 fw-bold me-5'
                      onClick={() => handleShowModalEdit(item.name)}
                    >
                      Edit
                    </button>
                    <button
                      className='badge badge-light-danger border-0 fs-8 fw-bold me-3'
                      onClick={() => {
                        setShowModal(true)
                        settoBedeleted(item.id)
                      }}
                    >
                      Delete
                    </button>
                    {showModal && (
                      <div className='modal fade show' style={{ display: 'block' }}>
                        <div className='modal-dialog modal-dialog-centered'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                                Are you sure you want to delete this social media icon ?
                              </h5>
                            </div>
                            <div className='modal-body'>
                              <h2>You can add this social media icon later if you want !</h2>
                            </div>
                            <div className='modal-footer'>
                              <button
                                className='btn btn-secondary'
                                onClick={() => setShowModal(false)}
                              >
                                close
                              </button>
                              <button
                                type='button'
                                className='btn text-white'
                                style={{ backgroundColor: '#F24183' }}
                                onClick={() => {
                                  handleDeleteMediaIcon()
                                }}
                              >
                                delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {showModalEdit && (
                      <div className='modal fade show' style={{ display: 'block' }}>
                        <div className='modal-dialog modal-dialog-centered'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                                Edit your social media icon ?
                              </h5>
                            </div>
                            <form>
                              <div className='modal-body'>
                                <div className='pb-10 pb-lg-15'>
                                  <label className='form-label fs-6'>Name</label>
                                  <input
                                    type='text'
                                    name='name'
                                    className='form-control'
                                    placeholder='Icon name'
                                    value={name!}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>

                                <div className='pb-10 pb-lg-15'>
                                  <label className='form-label fs-6'>Logo Icon</label>
                                  <input
                                    type='file'
                                    className='form-control'
                                    name='logo'
                                    placeholder='Logo'
                                    onChange={(e) => setFileEdit(e.target.files?.[0] || null)}
                                  />
                                </div>
                              </div>
                              <div className='modal-footer'>
                                <button
                                  className='btn btn-danger'
                                  onClick={() => setShowModalEdit(false)}
                                >
                                  close
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-primary'
                                  onClick={() => handleEditUpdate(item.id)}
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            {/* end::Description */}
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  )

  // function openModal(item: any) {
  //   return (
  //     <div key={item} className='modal fade' id={`${item}`} tabIndex={tabIndex}>
  //       <div className='modal-dialog modal-dialog-centered'>
  //         <div className='modal-content'>
  //           <div className='modal-header'>
  //             <h1 className='modal-title text-gray-800 fw-bold fs-6' id='exampleModalLabel'>
  //               Are you sure you want to delete this social media icon ?
  //             </h1>
  //             <button
  //               type='button'
  //               className='btn-close'
  //               data-bs-dismiss='modal'
  //               aria-label='Close'
  //             ></button>
  //           </div>
  //           <div className='modal-body'>
  //             <h2>You can add this social media icon later if you want !</h2>
  //           </div>
  //           <div className='modal-footer'>
  //             <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
  //               Close
  //             </button>
  //             <button
  //               type='button'
  //               className='btn text-white'
  //               style={{ backgroundColor: '#F24183' }}
  //               onClick={() => {
  //                 handleDeleteMediaIcon(item)
  //               }}
  //             >
  //               Delete{' '}
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
}

export { ListsWidget3 }
