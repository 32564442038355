/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTIcon } from '../../../helpers'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
}

const SubAdminRoles: React.FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [permissionGrant, setPermissionGrant] = useState(false)
  const { mode } = useThemeMode()

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label text-muted fw-bold fs-3 mb-1'>Sub Admin Role List</span>
        </h3>
        {/* end::Title */}

        {/* begin::Toolbar */}
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      <div className='d-flex align-items-sm-center mb-7 px-5'>
        <div className='symbol symbol-60px symbol-2by3 me-4 px-5'>
          <span className='symbol-label' style={{
            backgroundColor: '#800080',
            fontSize: '25px',
            fontWeight: '700',
            color: 'white'
          }}>
            A
          </span>
        </div>

        <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
          <div className='flex-grow-1 me-2'>
            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
              FirstName
            </span>
          </div>

          <div className='flex-grow-1 me-2'>
            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
              Email
            </span>
          </div>

          <div className='mx-10 text-muted fw-semibold d-block pt-1 fs-8'>
            Created At: &nbsp;
            <span className='pt-1 fs-9'>
              12, July, 2023
            </span>
          </div>
        </div>

        <div className='d-flex flex-row-fluid flex-wrap align-items-center justify-content-end px-5'>
          <button
            type='button'
            className='badge badge-light-primary border border-0 fs-8 fw-bold my-2 mx-2'
            onClick={() => {
              setPermissionGrant(true)
            }}
          >
            Permission Grant
          </button>

          <button
            type='button'
            className='badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2'
          >
            Permission Revoke
          </button>
        </div>

        {
          permissionGrant && (
            <>
              <div className='modal fade show' style={{ display: 'block' }}>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                        Are you sure you want to delete this card ?
                      </h5>
                    </div>
                    <div className='modal-body'>
                      <h3>You can create this card later if you want !</h3>
                    </div>
                    <div className='modal-footer'>
                      <button
                        className='btn btn-secondary'
                        onClick={() => setPermissionGrant(false)}
                      >
                        close
                      </button>
                      <button
                        type='button'
                        className='btn text-white'
                        style={{ backgroundColor: '#F24183' }}
                      >
                        delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>

      <div className='d-flex align-items-sm-center mb-7 px-5'>
        <div className='symbol symbol-60px symbol-2by3 me-4 px-5'>
          <span className='symbol-label' style={{
            backgroundColor: '#800080',
            fontSize: '25px',
            fontWeight: '700',
            color: 'white'
          }}>
            A
          </span>
        </div>

        <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
          <div className='flex-grow-1 me-2'>
            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
              FirstName
            </span>
          </div>

          <div className='flex-grow-1 me-2'>
            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
              Email
            </span>
          </div>

          <div className='mx-10 text-muted fw-semibold d-block pt-1 fs-8'>
            Created At: &nbsp;
            <span className='pt-1 fs-9'>
              12, July, 2023
            </span>
          </div>
        </div>

        <div className='d-flex flex-row-fluid flex-wrap align-items-center justify-content-end px-5'>
          <button
            type='button'
            className='badge badge-light-primary border border-0 fs-8 fw-bold my-2 mx-2'
          >
            Permission Grant
          </button>

          <button
            type='button'
            className='badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2'
          >
            Permission Revoke
          </button>
        </div>
      </div>

      <div className='d-flex align-items-sm-center mb-7 px-5'>
        <div className='symbol symbol-60px symbol-2by3 me-4 px-5'>
          <span className='symbol-label' style={{
            backgroundColor: '#800080',
            fontSize: '25px',
            fontWeight: '700',
            color: 'white'
          }}>
            A
          </span>
        </div>

        <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
          <div className='flex-grow-1 me-2'>
            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
              FirstName
            </span>
          </div>

          <div className='flex-grow-1 me-2'>
            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
              Email
            </span>
          </div>

          <div className='mx-10 text-muted fw-semibold d-block pt-1 fs-8'>
            Created At: &nbsp;
            <span className='pt-1 fs-9'>
              12, July, 2023
            </span>
          </div>
        </div>

        <div className='d-flex flex-row-fluid flex-wrap align-items-center justify-content-end px-5'>
          <button
            type='button'
            className='badge badge-light-primary border border-0 fs-8 fw-bold my-2 mx-2'
          >
            Permission Grant
          </button>

          <button
            type='button'
            className='badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2'
          >
            Permission Revoke
          </button>
        </div>
      </div>

      <div className='d-flex align-items-sm-center mb-7 px-5'>
        <div className='symbol symbol-60px symbol-2by3 me-4 px-5'>
          <span className='symbol-label' style={{
            backgroundColor: '#800080',
            fontSize: '25px',
            fontWeight: '700',
            color: 'white'
          }}>
            A
          </span>
        </div>

        <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
          <div className='flex-grow-1 me-2'>
            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
              FirstName
            </span>
          </div>

          <div className='flex-grow-1 me-2'>
            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
              Email
            </span>
          </div>

          <div className='mx-10 text-muted fw-semibold d-block pt-1 fs-8'>
            Created At: &nbsp;
            <span className='pt-1 fs-9'>
              12, July, 2023
            </span>
          </div>
        </div>

        <div className='d-flex flex-row-fluid flex-wrap align-items-center justify-content-end px-5'>
          <button
            type='button'
            className='badge badge-light-primary border border-0 fs-8 fw-bold my-2 mx-2'
          >
            Permission Grant
          </button>

          <button
            type='button'
            className='badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2'
          >
            Permission Revoke
          </button>
        </div>
      </div>

      <div className='d-flex align-items-sm-center mb-7 px-5'>
        <div className='symbol symbol-60px symbol-2by3 me-4 px-5'>
          <span className='symbol-label' style={{
            backgroundColor: '#800080',
            fontSize: '25px',
            fontWeight: '700',
            color: 'white'
          }}>
            A
          </span>
        </div>

        <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
          <div className='flex-grow-1 me-2'>
            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
              FirstName
            </span>
          </div>

          <div className='flex-grow-1 me-2'>
            <span className='text-gray-800 fw-bold text-hover-primary fs-5'>
              Email
            </span>
          </div>

          <div className='mx-10 text-muted fw-semibold d-block pt-1 fs-8'>
            Created At: &nbsp;
            <span className='pt-1 fs-9'>
              12, July, 2023
            </span>
          </div>
        </div>

        <div className='d-flex flex-row-fluid flex-wrap align-items-center justify-content-end px-5'>
          <button
            type='button'
            className='badge badge-light-primary border border-0 fs-8 fw-bold my-2 mx-2'
          >
            Permission Grant
          </button>

          <button
            type='button'
            className='badge badge-light-danger border border-0 fs-8 fw-bold my-2 mx-2'
          >
            Permission Revoke
          </button>
        </div>
      </div>
    </div>
  )
}

export { SubAdminRoles }

// function getChartOptions(height: number): ApexOptions {
//   const labelColor = getCSSVariableValue('--bs-gray-500')
//   const borderColor = getCSSVariableValue('--bs-gray-200')
//   const baseColor = getCSSVariableValue('--bs-primary')
//   const secondaryColor = getCSSVariableValue('--bs-gray-300')

//   return {
//     series: [
//       {
//         name: 'Net Profit',
//         data: [44, 55, 57, 56, 61, 58],
//       },
//       {
//         name: 'Revenue',
//         data: [76, 85, 101, 98, 87, 105],
//       },
//     ],
//     chart: {
//       fontFamily: 'inherit',
//       type: 'bar',
//       height: height,
//       toolbar: {
//         show: false,
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: '30%',
//         borderRadius: 5,
//       },
//     },
//     legend: {
//       show: false,
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       show: true,
//       width: 2,
//       colors: ['transparent'],
//     },
//     xaxis: {
//       categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
//       axisBorder: {
//         show: false,
//       },
//       axisTicks: {
//         show: false,
//       },
//       labels: {
//         style: {
//           colors: labelColor,
//           fontSize: '12px',
//         },
//       },
//     },
//     yaxis: {
//       labels: {
//         style: {
//           colors: labelColor,
//           fontSize: '12px',
//         },
//       },
//     },
//     fill: {
//       opacity: 1,
//     },
//     states: {
//       normal: {
//         filter: {
//           type: 'none',
//           value: 0,
//         },
//       },
//       hover: {
//         filter: {
//           type: 'none',
//           value: 0,
//         },
//       },
//       active: {
//         allowMultipleDataPointsSelection: false,
//         filter: {
//           type: 'none',
//           value: 0,
//         },
//       },
//     },
//     tooltip: {
//       style: {
//         fontSize: '12px',
//       },
//       y: {
//         formatter: function (val) {
//           return '$' + val + ' thousands'
//         },
//       },
//     },
//     colors: [baseColor, secondaryColor],
//     grid: {
//       borderColor: borderColor,
//       strokeDashArray: 4,
//       yaxis: {
//         lines: {
//           show: true,
//         },
//       },
//     },
//   }
// }
