import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, ProfileDatas, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import useApiHook from '../../hook/useApiHook'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined | null
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined | null>>
  logout: () => void
  profileData: ProfileDatas | undefined | null
  getShowProfileData: any
  // logoutResponse: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  profileData: undefined,
  getShowProfileData: () => {},
  // logoutResponse: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const {onGet} = useApiHook()
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [profileData, setProfileData] = useState<ProfileDatas | undefined>({})
  const [currentUser, setCurrentUser] = useState<UserModel | undefined | null>()

  const getShowProfileData = async () => {
    try {
      const response = await onGet('/user/show_profile')

      // console.log(response)
      // console.log(response.data)
      // console.log(response?.data?.['profile'])

      const profileAPIData: any = response.data?.['profile']
      const profileInfoData: ProfileDatas = profileAPIData

      if (response.status === 200) {
        setProfileData(profileInfoData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getShowProfileData()
  }, [currentUser])
  useEffect(() => {
    getShowProfileData()
  }, [])

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        profileData,
        getShowProfileData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
