import React, { FC, useEffect, useState } from 'react'
import {CardInfo, OrderDetail} from '../cardDetails/interfaces/cardDetails'

interface PropsChild {
  orderDetails?: OrderDetail
  cardDetailsData?: CardInfo
}

export const BottomNav: FC<PropsChild> = ({orderDetails, cardDetailsData}) => {
  const style = orderDetails?.template
  const iconStyle = {color: style?.font_color}

  const [deviceWidth, setDeviceWidth] = useState<any>();

  // console.log(cardDetailsData)

  useEffect(() => {
    let width = window.innerWidth

    setDeviceWidth(width)
  })

  // console.log(deviceWidth)
  
  return (
    <nav
      className='navbar fixed-bottom'
      style={{
        backgroundColor: style?.background_color,
        color: style?.font_color,
        fontFamily: style?.font_style,
      }}
    >
      <div className='container'>
        <div
          style={{
            overflowX: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            whiteSpace: 'nowrap',
            width: '100vw',
            display: 'flex',
            padding: '0 20px',
            color: style?.font_color
            // justifyContent: `${cardDetailsData?.video_link?.length > }`
          }}
          className={
            deviceWidth > 767 ? 'bottom__navbar' : 'bottom__nav'}
        >
          {' '}
          <ul className='nav nav-pills flex-nowrap'>
            <li className='nav-item'>
              <a className='nav-link' style={iconStyle} href='#home'>
                <i className='bi bi-house-fill' style={iconStyle}></i> Home
              </a>
            </li>
            {/* <li className='nav-item'>
              <a className='nav-link' style={iconStyle} href='#home'>
                <i className='bi bi-info-circle' style={iconStyle}></i> About Us
              </a>
            </li> */}
            {cardDetailsData?.product_services?.length! > 0 && (
              <li className='nav-item'>
                <a className='nav-link' style={iconStyle} href='#products'>
                  <i className='bi bi-layers' style={iconStyle}></i> Product & Services
                </a>
              </li>
            )}
            {/* <li className='nav-item'>
              <a className='nav-link' style={iconStyle} href='#shop'>
                <i className='bi bi-shop' style={iconStyle}></i> Shop
              </a>
            </li> */}
            {cardDetailsData?.galleries?.length! > 0 && (
              <li className='nav-item'>
                <a className='nav-link' style={iconStyle} href='#gallery'>
                  <i className='bi bi-images' style={iconStyle}></i> Gallery
                </a>
              </li>
            )}
            {cardDetailsData?.video_link && (
              <li className='nav-item'>
                <a className='nav-link' style={iconStyle} href='#youtube'>
                  <i className='bi bi-youtube' style={iconStyle}></i> Youtube Videos
                </a>
              </li>
            )}
            {cardDetailsData?.payment_details?.length! > 0 && (
              <li className='nav-item'>
                <a className='nav-link' style={iconStyle} href='#payment'>
                  <i className='bi bi-credit-card' style={iconStyle}></i> Payment
                </a>
              </li>
            )}
            {/* <li className='nav-item'>
              <a className='nav-link' style={iconStyle} href='#feedback'>
                <i className='bi bi-chat-dots' style={iconStyle}></i> Feedback
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' style={iconStyle} href='#contact'>
                <i className='bi bi-envelope' style={iconStyle}></i> Enquiry
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  )
}
