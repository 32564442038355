import React from 'react'
import '../customization/savedTemplates/template02.css'
import styleConfig from './interfaces/styleconfig'
import {bottom} from '@popperjs/core'

//TEMPLATE 02
interface CardDetails4Props {
  styleConfig: styleConfig
  firstName?:String
  lastName?:String
  OCompanyName?:String
  ODesignation?:String
  OAbout?:String
  OProfilePic?:String
  OCoverPic?:String
  //   cardconfig: CardInfo,
}

const CardDetails4: React.FC<CardDetails4Props> = (_props: CardDetails4Props) => {
  const styleconfig = _props.styleConfig
  const firstName = _props.firstName;
  const lastName = _props.lastName;
  const OCompanyName = _props.OCompanyName;
  const ODesignation = _props.ODesignation;
  const OAbout = _props.OAbout;
  const OProfilePic = _props.OProfilePic;
  const OCoverPic = _props.OCoverPic;
 

  const default_profile_image = `https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg`
  let default_cover_img =
    'https://www.solidbackgrounds.com/images/950x350/950x350-gray-solid-color-background.jpg'


  return (
    <div >
      <div  style={{borderRadius:'12px', maxWidth:'350px', maxHeight:'655px', overflow:'hidden'}}>
        <div
          className='cover_image_container'
          style={{
            // backgroundImage: `url(${default_cover_img})`,
            backgroundImage: styleconfig.coverImage
              ? `url(${styleconfig.coverImage})`
              : styleconfig.coverHeight === 0
              ? 'none'
              : OCoverPic
              ? `url(https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/template/cover_images/${OCoverPic})`
              :`${default_cover_img}`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className='name-container'
           style={{
            color: `${styleconfig.fontColor ? styleconfig.fontColor : '#fff'}`,
            fontFamily: `${styleconfig.fontFamily}`,
          }}>
            {firstName?(<>{firstName}</>):(<>First Name</>)} <br />
            {lastName?(<>{lastName}</>):(<>Last Name</>)}
          </div>
        </div>

        <div className='about-container'
        style={
          {
            background:`${styleconfig.backgroundColor ? styleconfig.backgroundColor : '#000'}`,
          }
        }>
          <div className='profile_info'
          style={
            {
              background:'transparent'    
            }
          }
          >
            <div
              className='profile_image'
              style={{
                // backgroundImage: `url(${default_profile_image})`,
                backgroundImage: styleconfig.profileImage
                  ? `url(${styleconfig.profileImage})`
                  : OProfilePic
                  ? `url(https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/template/profile_image/${OProfilePic})`
                  : `${default_profile_image}`,
              }}
            ></div>
            <div className='profile_designation'
              style={{
                color: `${styleconfig.fontColor ? styleconfig.fontColor : '#fff'}`,
                fontFamily: `${styleconfig.fontFamily}`,
              }}>
              <div className='profile_company_name'>
              {OCompanyName?(<>{OCompanyName}</>):(<>Company Name</>)}
              </div>
              <div className='profile_job_title'>
              {ODesignation?(<>{ODesignation}</>):(<>Designation</>)}
              </div>
            </div>
          </div>

          <div className='profile_connections'
          style={
            {
              paddingTop:'6em'
            }
          }>
            <div
              className='connection_box d-flex justify-content-center align-items-center'
              style={
                {
                  background:`${styleconfig.buttonbackgroundColor ? styleconfig.buttonbackgroundColor : '#fff'}`,
                }
              }>
              <i className="fa-solid fa-mobile-screen"
              style={{
                fontSize:'25px',
                color: `${styleconfig.buttonFontColor? styleconfig.buttonFontColor : '#000'}`,
              }}></i>
            </div>

            <div
              className='connection_box connection_email d-flex justify-content-center align-items-center'
              style={
                {
                  background:`${styleconfig.buttonbackgroundColor ? styleconfig.buttonbackgroundColor : '#fff'}`,
                }
              }
            >
               <i className="fa-regular fa-envelope"
              style={{
                fontSize:'25px',
                color: `${styleconfig.buttonFontColor? styleconfig.buttonFontColor : '#000'}`,
              }}></i>
            </div>
            <div
              className='connection_box connection_website d-flex justify-content-center align-items-center'
              style={
                {
                  background:`${styleconfig.buttonbackgroundColor ? styleconfig.buttonbackgroundColor : '#fff'}`,
                }
              }>
              <button
                className='btn h-100 w-100 d-flex justify-content-center align-items-center'
                style={{backgroundColor: 'transparent', overflow: 'hidden'}}
              >
                 <i className="fa-solid fa-globe ms-2 "
              style={{
                fontSize:'25px',
                color: `${styleconfig.buttonFontColor? styleconfig.buttonFontColor : '#000'}`,
              }}></i>
              </button>
            </div>

            <div className='connection_box d-flex justify-content-center align-items-center'
             style={
              {
                background:`${styleconfig.buttonbackgroundColor ? styleconfig.buttonbackgroundColor : '#fff'}`,
              }
            }>
            <i className="fa-solid fa-hashtag"
              style={{
                fontSize:'25px',
                color: `${styleconfig.buttonFontColor? styleconfig.buttonFontColor : '#000'}`,
              }}></i>
            </div>

            <div
              className='connection_box connection_address d-flex justify-content-center align-items-center'
              style={
                {
                  background:`${styleconfig.buttonbackgroundColor ? styleconfig.buttonbackgroundColor : '#fff'}`,
                }
              }>
                 <i className="fa-solid fa-location-dot"
              style={{
                fontSize:'25px',
                color: `${styleconfig.buttonFontColor? styleconfig.buttonFontColor : '#000'}`,
              }}></i>
              </div>
          </div>

          <div className='profile_about'
            style={{
              color: `${styleconfig.fontColor ? styleconfig.fontColor : '#fff'}`,
              fontFamily: `${styleconfig.fontFamily}`,
            }}>
            <div className='profile_about_title'>About Me</div>
            <div className='profile_about_content'>
            {OAbout?
            (<>{OAbout}</>)
            :
            (<>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt magnam cumque quod quia dolorum rem.</>)}
            </div>
          </div>

        </div>

       
      </div>
    </div>
  )
}

export default CardDetails4
