/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {
  SocialType,
  SocialTypeInfo,
} from '../../../../app/modules/widgets/components/admin/inerfaces/SocialMediaInterface'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import {SOCIAL_MEDIA_URL, TICKET_URL_IMAGE} from '../../../../app/modules/helpers/imageUrl'
import axios from 'axios'
import {APPURL} from '../../../../app/modules/helpers/imageUrl'
import MetaTagWrapper from '../../../../app/modules/helpers/MetaTagWrapper'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'
import TicketReply from '../../../../app/modules/widgets/components/admin/TicketReply'

const socialMediaURL = SOCIAL_MEDIA_URL
const BASE_URL = APPURL
const ticketImgUrl = TICKET_URL_IMAGE

type Props = {
  className: string
  tabIndex: any
}

interface TicketType {
  id?: number
  ticket_code: string
  subject: string
  description: string
  related_service: string
  image_file: string
  status: number
}

interface NameInfo {
  name?: string
}
const axiosInstance = axios.create({
  // Axios instance configuration
  baseURL: BASE_URL,
  // Other options...
})
const TicketList: React.FC<Props> = ({className, tabIndex}) => {
  const {onPost, onGet} = useApiHook()
  const [ticketList, setTicketList] = useState<TicketType[]>([])
  const [showModal, setShowModal] = useState(false)
  const [showModalAddIcon, setShowModalAddIcon] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [inputValue, setInputValue] = useState<SocialTypeInfo>({})
  const [statusCode, setStatusCode] = useState<any>(1)
  const [name, setName] = useState('')
  const [toBedeleted, settoBedeleted] = useState<number>()
  const navigate = useNavigate()
  const getTicketList = async () => {
    try {
      let response = await onGet('/show_all_tickets')
      console.log(response.data)
      const res: any = response.data
      const ticket_types: TicketType[] = res
      setTicketList(ticket_types)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTicketList()
  }, [])
  const handleDeleteMediaIcon = async () => {
    setShowModal(true)
  }
  const deleteTicket = async () => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'access-control-allow-origin': '*',
        'Access-Control-Allow-Origin': `http://127.0.0.1:3011`,
      },
    }
    try {
      const response = await axiosInstance.delete(`/tickets/${toBedeleted}`, config)
      if (response.status == 200) {
        toast.success('Ticket deleted successfully', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setShowModal(false)
        getTicketList()
      }
    } catch (error) {
      toast.error('Ticket deleted successfully', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }
  const handleSubmit = async () => {
    try {
      let response = await onPost('/admin/create_role', {name: name})
      console.log(response.data, 'data')
      if (response.status == 201) {
        toast.success('Role created Successfully', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      } else if (response.status == 422) {
        toast.error(`${name} has been already registered`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }
  return (
    <>
      <MetaTagWrapper title='Social Media || Tap Savvy' />
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title fw-bold text-muted'>Support Tickets</h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary btn-sm btn-active-light-primary'
              onClick={() => navigate('/createTicket')}
            >
              Create Ticket
            </button>

            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body  pt-2'>
          <div className='row mb-5'>
            <div className=' fw-semibold  d-block col-2'>Subject</div>
            <div className=' fw-semibold  d-block col-2'>Created By</div>
            <div className='fw-semibold  d-block col-3'>Discription</div>
            <div className=' fw-semibold  d-block  col-1'> Service</div>
            <div className=' fw-semibold  d-block col-1'>Attachment</div>

            <div className=' fw-semibold  col-1 text-center'>
              <span className=' ms-2'>Status</span>
            </div>
            <div className=' fw-semibold  col-2  ' style={{textAlign: 'right'}}>
              <span className='me-5'>Action</span>
            </div>
          </div>
          <div className='d-flex flex-column mb-3'>
            {/* begin::Description */}
            {ticketList &&
              ticketList?.map((item, index) => (
                // <div
                //   className='d-flex align-items-center justify-content-between mb-8'
                //   key={item.id}
                // >
                //   <div className='d-flex align-items-center ms-5'>
                //
                //     <span className='text-muted fw-semibold d-block mx-5'>{item.name}</span>
                //     <span className='text-muted fw-semibold d-block mx-5'>Shubham Singh</span>
                //     <span className='text-muted fw-semibold d-block mx-5'>
                //       Add to card not working and responsing when we try to...
                //     </span>
                //     <span className='text-muted fw-semibold d-block mx-5'>Card Issue</span>
                //     <span className='text-muted fw-semibold d-block mx-5'>26/08/2023</span>
                //   </div>

                //   <div className='d-flex align-items-center me-5'>
                //
                //     {showModal && (
                //       <div className='modal fade show' style={{display: 'block'}}>
                //         <div className='modal-dialog modal-dialog-centered'>
                //           <div className='modal-content'>
                //             <div className='modal-header'>
                //               <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                //                 Are you sure you want to delete this social media icon ?
                //               </h5>
                //             </div>
                //             <div className='modal-body'>
                //               <h2>You can add this social media icon later if you want !</h2>
                //             </div>
                //             <div className='modal-footer'>
                //               <button
                //                 className='btn btn-secondary'
                //                 onClick={() => setShowModal(false)}
                //               >
                //                 close
                //               </button>
                //               <button
                //                 type='button'
                //                 className='btn text-white'
                //                 style={{backgroundColor: '#F24183'}}
                //                 onClick={() => {
                //                   handleDeleteMediaIcon()
                //                 }}
                //               >
                //                 delete
                //               </button>
                //             </div>
                //           </div>
                //         </div>
                //       </div>
                //     )}
                //   </div>
                // </div>
                <div className='row mt-5'>
                  <div className='text-muted fw-semibold  d-block col-2'>{item?.subject}</div>
                  <div className='text-muted fw-semibold d-block col-2'> Shubham Singh</div>
                  <div className='text-muted fw-semibold d-block col-3'>{item?.description}</div>
                  <div className='text-muted fw-semibold  col-1 '> {item.related_service}</div>
                  <div className='text-muted fw-semibold text-center  d-block col-1'>
                    {item?.image_file && (
                      <a href={ticketImgUrl + item?.image_file} target='blank'>
                        <i className='bi bi-eye-fill'></i>
                      </a>
                    )}
                  </div>
                  <div className='col-1 text-center '>
                    {item?.status == 1 && (
                      <button className='badge badge-light-success border-0 fs-8 fw-bold ms-2'>
                        OPEN
                      </button>
                    )}
                    {item?.status == 2 && (
                      <button className='badge badge-light-warning border-0 fs-8 fw-bold ms-2'>
                        REPLIED
                      </button>
                    )}
                    {item?.status == 3 && (
                      <button className='badge badge-light-danger border-0 fs-8 fw-bold ms-2'>
                        CLOSED
                      </button>
                    )}
                  </div>
                  <div className='col-2 d-flex justify-content-end'>
                    <button
                      className='badge badge-light-info border-0 fs-8 fw-bold me-3'
                      onClick={() => navigate(`/supportTicket/${item.id}/reply`)}
                    >
                      Reply
                    </button>
                    <button
                      className='badge badge-light-danger border-0 fs-8 fw-bold me-3'
                      onClick={() => {
                        handleDeleteMediaIcon()
                        settoBedeleted(item.id)
                      }}
                    >
                      Delete
                    </button>
                    {showModal && (
                      <div className='modal fade show' style={{display: 'block'}}>
                        <div className='modal-dialog modal-dialog-centered'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h5 className='modal-title modal-title text-gray-800 fw-bold fs-6'>
                                Are you sure you want to delete this Ticket ?
                              </h5>
                            </div>
                            <div className='modal-body'>
                              <h2>You can open this ticket again when you want!</h2>
                            </div>
                            <div className='modal-footer'>
                              <button
                                className='btn btn-secondary'
                                onClick={() => setShowModal(false)}
                              >
                                close
                              </button>
                              <button
                                type='button'
                                className='btn text-white'
                                style={{backgroundColor: '#F24183'}}
                                onClick={() => {
                                  deleteTicket()
                                }}
                              >
                                delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            {/* end::Description */}
          </div>
        </div>
        {/* end::Body */}
      </div>
      <ToastContainer />
    </>
  )

  // function openModal(item: any) {
  //   return (
  //     <div key={item} className='modal fade' id={`${item}`} tabIndex={tabIndex}>
  //       <div className='modal-dialog modal-dialog-centered'>
  //         <div className='modal-content'>
  //           <div className='modal-header'>
  //             <h1 className='modal-title text-gray-800 fw-bold fs-6' id='exampleModalLabel'>
  //               Are you sure you want to delete this social media icon ?
  //             </h1>
  //             <button
  //               type='button'
  //               className='btn-close'
  //               data-bs-dismiss='modal'
  //               aria-label='Close'
  //             ></button>
  //           </div>
  //           <div className='modal-body'>
  //             <h2>You can add this social media icon later if you want !</h2>
  //           </div>
  //           <div className='modal-footer'>
  //             <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
  //               Close
  //             </button>
  //             <button
  //               type='button'
  //               className='btn text-white'
  //               style={{ backgroundColor: '#F24183' }}
  //               onClick={() => {
  //                 handleDeleteMediaIcon(item)
  //               }}
  //             >
  //               Delete{' '}
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
}

export {TicketList}
