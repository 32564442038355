import  { FC, useState } from 'react'
import { OrderDetail, PaymentDetails } from '../interfaces/cardDetails'
import { QRCODE_IMAGE_URL } from '../../../modules/helpers/imageUrl'
import { upiProviders } from '../../../modules/wizards/components/steps/Step2'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

interface props {
  paymentDetails?: PaymentDetails[]
  orderDetails?: OrderDetail
}

const Qrurl = QRCODE_IMAGE_URL

const Payment:FC<props> = ({paymentDetails, orderDetails}) => {
  const style = orderDetails?.template
  return (
    <div
      className='card'
      style={{backgroundColor: style?.background_color, fontFamily: style?.font_style}}
    >
      <h1 className='text-center fw-bold p-3' style={{color: style?.font_color}}>
        Payment Methods
      </h1>{' '}
      {paymentDetails?.map((paymentDetails) => (
        <div className='card rounded-3'>
          <div
            className='card-body p-4'
            style={{backgroundColor: style?.background_color, color: style?.font_color}}
          >
            <h5
              className='fw-light text-center row row-cols-1 align-items-center'
              style={{color: style?.font_color}}
            >
              {UpiLogo(paymentDetails?.platform_name!)}
              {/* <span className='col'> {paymentDetails?.platform_name}</span>{' '} */}
            </h5>
            <div className='qr-code text-center m-3'>
            <a href={`upi://pay?pa=${paymentDetails?.upi_id}`} >
              <img
                src={Qrurl + paymentDetails?.qr_code}
                alt='UPI QR Code'
                className=''
                style={{width: 200}}
                />
                </a>
              {/* <img
            src={qrCode}
            alt='QR Code'
            style={{width: '200px', height: '200px', display: 'block', margin: '0 auto'}}
          /> */}
            </div>
            <p className='card-text text-center'>
              {/* Render the link */}
              {/* {paymentDetails?.upi_id} */}
              <a href={`upi://pay?pa=${paymentDetails?.upi_id}`} className='text-dark fw-bold text-hover-primary fs-6'>{paymentDetails?.upi_id}</a>
              {/* <a href="upi://pay?pa=hittheshubham1810@oksbi" className="upi-pay1" target='_blank'>{paymentDetails?.upi_id}</a> */}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export function UpiLogo(platform : string){
  const filteredplatform = upiProviders.filter((pl)=> platform == pl.name)
  // console.log(filteredplatform);

  return (
    <>
      {filteredplatform && <img className='' src={toAbsoluteUrl(filteredplatform[0].logo!)} alt={filteredplatform[0].name} height={25} width={25} />}
    </>
  )
  
}

export default Payment