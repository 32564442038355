import {response} from 'express'
import React, {useEffect, useRef, useState} from 'react'
import {Bar, Line} from 'react-chartjs-2'
import * as XLSX from 'xlsx'

type Props = {
  className: string
  cardID: string
}

interface DataPoint {
  card_id: string
  view_count: string
  save_count: string
  // Add other properties as needed
}

interface DataPoints {
  [date: string]: DataPoint
}

const UserChart1: React.FC<Props> = ({className, cardID}) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL
  const token = localStorage.getItem('token')
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)

  // Code for Chart JS react Bar Chart
  const [timeLineCount, setTimeLineCount] = useState<Array<any> | null>(null)
  const [labels, setLabels] = useState<string[]>([])
  const [dataPoints, setDataPoints] = useState<number[]>([])
  const [viewCountsDataPoint, setViewCountsDataPoint] = useState<string[]>([])
  const [saveCountDataPoints, setSaveCountDataPoints] = useState<string[]>([])
  const [datapointLabels, setDatapointLabels] = useState<string[]>([])

  const [userdata, setUserData] = useState<Array<any> | null>(null)

  let dataToDownload: any = []

  function formatDate(date: Date): string {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}/${month}/${day}`
  }

  const getDayOfWeek = (dateString: string): string => {
    const date = new Date(dateString)
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const dayIndex = date.getDay()
    return daysOfWeek[dayIndex]
  }

  const [currentDate, setCurrentDate] = useState<string>(formatDate(new Date()))
  const oneYearAgo = new Date()
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
  const [conditionalDate, setConditionalDate] = useState<string>(formatDate(oneYearAgo))
  const [chartTitle, setChartTitle] = useState<string>('Day')

  // To Transform the backend into desired result
  const transformDataPoints = (
    datapoints: DataPoints
  ): {date: string; view_count: string; save_count: string}[][] => {
    const result = []

    for (const date in datapoints) {
      if (datapoints.hasOwnProperty(date)) {
        const viewCount = datapoints[date].view_count
        const saveCount = datapoints[date].save_count
        result.push([{date: date, view_count: viewCount, save_count: saveCount}])
      }
    }

    return result
  }
  // Timeline Analysis
  const timelineAnalysisAPI = async (days: string) => {
    const apihit = await fetch(`${API_BASE_URL}/timeline/${cardID}/${days}`, {
      method: 'GET',
      headers: headers,
    })

    const response = await apihit.json()
    const datapoints = await response.counts_by_period
    const result = await transformDataPoints(datapoints)
    setUserData(result)
    return result
  }

  const getDataPointsByDays = async (days: string) => {
    const result = await timelineAnalysisAPI(days)

    // Initialize arrays for dates, view counts, and save counts
    const dates: string[] = []
    const viewCounts: string[] = []
    const saveCounts: string[] = []

    // Iterate over the result and extract data into separate arrays
    result.forEach((item) => {
      item.forEach((dataPoint) => {
        if (days === '7') {
          dates.push(getDayOfWeek(dataPoint.date))
        } else if (days === '30') {
          dates.push(dataPoint.date.substring(5))
        } else {
          dates.push(dataPoint.date)
        }

        viewCounts.push(dataPoint.view_count)
        saveCounts.push(dataPoint.save_count)
      })
    })

    // console.log("Dates:", dates);
    // console.log("View Counts:", viewCounts);
    // console.log("Save Counts:", saveCounts);
    setViewCountsDataPoint(viewCounts)
    setSaveCountDataPoints(saveCounts)
    setDatapointLabels(dates)
  }

  const downloadDashboardAnalytics = () => {
    if (userdata)
      for (let data of userdata) {
        // console.log("Userdata debug:", data["0"]);
        dataToDownload.push(data['0'])
      }
    else {
      console.log('Some error has occured')
    }
    // console.log(dataToDownload)
    const ws = XLSX.utils.json_to_sheet(dataToDownload)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    if(chartTitle==="Day")
      {
        XLSX.writeFile(wb, `${cardID}_lastweek_DashboardAnalytics.xlsx`)
      }
      else
      {
        XLSX.writeFile(wb, `${cardID}_${chartTitle}ly_DashboardAnalytics.xlsx`)
      }
  }

  useEffect(() => {
    getDataPointsByDays('7')
  }, [])

  // useEffect(()=>
  // {
  //   console.log("User Data Debug: ",userdata)
  // })

  const handleButtonClick = (timeRange: string) => {
    let newDate = new Date()

    if (timeRange?.toLowerCase() === 'year') {
      newDate.setFullYear(newDate.getFullYear() - 1)
      setChartTitle('Year')
      getDataPointsByDays('365')
    } else if (timeRange?.toLowerCase() === 'month') {
      newDate.setMonth(newDate.getMonth() - 1)
      setChartTitle('Month')
      getDataPointsByDays('30')
    } else if (timeRange?.toLowerCase() === 'week') {
      newDate.setDate(newDate.getDate() - 7)
      setChartTitle('Day')
      getDataPointsByDays('7')
    }

    setConditionalDate(formatDate(newDate))
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Card Insights </span>
        </h3>

        <div className='card-toolbar' data-kt-buttons='true'>
          <button
            className='btn btn-sm btn-color-muted btn-primary mx-2 text-white'
            onClick={() => handleButtonClick('year')}
          >
            Year
          </button>
          <button
            className='btn btn-sm btn-color-muted btn-primary mx-2 text-white'
            onClick={() => handleButtonClick('month')}
          >
            Month
          </button>
          <button
            className='btn btn-sm btn-color-muted btn-primary mx-2 text-white'
            onClick={() => handleButtonClick('week')}
          >
            Week
          </button>
        </div>
      </div>
      <div className='d-flex justify-content-end align-items-center mx-5 px-5'></div>

      <div className='card-body'>
        <p className='fs-6 fw-normal'>
          {chartTitle} wise data plot{' '}
          <button
              onClick={downloadDashboardAnalytics}
              style={{background: 'transparent', border: 'none', outline: 'none'}}
            >
              <i
                className='fa-solid fa-download btn btn-success rounded-circle mx-2'
                style={{padding:'0.5em'}}
              ></i>
            </button>
        </p>

        <Line
          data={{
            labels: datapointLabels,
            datasets: [
              {
                label: 'View Counts',
                data: viewCountsDataPoint,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1,
              },
              {
                label: 'Save Count',
                data: saveCountDataPoints,
                borderColor: 'rgb(54, 162, 235)',
                tension: 0.1,
              },
            ],
          }}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </div>
    </div>
  )
}
export {UserChart1}
